import * as React from "react";
import { Container, Dropdown, Accordion, Card, Button, Form , Spinner,OverlayTrigger,Tooltip} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import { PartTemplateService } from "../../Common/Services/PartTemplateService";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { ProductSlideout } from './ProductSlideout';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { ConfirmationModal } from "../../Common/Components/ConfirmationModal";
import _ from "lodash";
import { confirmAlert } from "react-confirm-alert";
// import { SortableContainer, SortableElement,SortableHandle } from "react-sortable-hoc";
import createDOMPurify from "dompurify";
// import { arrayMove } from 'react-sortable-hoc';
import "react-confirm-alert/src/react-confirm-alert.css";
import { faArrowUp, faListSquares } from "@fortawesome/free-solid-svg-icons";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { FaArrowUpFromBracket,  FaCirclePlus, FaEllipsis,FaCircleMinus  } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import "../../Modules/customer.scss";
export class ProcessMaster extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
         
          htmlItem: [],
          htmlErrorItem: [],
          htmlWarningItem: [],
          searcResult: [],
          productid:0,
          isSaveOpen: false,
          loading: false,
          isWait: false,
          newRow:
          {id:0,processName:"",pDescription:"",srno:0,status:1},
          formData :{processlist:[],},
          templateColumns: this.templateColumns,
        };
        
     
      }

      private templateColumns = [

        {
          dataField: 'srno',
          text: 'Sr No.',
          headerStyle: { width: '50px' },
          formatter: (cell: any, row: any, rowIndex: any) => {
    
            return (
              <div>
                {rowIndex + 1}
              </div>
            );
          },
        },
        {
          dataField: "isSelected",
          text: "",
          classes: "expanding-bar",
          headerStyle: { width: '10px' },
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <div className="d-flex align-items-center txt-bold">
    
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="toolp">Click to move up this step</Tooltip>
                  }
                >
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    size="xs"
                    onClick={(e) => this.handleRowOrdering(e, row, 'up')}
                  />
                </OverlayTrigger>
              </div>
            );
          },
        },
    
        {
          dataField: "isSelected",
          text: "",
          classes: "expanding-bar",
          headerStyle: { width: '10px' },
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <div className="d-flex align-items-center txt-bold">
    
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="toolp">Click to move down this step</Tooltip>
                  }
                >
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    size="xs"
                    onClick={(e) => this.handleRowOrdering(e, row, 'down')}
                  />
                </OverlayTrigger>
              </div>
            );
          },
        },
    
        {
          dataField: 'process',
          text: 'Process Name',
          headerStyle: { width: '250px' },
          hidden: false,
          editable: false,
          headerAttrs: (column, colIndex) => (
            { 'tabIndex': '-1' }
          ),
          formatter: (cell: any, row: any, rowIndex: any) => {
           
            return (
              <div>
               <Form.Control
                        // tabIndex={this.state.tabEnable}
                         id={"txtprocess" + rowIndex + "txt"}
                        type="text"
                        // placeholder="Enter Part No"
                        value={row.processName}
                          onChange={(e) => this.updateState(e, rowIndex, "processName", rowIndex)}
                        autoComplete="off"
                         disabled={ false }
                        title=""
                        maxLength={500}
                      />

              </div>
    
            );
    
    
    
          },
        },
        {
          dataField: 'description',
          text: 'Description',
          headerStyle: { width: '550px' },
        
          hidden: false,
          editable: false,
          headerAttrs: (column, colIndex) => (
            { 'tabIndex': '-1' }
          ),
          formatter: (cell: any, row: any, rowIndex: any) => {
         
            return (
             <div>
 <Form.Control
                        // tabIndex={this.state.tabEnable}
                        id={"txtdes" + rowIndex + "txt"}
                        type="text"
                        // placeholder="Enter Part No"
                        value={row.pDescription}
                          onChange={(e) => this.updateState(e, rowIndex, "Description", rowIndex)}
                        autoComplete="off"
                       // disabled={ row.id==6 ? true: false }
                        title=""
                        maxLength={500}
                      />
                   
              


             </div>
            );
    
    
    
          },
    
        },
        {
          dataField: 'ledgercode',
          text: 'Ledger Code',
          headerStyle: { width: '100px' },
          hidden: false,
          editable: false,
          headerAttrs: (column, colIndex) => (
            { 'tabIndex': '-1' }
          ),
          formatter: (cell: any, row: any, rowIndex: any) => {
           
            return (
              <div>
               <Form.Control
                        // tabIndex={this.state.tabEnable}
                         id={"txtledgercode" + rowIndex + "txt"}
                        type="text"
                        // placeholder="Enter Part No"
                        value={row.ledgercode}
                          onChange={(e) => this.updateState(e, rowIndex, "ledgercode", rowIndex)}
                        autoComplete="off"
                         disabled={ false }
                        title=""
                        maxLength={500}
                      />

              </div>
    
            );
    
    
    
          },
        },
      
    
        // {
        //   dataField: 'isFixed',
        //   text: 'External',
        //   headerStyle: { width: '50px' },
        //   hidden: false,
        //   editable: false,
        //   headerAttrs: (column, colIndex) => (
        //     { 'tabIndex': '-1' }
        //   ),
        //   formatter: (cell: any, row: any, rowIndex: any) => {
        //     let id = "txtdescription" + rowIndex + "txt";
        //     let disable = false;
        //     return (
        //       <div>
        //      <Form.Check
        //         className="knob"
        //                 // tabIndex={this.state.tabEnable}
        //                 id={"txtIsFixed" + rowIndex + "txt"}
        //               type="switch"
        //                 checked={row.isFixed}
        //                 // placeholder="Enter Part No"
        //                 //value={value.pDescription}
        //                   onChange={(e) => this.updateState(e, rowIndex, "IsFixed", rowIndex)}
        //                 autoComplete="off"
        //                // disabled={ row.id==6 ? true: false }
        //                 title=""
        //                 //maxLength={500}
        //               />
        //     </div>
          

      


    
        //     );
    
    
    
        //   },
    
        // },

        {
          dataField: 'isFixed',
          text: 'External',
          headerStyle: { width: '80px' },
          hidden: false,
          headerClasses: 'text-center',
          classes: 'text-center',
          
          editable: false,
          headerAttrs: (column, colIndex) => (
            { 'tabIndex': '-1' }
          ),
          formatter: (cell: any, row: any, rowIndex: any) => {
            let id = "txtdescription" + rowIndex + "txt";
            let disable = false;
            return (
              <div>
                   <Form.Check
                className="knob"
                        // tabIndex={this.state.tabEnable}
                        id={"txtIsFixed" + rowIndex + "txt"}
                        type="switch"
                        checked={row.isFixed}
                        // placeholder="Enter Part No"
                        //value={value.pDescription}
                          onChange={(e) => this.updateState(e, rowIndex, "IsFixed", rowIndex)}
                        autoComplete="off"
                       // disabled={ row.id==6 ? true: false }
                        title=""
                        //maxLength={500}
                      />

              </div>
    
            );
    
    
    
          },
    
        },
        {
          dataField: 'status',
          text: 'Active',
          headerClasses: 'text-center',
          classes: 'text-center',
          
          headerStyle: { width: '80px' },
          hidden: false,
          editable: false,
          headerAttrs: (column, colIndex) => (
            { 'tabIndex': '-1' }
          ),
          formatter: (cell: any, row: any, rowIndex: any) => {
            let id = "txtdescription" + rowIndex + "txt";
            let disable = false;
            return (
              <div>
                   <Form.Check
                className="knob"
                        // tabIndex={this.state.tabEnable}
                        id={"txtstatus" + rowIndex + "txt"}
                        type="switch"
                        checked={row.status}
                        // placeholder="Enter Part No"
                        //value={value.pDescription}
                          onChange={(e) => this.updateState(e, rowIndex, "status", rowIndex)}
                        autoComplete="off"
                       // disabled={ row.id==6 ? true: false }
                        title=""
                        //maxLength={500}
                      />

              </div>
    
            );
    
    
    
          },
    
        },
        {
          dataField: 'Attachment',
          text: '',
          //headerFormatter: this.iconFormatter,
          headerAttrs: (column, colIndex) => (
            { 'tabIndex': '-1' }
          ),
          formatter: (cell: any, row: any, rowIndex: any) => {
            let confirmModalContent = [
              {
                title: "Delete Row",
                desc: "Are you sure you want to delete?",
                cancleAction: "Cancel",
                ActionTrue: "Delete",
              },
            ];
    
            if (   row.deleteAllowed ==false  )  {
              return (
                <div></div>
              );
            }
    
    
            else {
              return (
                <Dropdown
             className="more-action confirmation-modal"
              
              //tabIndex={this.state.tabDisable}
            >
              <Dropdown.Toggle
                className="btn-outline-primary btn btn-primary more fa-trash"
                id="dropdown-more-delete"
              //  tabIndex={this.state.tabDisable}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5 5.83329C4.53917 5.83329 4.16667 5.83329 4.16667 5.83329V16.6666C4.16667 17.5866 4.91333 18.3333 5.83333 18.3333H14.1667C15.0867 18.3333 15.8333 17.5866 15.8333 16.6666V5.83329C15.8333 5.83329 15.4608 5.83329 15 5.83329H5ZM8.33333 15.8333H6.66667V8.33329H8.33333V15.8333ZM13.3333 15.8333H11.6667V8.33329H13.3333V15.8333ZM13.8483 3.33329L12.5 1.66663H7.5L6.15167 3.33329H2.5V4.99996H6.66667H13.3333H17.5V3.33329H13.8483Z" />
                </svg>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <ConfirmationModal
                  confirmModalContent={confirmModalContent}
                  ishandleDeletePosition={this.ishandleDeleteRow.bind(
                    this,
                    row
                  )}
                />
              </Dropdown.Menu>
            </Dropdown>
              );
            }
          },
        },
    
      ];
    
      componentDidMount() {
        let requestlist = { ...this.state.requestlist };
        this.getprocessMaster(requestlist);
      }

      handleRowOrdering = (e: any, row: any, type: any) => {

let isSaveOpen =true;
        let searcResult = this.state.searcResult;
        
        let nextOrderNo = type == 'down' ? row.srno + 1 : row.srno - 1;
        let currentRow = searcResult.filter((x) => x.srno == row.srno);
        let nextRow = searcResult.filter((x) => x.srno == nextOrderNo);
    
        if (nextRow != null && nextRow.length > 0) {
    
          nextRow[0].srno = row.srno;
          currentRow[0].srno = nextOrderNo;
    
    
          searcResult = searcResult.sort((a, b) => a.srno - b.srno);
    
          this.setState({ searcResult: searcResult }, () => {
            this.setState({ searcResult: searcResult,isSaveOpen:isSaveOpen });
          });
    
         // this.ReorderProcess();
        }
    
      };
      ReorderProcess = () => {
        
        let request = { ...this.state.requestlist };
        let searcResult = this.state.searcResult;
        searcResult.forEach((element, idx) => {
          //searcResult.forEach(element => {
          let item: any = {};

          item.Id = element.id ==null ?0 :element.id ;
          item.ProcessName = element.processName;
          item.srno =idx;
          item.PDescription = element.pDescription;
          item.Tenantid = element.tenantid;
          item.status=element.status;
          item.isFixed = element.isFixed;
          item.ledgercode=element.ledgercode;

        
          this.state.formData.searcResult.push(item);
        });
    
    
    
      
      };
    
      
      sortByKey = (array: any, key: any) => {
        return array.sort((a, b) => {
          let x = parseInt(a[key]);
          let y = b[key];
          return x < y ? -1 : x > y ? 1 : 0;
        });
      };
      // startState = () => { };
      // onSortMove = () => { };
      // onSortEnd = ({ oldIndex, newIndex }) => {
      //   
      //   if (newIndex !== oldIndex) {
      //     this.setState({ isSaveOpen: true }, () => {
      //        //this.props.changeValueFuntion(true);
      //     });
      //   }
      //   this.setState({ isWait: false });
      //   this.setState(({ searcResult }) => ({
      //      searcResult: arrayMove(searcResult, oldIndex, newIndex),
      //   }));
      // };

      updateState = (e: any, index: any, type: any, txtID) => {
        if (this != undefined) {
          let isStateChanged = true;
          let isSaveOpen =true;
          let start = e.target?.selectionStart;
          let end = e.target?.selectionEnd;
          let searcResult = Object.assign(
            [],
            JSON.parse(JSON.stringify(this.state.searcResult))
          );
    
        
          if (type === "processName") {
            
            e.preventDefault();
          
            searcResult[index].processName = e.target.value;
    
            this.setState({ searcResult, isStateChanged,isSaveOpen }, () => {
              $("input[id^='txtprocess" + txtID + "txt']").focus();
              
            let txtdesc =  $("input[id^='txtprocess" + txtID + "txt']")[0] as any;
            if (txtdesc !== null && txtdesc !== undefined) {
              txtdesc.selectionStart = start;
              txtdesc.selectionEnd = end;
            }
            });
          }
          if (type === "ledgercode") {
            
            e.preventDefault();
          
            searcResult[index].ledgercode = e.target.value;
    
            this.setState({ searcResult, isStateChanged,isSaveOpen }, () => {
              $("input[id^='txtledgercode" + txtID + "txt']").focus();
              
            let txtdesc =  $("input[id^='txtledgercode" + txtID + "txt']")[0] as any;
            if (txtdesc !== null && txtdesc !== undefined) {
              txtdesc.selectionStart = start;
              txtdesc.selectionEnd = end;
            }
            });
          }
          if (type === "Description") {
            e.preventDefault();
    
            searcResult[index].pDescription = e.target.value;
    
            this.setState({ searcResult, isStateChanged,isSaveOpen }, () => {
              $("input[id^='txtdes" + txtID + "txt']").focus();
              let txtdesc =  $("input[id^='txtdes" + txtID + "txt']")[0] as any;
            if (txtdesc !== null && txtdesc !== undefined) {
              txtdesc.selectionStart = start;
              txtdesc.selectionEnd = end;
            }
            });
          }
          
          if (type === "status") {
            e.preventDefault();
            
            if(e.target.checked)
            {
              
             // $("input[id^='txtstatus" + txtID + "txt']").isChecked =true;
            searcResult[index].status =1;
            }
          else
          {
           searcResult[index].status =0;
          }
            this.setState({ searcResult, isStateChanged,isSaveOpen }, () => {
            
             
            });
          }

          if (type === "IsFixed") {
            e.preventDefault();
            
            if(e.target.checked)
            {
              
             // $("input[id^='txtstatus" + txtID + "txt']").isChecked =true;
            searcResult[index].isFixed =1;
            }
          else
          {
           searcResult[index].isFixed =0;
          }
            this.setState({ searcResult, isStateChanged,isSaveOpen }, () => {
            
             
            });
          }
       
        }
      };

      deleteProcess = (id) => {

       
        let request = { ...this.state.requestlist };
        request.processId = id;
        
        PartTemplateService.DeleteProcess(request)
          .then(async (result: any | null) => {
            if (result !== null) {
    
              this.closeSlideOut();
    
              toast.success("Process has been deleted.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "OrderSlideout",
              });
    
             
        let requestlist = { ...this.state.requestlist };
        this.getprocessMaster(requestlist);
    
              this.setState({ isSpinner: false });
    
            }
            resolve();
          })
          .catch((error) => {
            this.setState({ isdefaultColDisabled: false, isSpinner: false });
            reject();
          });
     
      };

      ishandleDeleteRow(row: any, mode: any) {
        
        this.deleteProcess(row.id);
        // if (mode) {
        //   let searcResult = Object.assign(
        //     [],
        //     JSON.parse(JSON.stringify(this.state.searcResult))
        //   );
        //   let isStateChanged = true;
        //   this.setState({ searcResult: [] }, () => {
        //     const searcResultc = searcResult
        //     .filter((item) => item.srno !== row.srno)
        //       .map((item, index) => {
        //         item.itemNo = index + 1;
        //         return item;
        //       });
    
        //     this.setState(
        //       {
        //         searcResult: searcResultc, isStateChanged: isStateChanged,isSaveOpen: true
    
        //       },
        //       () => { }
        //     );
        //   });
        // }
      }

      deleteprocess = (id) => {


         this.setState({ isSpinner: true });
        let request = { ...this.state.requestlist };
        request.processId = id;
        
        PartTemplateService.DeleteProcess(request)
          .then(async (result: any | null) => {
            if (result !== null) {
    
              this.closeSlideOut();
    
              toast.success("Process has been deleted.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "OrderSlideout",
              });
    
             
        let requestlist = { ...this.state.requestlist };
        this.getprocessMaster(requestlist);
    
              this.setState({ isSpinner: false });
    
            }
            resolve();
          })
          .catch((error) => {
            this.setState({ isdefaultColDisabled: false, isSpinner: false });
            reject();
          });
      
      };
      
      handleNewRow = () => {
        let searcResult: any = [];
        let newRow: any = {};
        let isStateChanged = true;
    
        searcResult = Object.assign(
          [],
          JSON.parse(JSON.stringify(this.state.searcResult))
        );
        newRow = Object.assign({}, JSON.parse(JSON.stringify(this.state.newRow)));
    
        let maxValueOfRow = Math.max(...searcResult.map((o) => o.srno), 0);
        let len = searcResult.length;
        let maxRno = maxValueOfRow;
         let maxSrno = searcResult[len - 1].srno;
        newRow.id = 0;
        // newRow.id = Number(maxSrno) + 1;
        newRow.srno=Number(maxSrno) + 1;
        newRow.deleteAllowed=true;
        searcResult.push(newRow);
        this.setState({ searcResult: [] }, () => {
          this.setState({ searcResult: searcResult, isStateChanged });
        });
      };


      getprocessMaster = (requestlist: any) => {
 

        PartTemplateService.GetProcessListAll(requestlist)
    .then(async (result: any[] | null) => {
        
      if (result != null) {
        
        let data = this.sortByKey(result, "srno");
          this.setState({ searcResult: data.slice(0) });
          this.setState({ isWait: false });
        // this.setState({ searcResult: result }, () => {
           
        // });
      }
      resolve();
    })
    .catch((err: any) => {
      toast.error(`Server Error, ${err}`);
      this.setState({ isWait: false });
      reject();
    });
  };

  shownewslideout = () => {
    this.setState({ showSlideout: true, productid: 0 });
  };

  closeSlideOut = () => {
    
    this.setState({ showSlideout: false });
    
  };
  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      
      this.setState({
        showSlideout: true, productid: row.id
      });


    },
  };
  discard = () => {
    let requestlist = { ...this.state.requestlist };
        this.getprocessMaster(requestlist);
    this.setState({ isSaveOpen: false }, () => {
       
    });
  };

  saveApi = () => {
    let { searcResult } = this.state;
  
    this.setState({ isWait: true });
    let processlistItem: any = [];
    searcResult.forEach((items: any, index: any) => {
      let newIndex = index + 1;
     
      let item: any = {};


      item.Id = items.id ==null ?0 :items.id ;
      item.ProcessName = items.processName;
      item.srno =newIndex;
      item.PDescription = items.pDescription;
      item.Tenantid = items.tenantid;
      item.status=items.status;
      item.isFixed = items.isFixed;
      item.ledgercode=items.ledgercode;
      processlistItem.push(item);
    });

    var hasDuplicate = false;
   
    processlistItem.map(v => v.ProcessName).sort().sort((a, b) => {
      if (a != null && b != null && a.toLowerCase() === b.toLowerCase()) hasDuplicate = true;
    });
   // this.state.formData.processlist = processlistItem;
//alert(hasDuplicate);

if(processlistItem != undefined && processlistItem.filter((x)=>x.ProcessName == '').length > 0)
{
  this.setState({ isWait: false });
  toast.error("Please check empty Process Name ",
  { position: toast.POSITION.BOTTOM_RIGHT, containerId: "userManagementHierarchy" });
  return;
}
    
if(hasDuplicate)
{
  this.setState({ isWait: false });
  toast.error("Please check duplicate Process Name ",
  { position: toast.POSITION.BOTTOM_RIGHT, containerId: "userManagementHierarchy" });
  
}
else
{
  
    PartTemplateService.Saveprocess(processlistItem).then(
      async (result: any | null) => {
        
          let requestlist = { ...this.state.requestlist };
          this.getprocessMaster(requestlist);
        
          toast.error("Process updated",
            { position: toast.POSITION.BOTTOM_RIGHT, containerId: "userManagementHierarchy" });
          
            this.setState({ isSaveOpen: false ,loading: false });
      }
    );
    }


  };

    render() { 
      const customTotal = (from, to, size) => (
        <span className="text-muted">
          Showing {from} to {to} of {size} entries
        </span>
      );
      const options = {
  
        showTotal: true,
        hideSizePerPage: false,
        hidePageListOnlyOnePage: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [{
          text: '10', value: 10
        },
        {
          text: '25', value: 25
        },
        {
          text: '50', value: 50
        },
  
        {
          text: 'All', value: this.state.searcResult.length
        }] // A numeric array is also available. the purpose of above example is custom the text
      };
      let confirmModalContent = [
        {
          title: "Delete Row",
          desc: "Are you sure you want to delete?",
          cancleAction: "Cancel",
          ActionTrue: "Delete",
        },
      ];
      const {

        showSlideout,
  
      } = this.state;
      

//       const SortableItem = SortableElement<{ value: any, idx: any }>(({ value, idx }) => (
//         <div className="row" style={{ cursor: "move" }}>
//           <div className="col-sm-1 td">
//             <div className="tdBody diwidth20">{idx + 1}</div>
//           </div>
//           <div className="col-sm-3 td">
          
      
//             <div className="tdBody">
//                       <Form.Control
//                         // tabIndex={this.state.tabEnable}
//                          id={"txtprocess" + idx + "txt"}
//                         type="text"
//                         // placeholder="Enter Part No"
//                         value={value.processName}
//                           onChange={(e) => this.updateState(e, idx, "processName", idx)}
//                         autoComplete="off"
//                          disabled={ false }
//                         title=""
//                         maxLength={500}
//                       />
//                     </div>
            
//           </div>
//           <div className="col-sm-4 td">
           
//            <div className="tdBody">
//                       <Form.Control
//                         // tabIndex={this.state.tabEnable}
//                         id={"txtdes" + idx + "txt"}
//                         type="text"
//                         // placeholder="Enter Part No"
//                         value={value.pDescription}
//                           onChange={(e) => this.updateState(e, idx, "Description", idx)}
//                         autoComplete="off"
//                        // disabled={ row.id==6 ? true: false }
//                         title=""
//                         maxLength={500}
//                       />
                   
              
//               </div>
//           </div>
//           <div className="col-sm-1 td">
//           <div className="tdBody">
//           <Form.Control
//                 className="knob"
//                         // tabIndex={this.state.tabEnable}
//                         id={"txtstatus" + idx + "txt"}
//                         type="checkbox"
//                         checked={value.status}
//                         // placeholder="Enter Part No"
//                         //value={value.pDescription}
//                           onChange={(e) => this.updateState(e, idx, "status", idx)}
//                         autoComplete="off"
//                        // disabled={ row.id==6 ? true: false }
//                         title=""
//                         //maxLength={500}
//                       />
//            </div>
//           </div>
//           <div className="col-sm-1 td">
//           <div className="tdBody">
//           <Form.Control
//                 className="knob"
//                         // tabIndex={this.state.tabEnable}
//                         id={"txtIsFixed" + idx + "txt"}
//                         type="checkbox"
//                         checked={value.isFixed}
//                         // placeholder="Enter Part No"
//                         //value={value.pDescription}
//                           onChange={(e) => this.updateState(e, idx, "IsFixed", idx)}
//                         autoComplete="off"
//                        // disabled={ row.id==6 ? true: false }
//                         title=""
//                         //maxLength={500}
//                       />
//            </div>
//           </div>
//           <div className="col-sm-1 td" >
//           <div className="tdBody">
//          { value.deleteAllowed &&  
           
    
           
//             <>
           
//             <Button className="gg-remove" onClick=
//            // {(e)=>{ this.deleteProcess(value.srno);}}
//            {this.ishandleDeleteRow.bind(
//             this,
//           value
//           )}  
//             >
//              Delete
//             </Button>
// {/* <i className="fa fa-trash" ></i> */}
// {/* <i
//    onClick={(e)=>{ this.deleteProcess(value.id);}}
//   className="far fa-trash-alt ml-auto"
// /> */}

//        {/* <Button
//                           type="button"
//                           className="btn-ghost mr-auto dx-button"
                         
//                           onClick={(e)=>{ this.deleteProcess(value.id);}}
//                         >
//                           <MdDelete className=".dx-icon align-top lh-1'"   onClick={(e)=>{ this.deleteProcess(value.id);}} size={20} /> 
//                         </Button> 
//         */}
            
//                 </>
           
//          }
//            </div>
//           </div>
//         </div>
//       ));
      
      
      // const DragHandle = SortableHandle(() => <span>::</span>);
      // const SortableList = SortableContainer<{ searcResult: [] }>(({ searcResult }) => {
      //   searcResult = searcResult || [];
      //   return (
      //     <div className="tableTbody">
      //       {(searcResult || [])?.map((value, index) => (
              
      //         <SortableItem key={index} idx={index} index={index} value={value} />
      //       ))}
      //     </div>
      //   );
      // });
      
      let { searcResult, isSaveOpen, loading, isWait, d } = this.state;


        return (

          
            <>
<React.Fragment>


        
          <Container fluid className="body-sec">
            <div className="page-heading underline d-flex">
            
          Process Master
            <div className="action-group d-flex flex-row ml-auto">
              </div>
              </div>
              <ToastContainer
          enableMultiContainer
          autoClose={3000}
          containerId={"userManagementHierarchy"}
        />
     
        {/* <div className="hierarchyDragableTable">
          <div className="tableThead">
            <div className="row hasBorder">
              <div className="col-sm-1 th">
                Order
              </div>
              <div className="col-sm-3 th">
                 Process Name 
              </div>
              <div className="col-sm-4 th">
                Description 
              </div>
              <div className="col-sm-1 th">
                 Status 
              </div>
              <div className="col-sm-1 th">
                 External 
              </div>
              <div className="col-sm-1 th">
                  
              </div>
            </div>
          </div>

          <div
            className={`${isSaveOpen ? "hasFixedAction hasTable" : "hasTable"}`}
          >
            {loading ? (
              ""
            ) : (
              <>
              

                <SortableList
                  helperClass="sortableHelper"
                  axis="y"
                  lockAxis="y"
                  searcResult={searcResult}
                  onSortEnd={this.onSortEnd}
                  onSortStart={this.startState}
                  onSortMove={this.onSortMove}
                />
                 
              </>
            )}
          </div>
            <div className="d-flex align-items-center justify-content-end height-20">
                  <div className="d-flex action-btn align-items-center">
                  <Button
                          type="button"
                          className="btn-ghost mr-auto"
                          onClick={this.handleNewRow}
                        >
                          <FaCirclePlus className="align-middle lh-1`" /> <span className="align-middle lh-1">Add Row</span>
                        </Button>
                   </div>
                 </div>
                 </div> */}

{this.state.searcResult.length > 0 && (
 <div className="table-responsive table-responsive1" >
                <section className="pb-3">
                <div
                          className="table table-borderless align-middle transactions-list"

                        >
<BootstrapTable
  id="tbltemplate2"
  keyField="templateid"
  data={this.state.searcResult}
  columns={this.state.templateColumns}
 // pagination={paginationFactory(options)}
/>
</div>
</section>
</div>

)}



<div className="d-flex align-items-center justify-content-end height-20">
                  <div className="d-flex action-btn align-items-center">
                  <Button
                          type="button"
                          className="btn-ghost mr-auto"
                          onClick={this.handleNewRow}
                        >
                          <FaCirclePlus className="align-middle lh-1`" /> <span className="align-middle lh-1">Add Row</span>
                        </Button>
                   </div>
                 </div>
                 
                 <div className="filter-sec height-172">

<br></br>
<br></br>

</div>
                 <div className="d-flex align-items-center justify-content-end height-20"
                
                 >
          {isSaveOpen && (
           <div className="TabFooter-Pay"
           style={{ minWidth: "83.7%", width: "992px", maxWidth: "100%" }}
           >
              <div className="bulkSelectFooter">
                <div className="mr-auto message">
                  <span>You have unsaved changes</span>
                </div>
                <button
                  type="button"
                   onClick={this.discard}
                  className="btn btn-primary btn-discard"
                >
                  Discard
                </button>
                <button
                  type="button"
                  disabled={isWait}
                   onClick={this.saveApi}
                  className="btn btn-primary"
                >
                  {isWait ? "Loading..." : "Save Changes"}
                </button>
              </div>
            </div>
          )}
         </div>


   </Container>
       
   {(this.state.isSpinner || this.state.isSaveSpinner) && (
            <div className="loader-spinner d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="success" />
            </div>
          )}
    
      </React.Fragment>
            </>
        );
    }

}

import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import * as React from "react";
import { Container, Dropdown, Accordion, Card, Button, Form, Spinner, } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import { Customerorderslideout } from '../Customerorderslideout';
import { VendorService } from "../../Common/Services/VendorService";
import { Utils } from "../../Common/Utilis";
import _ from "lodash";
import paginationFactory from 'react-bootstrap-table2-paginator';
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import createDOMPurify from "dompurify";
import chevronRight from "../../Common/Assets/Images/icons/bx-chevron-right.svg";
import moment from "moment";
import { JobOrderService } from "../../Common/Services/JobOrderService";
import { Vendororderslideout } from '../Vendororderslideout';
import { JobCreations } from '../JobCreations';
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";



import { SingleDropdownListWithoutSearch } from "../../Common/Components/SingleDropdownListWithoutSearch";

const { SearchBar } = Search;

import 'react-tabs/style/react-tabs.css';


const DOMPurify = createDOMPurify(window);

export class VendorOrder extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      defaultActiveKey : this.props.taboption ? this.props.taboption : "first",
      footershow : this.props.CustomerPoNumber ? true : false,
      isOrderDate: true,
      isvendorname: true,
      isvendorOrderType: true,
      isvendorPoNumber: true,
      htmlItem: [],
      htmlErrorItem: [],
      htmlWarningItem: [],
      searcResult: [],
      searcResultReceiving: [],
      searcResultCompleted: [],
      orderid: 0,
      jobid: 0,
      customername: '',
      customerpo: '',
      receivedStatus: '',
      orderdate: null,
      jobno: '',
      partno: '',
      partname: '',
      duedate: null,
      qty: 0,
      vendororderid: 0,
      vendorordertype: 'Material',
      selectedcolumn: "All",
      selectOptions: [{
        id: 'All', name: 'All'
      },
      { id: 'Vendor PO Number', name: 'Vendor PO Number' },
      { id: 'Vendor Name', name: 'Vendor Name' },
      { id: 'Order Type', name: 'Order Type' },
      { id: 'Order Date', name: 'Order Date' },
      ],
      receivingStatusOptions: [
        { id: 'Select', name: 'Select' },
        { id: 'Completed', name: 'Completed' },
      { id: 'Partial', name: 'Partial' }
      ],
      expandedRows: [],
      formData: {
        TenantId: 0,
        ReceivingData: [],
      },
    };


  }


  componentDidMount() {

    this.getJobItems();
    this.getJobItemsReceiving();
    this.getJobItemsCompleted();
  }
 

  updateExpandedRows() {
    const { CustomerPoNumber } = this.props;
    const expandedRows = this.state.searcResultReceiving
      .filter(row => row.vendorPoNumber === CustomerPoNumber)
      .map(row => row.rowId);
    this.setState({ expandedRows });
  }

  getJobItems() {

    let requestlist = { ...this.state.requestlist };
    requestlist.showCompleted = false;
    JobOrderService.GetVendorOrderList(requestlist)
      .then(async (result: any[] | null) => {
        if (result != null) {
          // console.log(result);
          this.setState({ searcResult: result }, () => {

          });
        }
        resolve();
      })
      .catch((err: any) => {
        toast.error(`Server Error, ${err}`);
        reject();
      });
  }

  getJobItemsReceiving() {

    let requestlist = { ...this.state.requestlist };

    requestlist.showReceivedOrders = true;
    requestlist.isPrinted = true;
    JobOrderService.GetOrderlistForReceiving(requestlist)
      .then(async (result: any[] | null) => {
        if (result != null) {
          this.setState({ searcResultReceiving: result }, () => {
          this.updateExpandedRows();

          if(this.props.CustomerPoNumber){
            const data = result.filter((x) => x.rowType == 1);
            const isCustomerPoNumberPresent = data.some(item => item.vendorPoNumber === this.props.CustomerPoNumber);
            if (!isCustomerPoNumberPresent) {
              this.backToJob();
            }
          }
          });
        }
        resolve();
      })
      .catch((err: any) => {
        toast.error(`Server Error, ${err}`);
        reject();
      });
  }

  getJobItemsCompleted() {

    let requestlist = { ...this.state.requestlist };
    requestlist.showCompleted = true;
    //JobOrderService.GetVendorOrderlistCompleted(requestlist)

    JobOrderService.GetVendorOrderList(requestlist)
      .then(async (result: any[] | null) => {
        if (result != null) {
          // console.log(result);
          this.setState({ searcResultCompleted: result }, () => {

          });
        }
        resolve();
      })
      .catch((err: any) => {
        toast.error(`Server Error, ${err}`);
        reject();
      });
  }
  shownewslideout = () => {
    this.setState({ isStateChanged: false,footershow:false ,showSlideout: true, orderid: 0, jobid: 0, customername: '', customerpo: '', orderdate: null, jobno: '', partno: '', partname: '', duedate: null, qty: 0, vendororderid: 0, vendorordertype: 'Material', isSpinner: false, });
  };


  showslideout = () => {
    this.setState({ showSlideout: true, isSpinner: false, });
  };

  closeSlideOut = () => {
    this.setState({ showSlideout: false,showCustomerSlideout:false, isSpinner: false, });
    this.getJobItems();
    this.getJobItemsReceiving();
    this.getJobItemsCompleted();
  };
  rowEvents = {

    onClick: (e: any, row: any, rowIndex: any) => {
      // if (row.receivedStatus === 'Completed' || row.receivedStatus === 'Initial') {
        this.setState({
          showSlideout: true, orderid: 0, jobid: row.jobId, customername: row.vendorName,
          customerpo: row.vendorPoNumber, orderdate: row.orderDate, jobno: row.jobNo, partno: row.partNo, partname: row.partName, qty: row.qty, duedate: row.dueDate, vendororderid: row.orderID, vendorordertype: row.vendorOrderType, receivedStatus: row.receivedStatus, isSpinner: false,
        });
     // }
    },
  };

  handleSelectedItem1 = (control: any, id: any) => {
    let searcResult1 = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.searcResult))
    );

    let isOrderDate = id == "Order Date" ? true : false;
    let isvendorname = id == "Vendor Name" ? true : false;
    let isvendorOrderType = id == "Order Type" ? true : false;
    let isvendorPoNumber = id == "Vendor PO Number" ? true : false;


    if (id == "All") {
      isOrderDate = true;
      isvendorname = true;
      isvendorOrderType = true;
      isvendorPoNumber = true;
    }


    this.setState({
      searcResult: [], selectedcolumn: id, isOrderDate: isOrderDate, isvendorname: isvendorname,
      isvendorOrderType: isvendorOrderType, isvendorPoNumber: isvendorPoNumber
    },
      () => {
        this.setState({ searcResult: searcResult1 });
      }

    );

  };

  showSlideOut = (row, type) => {
    
    if (type === 'customer') {
    
      this.setState({ isStateChanged: false,footershow:false , showCustomerSlideout: true, showJobSlideout: false, orderid: row.customerOrderID });
    }
    else if (type === 'job') {
      this.setState({isStateChanged: false,footershow:false ,showJobSlideout: true, showCustomerSlideout: false, orderdetailid: row.orderDetailId , jobid: row.jobId, qtyOrdered: row.qty, partname: row.partName, partNo: row.partNo, selectedCustomer: row.customerName, productid: row.partId, CustomerPoNumber: row.customerPoNumber, itemNo: row.itemId });
    }
    else if (type === 'vendor') {
      this.setState({
        showSlideout: true, orderid: 0, jobid: row.jobId, customername: row.vendorName,
        customerpo: row.vendorPoNumber, orderdate: row.orderDate, jobno: row.jobNo, partno: row.partNo, partname: row.partName, qty: row.qty, duedate: row.dueDate, vendororderid: row.orderID, vendorordertype: row.vendorOrderType, receivedStatus: row.receivedStatus, isSpinner: false,
      });
    }
  };

  handleSelectedItem = (rowIndex: any, control: any, rowid: any, value: any) => {
    let searcResultReceiving = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.searcResultReceiving))
    );



    let selectedRow = searcResultReceiving.filter((x) => x.rowId == rowid);
    if (selectedRow.length > 0)
      selectedRow[0].received =  value;
    if (String(value).toLowerCase() == "partial" || String(value).toLowerCase() == "select") {
      selectedRow[0].qtyReceived =0;
    }
    else

    {
      selectedRow[0].qtyReceived =selectedRow[0].qty;

    }
    this.setState({
      searcResultReceiving: searcResultReceiving, isStateChanged: true
      ,footershow:true
    },
      () => {
        this.setState({ searcResultReceiving: searcResultReceiving });
      }
    );
  };

  backToJob() {
    this.props.backToJob();
  }

  handleDiscard() {
    this.setState({ isStateChanged: false,footershow:false }, () => {
      this.closeSlideOut();
    });
  }

  closeJobSlideout = () => {
    this.setState({ showJobSlideout: false, isSpinner: false, });

  };

  saveOrder = () => {

    let isFormValid = true;
    let request: any = {};
    let receivingData: any = [];
    let searcResultReceiving = this.state.searcResultReceiving;
    let isqty: any = 0;
    let isqtyitem: any = "";

    if (searcResultReceiving.filter((x)=>String(x.received).toLowerCase() == 'partial' && x.qtyReceived == 0).length > 0) {
      toast.error("Quantity can't be zero for Partial status", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "OrderSlideout",
      });
      // this.setState({ isSaveSpinner: false });
      isFormValid = false;
      return false;
    }

    
    
    searcResultReceiving.forEach(element => {

      let item: any = {};
      if (element.id > 0) {
        
        item.id = element.id;



        item.received = String(element.received).toLowerCase() == 'select' ? '' : element.received;
        item.qtyReceived = parseInt(element.qtyReceived);




        item.vendorOrderType = element.vendorOrderType;
        item.orderID = element.orderId;
        receivingData.push(item);
      }
    });
    this.state.formData.ReceivingData = receivingData;

    request = Object.assign({}, JSON.parse(JSON.stringify(this.state.formData)));
    isFormValid = true;
    // if(isqty==1)
    //   {
    //   toast.error("Please check qty for " + isqtyitem, {
    //     position: toast.POSITION.BOTTOM_RIGHT,
    //     containerId: "OrderSlideout",
    //   });
    // isFormValid = false;
    // return false;
    // }
    if (receivingData.length == 0) {
      toast.error("Please select atleast one item.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "OrderSlideout",
      });
      // this.setState({ isSaveSpinner: false });
      isFormValid = false;
      return false;
    }





    if (isFormValid) {
      this.setState({ ispopupshow: false, isSpinner: true });
      this.setState({ saveRequest: request, ispopupshow: false });

      VendorService.SaveReceivingData(request)
        .then(async (result: any | null) => {
          if (result !== null) {

            toast.success("Order has been updated.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "OrderSlideout",
            });
            // this.closeSlideOut();
            this.getJobItemsReceiving();
            this.getJobItemsCompleted();
          }
          resolve();
          this.setState({ isStateChanged: false, isSpinner: false,footershow:this.props.CustomerPoNumber ? true : false});
        })
        .catch((error) => {
          this.setState({ isdefaultColDisabled: false, isSpinner: false, isStateChanged: false });
          reject();
        });
    }
  };

  updateState1 = (e: any, index: any, type: any, rowId) => {
    if (this != undefined) {
      let isStateChanged = true;
      let start = e.target?.selectionStart;
      let end = e.target?.selectionEnd;

      let searcResultReceiving = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.searcResultReceiving))
      );


      if (type === "ReceivedQty") {
        e.preventDefault();
        let newVal = Utils.removeInvaildCharFromAmount(e.target.value);
        e.target.value = newVal;

        let selectedRow = searcResultReceiving.filter((x) => x.rowId == rowId);
        if (selectedRow.length > 0) {
          selectedRow[0].qtyReceived = newVal;
        }




        this.setState({ searcResultReceiving, isStateChanged }, () => {
          let id = "txtReceivedQty_" + rowId;
          $('#' + id).focus();
          // $("#tbltemplate")
          //   .find("input[id^='" + txtID + "']")
          //   .focus();

        });

      }
    }
  };

  updateState = (e: any, index: any, type: any, rowId) => {
    if (this != undefined) {
      let isStateChanged = true;

      let start = e.target?.selectionStart;
      let end = e.target?.selectionEnd;

      let searcResultReceiving = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.searcResultReceiving))
      );


      if (type === "ReceivedQty") {
        e.preventDefault();
        let newVal = Utils.removeInvaildCharFromAmount(e.target.value);
        e.target.value = newVal;

         let selectedRow = searcResultReceiving.filter((x) => x.rowId == rowId);
         selectedRow[0].qtyReceived = newVal;
        // if (selectedRow.length > 0) {

        //   if (selectedRow[0].qty < parseInt(newVal)) {

        //     // selectedRow[0].qtyReceived = 0;
        //     // toast.error("Please check qty", {
        //     //       position: toast.POSITION.BOTTOM_RIGHT,
        //     //       containerId: "OrderSlideout",
        //     //     });
        //   }
        //   else {



        //     if (selectedRow[0].qty == parseInt(newVal)) {
        //       //this.handleSelectedItem.bind(this,index,1,"Completed")}
        //       selectedRow[0].received = "Completed";
        //     };
        //     selectedRow[0].qtyReceived = newVal;
        //   }





          this.setState({ searcResultReceiving, isStateChanged
            ,footershow:true }, () => {
            let id = "txtReceivedQty_" + rowId;
            $('#' + id).focus();
            // $("#tbltemplate")
            //   .find("input[id^='" + txtID + "']")
            //   .focus();

          });
        // }
      }
    }
  };


  render() {
    const customTotal = (from, to, size) => (
      <span className="text-muted">
        Showing {from} to {to} of {size} entries
      </span>
    );
    const options = {

      showTotal: true,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '10', value: 10
      },
      {
        text: '25', value: 25
      },
      {
        text: '50', value: 50
      },

      {
        text: 'All', value: this.state.searcResult.length
      }] // A numeric array is also available. the purpose of above example is custom the text
    };


    const columns = [
      // {
      //   dataField: 'orderID',
      //   text: '#',
      //   headerStyle: { width: '10px' },
      // },
      {
        dataField: 'vendorPoNumber',
        text: 'PO No.',
        sort: true,
        headerStyle: { width: '100px' },
        searchable: this.state.isvendorPoNumber,
      },
      {
        dataField: 'vendorOrderType',
        text: 'Order Type',
        sort: true,
        headerStyle: { width: '100px' },
        searchable: this.state.isvendorOrderType,
      },
      {
        dataField: 'vendorName',
        text: 'Vendor',
        sort: true,
        headerStyle: { width: '150px' },
        searchable: this.state.isvendorname,
      },
      {
        dataField: 'orderDate',
        text: 'Order Date',
        sort: true,
        headerStyle: { width: '100px' },
        searchable: this.state.isOrderDate,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {moment(row.orderDate).format("MM/DD/YYYY")}
            </EllipsisWithTooltip>
          );
        },
      },
      // {
      //   dataField: 'qty',
      //   text: 'Order Qty',
      //   sort: true,
      //   headerStyle: { width: '150px' },
      //   headerClasses: 'amount',
      //   classes: 'text-end pe-4 divgreen',
      //   formatter: (cell: any, row: any, rowIndex: any) => {
      //     return (
      //       <EllipsisWithTooltip placement="bottom">
      //         {row.qty}
      //       </EllipsisWithTooltip>
      //     );
      //   },
      // },
      {
        dataField: 'totalAmount',
        text: 'Total Amount',
        sort: true,
        headerStyle: { width: '150px' },
        headerClasses: 'amount',
        classes: 'text-end pe-4 divgreen',
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {Utils.currencyFormat(row.totalAmount)}
            </EllipsisWithTooltip>
          );
        },
      }
    ];



    const columnsR = [
      // {
      //   dataField: 'orderID',
      //   text: '#',
      //   headerStyle: { width: '10px' },
      // },
      {
        dataField: 'vendorPoNumber',
        text: 'PO No.',
        sort: true,
        headerStyle: { width: '200px' },
        searchable: this.state.isvendorPoNumber,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              <Button className="btn-link" onClick={() => this.showSlideOut(row,'vendor')}>
           {row.vendorPoNumber}
           </Button>
            </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: 'vendorOrderType',
        text: 'PO Type',
        sort: true,
        headerStyle: { width: '200px' },
        searchable: this.state.isvendorOrderType,
      },
      {
        dataField: 'vendorName',
        text: 'Vendor',
        sort: true,
        headerStyle: { width: '450px' },
        searchable: this.state.isvendorname,
      },
      {
        dataField: 'orderDate',
        text: 'Order Date',
        sort: true,
        headerStyle: { width: '200px' },
        searchable: this.state.isOrderDate,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {moment(row.orderDate).format("MM/DD/YYYY")}
            </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: 'qty',
        text: 'Order Qty',
        sort: true,
        headerStyle: { width: '150px' },
        // headerClasses: 'amount',
        // classes: 'text-end pe-4 divgreen',
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {row.qty}
            </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: 'totalAmount',
        text: 'Total Amount',
        sort: true,
        headerStyle: { width: '250px' },
        headerClasses: 'amount',
        classes: 'text-end pe-4 divgreen',
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {Utils.currencyFormat(row.totalAmount)}
            </EllipsisWithTooltip>
          );
        },
      }
    ];


    const columnsC = [
      // {
      //   dataField: 'orderID',
      //   text: '#',
      //   headerStyle: { width: '10px' },
      // },
      {
        dataField: 'vendorPoNumber',
        text: 'PO No.',
        sort: true,
        headerStyle: { width: '100px' },
        searchable: this.state.isvendorPoNumber,
      },
      {
        dataField: 'vendorOrderType',
        text: 'Order Type',
        sort: true,
        headerStyle: { width: '100px' },
        searchable: this.state.isvendorOrderType,
      },
      {
        dataField: 'vendorName',
        text: 'Vendor',
        sort: true,
        headerStyle: { width: '150px' },
        searchable: this.state.isvendorname,
      },
      {
        dataField: 'orderDate',
        text: 'Order Date',
        sort: true,
        headerStyle: { width: '100px' },
        searchable: this.state.isOrderDate,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {moment(row.orderDate).format("MM/DD/YYYY")}
            </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: 'qty',
        text: 'Order Qty',
        sort: true,
        headerStyle: { width: '150px' },
        // headerClasses: 'amount',
        // classes: 'text-end pe-4 divgreen',
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {row.qty}
            </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: 'totalAmount',
        text: 'Total Amount',
        sort: true,
        headerStyle: { width: '150px' },
        headerClasses: 'amount',
        classes: 'text-end pe-4 divgreen',
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {Utils.currencyFormat(row.totalAmount)}
            </EllipsisWithTooltip>
          );
        },
      }
    ];

    const {

      showSlideout,
      showJobSlideout,
      showCustomerSlideout
    } = this.state;



    const expandSplitRow = {
      onlyOneExpanding: false,
      parentClassName: "parent-expand-foo",
      renderer: (row: any, rowIndex: any) => renderItemTableChild(row, rowIndex),
      showExpandColumn: true,
      // expandByColumnOnly: true,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <div></div>;
        }
        return <div></div>;
      },
      expandColumnRenderer: ({ expanded }) => {

        if (expanded) {
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };
    const whiteBgFormatter = (column, colIndex) => {
      return <div className="white-bg"></div>;
    };

    const renderItemTableChild = (mainrow, rowIndex) => {

      let splitDetail: any = [];
      splitDetail = this.state.searcResultReceiving.filter(
        (x) => x.orderID === mainrow.orderID && x.rowType === 2
      );


      const columnCOAChild = [

       
        {
          dataField: "itemName",
          text: "Item Name",
          classes:"expanding-bar text-truncate",
          headerStyle: { width: "10%" },
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
             
              <div className="d-flex align-items-center txt-bold">
              <div style={{width: '150px'}}>
             <EllipsisWithTooltip placement="bottom">
               {row.itemName}
             </EllipsisWithTooltip>
           </div>
           </div>
            );
          },
        },
        {
          dataField: "partName",
          text: "Part Description",
          classes:"expanding-bar text-truncate",
          headerStyle: { width: "10%" },
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
             
              <div className="d-flex align-items-center txt-bold">
              <div style={{width: '150px'}}>
             <EllipsisWithTooltip placement="bottom">
               {row.partName}
             </EllipsisWithTooltip>
           </div>
           </div>
            );
          },
        },
        {
          dataField: "customerPoNumber",
          text: "CO No.",
          classes:"expanding-bar",
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
                <Button className="btn-link" onClick={() => this.showSlideOut(row,'customer')}>
             {row.customerPoNumber}
             </Button>
              </EllipsisWithTooltip>
            );
          },
        },
        {
          dataField: "dueDate",
          text: "Due Date",
          classes:"expanding-bar",
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
                              { row.dueDate=="0001-01-01T00:00:00" || row.dueDate==undefined ? "" : moment(row.dueDate).format("MM/DD/YYYY")}
              </EllipsisWithTooltip>
            );
          },
        },

 {
          dataField: "jobNo",
          text: "Job No.",
          classes:"expanding-bar",
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
               <Button className="btn-link" onClick={() => this.showSlideOut(row,'job')}>
             {row.jobNo}
             </Button>
              </EllipsisWithTooltip>
            );
          },
        },
        {
          dataField: "qty",
          text: "Requested Qty",
          hidden: false,
          classes:"expanding-bar",
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
                {row.qty}
              </EllipsisWithTooltip>
            );
          },
        },

        {
          dataField: "rowId",
          text: "rowId",
          hidden: true,
          classes:"expanding-bar",
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
                {row.rowId}
              </EllipsisWithTooltip>
            );
          },
        },
        {
          dataField: 'received',
          text: 'Receive Status',
         headerStyle: { width: '200px' },
         classes:"expanding-bar",
         // style: { overflow: 'inherit' },
          hidden: false,
          editable: false,
          headerAttrs: (column, colIndex) => (
            { 'tabIndex': '-1' }
          ),
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (

<div  className="w-auto" >
            <div 
          onClick={() => $('#tblReceiving td.reset-expansion-style').css('overflow', 'inherit')}
            >
            <div className="btn-group" >
                <Form.Label>
                  <SingleDropdownListWithoutSearch
                    itemList={this.state.receivingStatusOptions}
                    handleSelectedItem={this.handleSelectedItem.bind(this, rowIndex, 1, row.rowId)}
                    defaultItem={row.received == '' || row.received == null ? 'Select':row.received}
                    defaultText={"Select Filter"}
                    defaultName={row.received == ''  || row.received == null ? 'Select':row.received}
                    controlID="1"
                
                    id={"ddlreceivingStatus"}
                    className="more-action"
                  /></Form.Label>
</div>
</div>
              </div>
            );
          },

        },
        {
          dataField: "partialQty",
          text: "Received Qty",
          headerStyle: { width: '100px' },
          hidden: false,
          classes:"expanding-bar",
          formatter: (cell: any, row: any, rowIndex: any) => {
            let id = "txtReceivedQty_" + row.rowId;
            return (

              <EllipsisWithTooltip placement="bottom">
                <Form.Control
                  tabIndex={this.state.tabEnable}
                  id={id}
                  type="text"
                  placeholder="Qty Received"
                  value={ row.qtyReceived}
                  onChange={(e) => this.updateState(e, rowIndex, "ReceivedQty", row.rowId)}
                  //onBlur={(e) => this.updateStateblur(e, rowIndex, "ReceivedQty", row.rowId)}
                  autoComplete="off"
                  disabled={false}
                  title=""
                />
              </EllipsisWithTooltip>
            );
          },
        },
        {
          dataField: "",
          text: "",
          headerClasses: "blankLastChildofChild",
          classes:"expanding-bar",
        },


      ];

      return (
        <>
        <div className='px-4 p-4 height-72'>
          <BootstrapTable
            id="tblCOAchild"
            keyField="rowId"
            data={splitDetail}
            columns={columnCOAChild}
            //rowEvents={this.rowEvents}
            selectRow={{
              mode: 'checkbox', clickToSelect: false, hideSelectAll: false,
              selected: splitDetail.filter((x) => x.selected === true).map(r => r.rowId),
              //onSelect: this.handleOnSelect,
              //onSelectAll: this.handleOnSelectAll,
              hideSelectColumn: true
            }}

          />
          </div>
        </>
      );
    };






    return (

      <>
      
        <React.Fragment>



          <Container fluid className="body-sec" >
          {/* <Container  
          fluid

          className="body-sec viewer-panel back-drop p-4"
          style={{ minWidth: "84%", width: "992px", maxWidth: "100%", }}
> */}
            <div className="page-heading underline d-flex  ">

              Vendor Orders
              <div className="action-group d-flex flex-row ml-auto">
                { <Button onClick={this.shownewslideout}>Add Vendor Order</Button> }
              </div>
            </div>

            <Tab.Container transition={false} defaultActiveKey={this.state.defaultActiveKey} >

              <section className="pb-3">
                <Nav className="nav nav-underline border-bottom">
                  <Nav.Item className="nav-item">
                    <Nav.Link eventKey="first">Orders <span className="badge badge-light">{this.state.searcResult.length}</span>


                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item">
                    <Nav.Link eventKey="second">Receiving
                      <span className="badge badge-light">{this.state.searcResultReceiving.filter((x) => x.rowType == 1).length}</span>

                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item">
                    <Nav.Link eventKey="third">Completed
                      <span className="badge badge-light"> {this.state.searcResultCompleted.length} </span>

                    </Nav.Link>
                  </Nav.Item>

                </Nav>
              </section>

              <Tab.Content>
                <Tab.Pane eventKey="first">

                  <ToolkitProvider
                    keyField="id"
                    data={this.state.searcResult}
                    columns={columns}

                    search
                  >
                    {
                      props => (
                        <div>

<section className="d-flex flex-wrap gap-3 pb-2">
                            <div className="position-relative">
                              <SearchBar {...props.searchProps} />
                            </div>

                            <div 
                            //className="input-group w-auto"
                            >
                              <Form.Label>
                                <SingleDropdownListWithoutSearch
                                  
                                  itemList={this.state.selectOptions}
                                  handleSelectedItem={this.handleSelectedItem1.bind(this, 1)}
                                  defaultItem={this.state.selectedcolumn}
                                  defaultText={"Select Filter"}
                                  defaultName={this.state.selectedcolumn}
                                  controlID="1"
                                  id={"ddlselected"}

                                /></Form.Label>
                            </div>

                          </section>
                          <section className="pb-3">
                            <div className="table-responsive1 divbottom"  >
                              <div className="ml-auto transactions-list">
                          <BootstrapTable
                            {...props.baseProps}
                            rowEvents={this.rowEvents}
                            pagination={paginationFactory(options)}
                          />
                       </div>
                            </div>
                          </section>
                        </div>
                      )
                    }
                  </ToolkitProvider>


                </Tab.Pane>
                <Tab.Pane eventKey="second">


                  <ToolkitProvider

                    data={this.state.searcResultReceiving.filter((x) => x.rowType === 1)}
                    columns={columnsR}

                    search={ { defaultSearch: this.props.CustomerPoNumber } }

                  >
                    {
                      props => (
                        <div>

<section className="d-flex flex-wrap gap-3 pb-2">
                            <div className="position-relative">
                              <SearchBar {...props.searchProps} />
                            </div>

                            <div 
                            //className="input-group w-auto"
                            >
                              <Form.Label>
                                <SingleDropdownListWithoutSearch
                                  
                                  itemList={this.state.selectOptions}
                                  handleSelectedItem={this.handleSelectedItem1.bind(this, 1)}
                                  defaultItem={this.state.selectedcolumn}
                                  defaultText={"Select Filter"}
                                  defaultName={this.state.selectedcolumn}
                                  controlID="1"
                                  id={"ddlselected"}

                                /></Form.Label>
                            </div>

                          </section>
                          <section className="pb-3">
                            <div className="table-responsive1 divbottom"  >
                              <div className="ml-auto transactions-list">
                                {this.props.CustomerPoNumber ? (
                                  <BootstrapTable id="tblReceiving"
                                  {...props.baseProps}
                                  keyField="rowId"
                                  rowEvents={this.rowEvents}
                                  expandRow={{
                                    ...expandSplitRow,
                                    expanded: this.state.expandedRows
                                  }}
                                  
                                  pagination={paginationFactory(options)}
                                  />
                                ):(
                                  <BootstrapTable id="tblReceiving"
                                  {...props.baseProps}
                                  keyField="rowId"
                                  // rowEvents={this.rowEvents}
                                  expandRow={expandSplitRow}   
                                  pagination={paginationFactory(options)}
                                  defaultSorted={[{
                                    dataField: 'orderDate', 
                                    order: 'desc'             
                                  }]}
                                />
                                )}
                         
                            </div>
                            </div>
                            
                          </section>
                          <div className="filter-sec"></div>
                          <div className="filter-sec"></div>
                          {/* <div className={"footer-section viewer-panel"}>
                            <div>
                              <div className="d-flex align-items-center justify-content-end height-72">
                                <div className="d-flex align-items-center">



                              



                                </div>
                              </div>
                            </div>
                          </div> */}


                         
                        </div>
                      )
                    }
                  </ToolkitProvider>

                </Tab.Pane>
                <Tab.Pane eventKey="third">

                  <ToolkitProvider
                    keyField="id"
                    data={this.state.searcResultCompleted}
                    columns={columnsC}

                    search
                  >
                    {
                      props => (
                        <div>

                          <section className="d-flex flex-wrap gap-3 pb-2">
                            <div className="position-relative">
                              <SearchBar {...props.searchProps} />
                            </div>

                            <div
                            //className="input-group w-auto"
                            >
                              <Form.Label>
                                <SingleDropdownListWithoutSearch

                                  itemList={this.state.selectOptions}
                                  handleSelectedItem={this.handleSelectedItem1.bind(this, 1)}
                                  defaultItem={this.state.selectedcolumn}
                                  defaultText={"Select Filter"}
                                  defaultName={this.state.selectedcolumn}
                                  controlID="1"
                                  id={"ddlselected"}

                                /></Form.Label>
                            </div>

                          </section>
                          <section className="pb-3">
                            <div className="table-responsive divbottom"  >
                              <div className="ml-auto transactions-list">
                                <BootstrapTable
                                  {...props.baseProps}
                                  rowEvents={this.rowEvents}
                                  pagination={paginationFactory(options)}
                                />
                              </div>
                            </div>
                          </section>
                        </div>
                      )
                    }
                  </ToolkitProvider>


                </Tab.Pane>
              </Tab.Content>

            </Tab.Container>

            {(this.state.footershow &&
        // <div className="TabFooter-Pay"
        // style={{ minWidth: "83.7%", width: "992px", maxWidth: "100%" }}
        // >
        <div className="footer-section-new1 viewer-panel"
        style={{ minWidth: "84%", width: "992px", maxWidth: "100%", }}
        >
           
          <div className="bulkSelectFooter d-flex gap-3 align-items-center justify-content-end height-72">
            <div className="d-flex gap-3 justify-content-end px-2">
           

                                  {(this.state.isStateChanged)
                                    && (
                                      <>
                                  <Button
                                    variant="primary"
                                    type="button"
                                    className="space btn btn-primary btn-discard"
                                    onClick={() =>
                                      this.handleDiscard()
                                    }
                                  >
                                    Discard
                                  </Button>
                                        <Button
                                          id="btnSaveVendor"
                                          variant="primary"
                                          type="button"
                                          onClick={() => this.saveOrder()}
                                          disabled={false}
                                        >
                                          {"Update"}
                                        </Button>
                                      </>
                                    )}
                                      {(this.props.CustomerPoNumber)
                                    && (
                                      <>

                                        <Button
                                          id="btnSaveVendor"
                                          variant="primary"
                                          type="button"
                                          onClick={() => this.backToJob()}
                                          disabled={false}
                                        >
                                          {"Back to Job Page"}
                                        </Button>
                                      </>
                                    )}

            </div>
          </div>
        </div>)}

            {(showSlideout &&

<Vendororderslideout
closeSlideOut={this.closeSlideOut}
orderid={this.state.orderid}
receivedStatus={this.state.receivedStatus}
CustomerPoNumber={this.state.customerpo}
poNumber={this.state.poNumber}
productid={this.state.productid}
partname={this.state.partname}
partNo={this.state.partNo}
qtyOrdered={this.state.qtyOrdered}
jobid={this.state.jobid}
itemNo={this.state.itemNo}
vendororderid={this.state.vendororderid}
vendorordertype={this.state.vendorordertype}
selectedCustomer={this.state.selectedCustomer}
orderdate={this.state.orderdate}
dueDate={this.state.dueDate}
jobDesc={this.state.jobDesc}
id={this.state.id}
>


</Vendororderslideout>


              )}

{showJobSlideout && (

<JobCreations
  closeSlideOut={this.closeJobSlideout}
  jobid={this.state.jobid}
  orderid={this.state.orderid}
  CustomerPoNumber={this.state.CustomerPoNumber}
  poNumber={this.state.poNumber}
  productid={this.state.productid}
  partname={this.state.partname}
  partNo={this.state.partNo}
  qtyOrdered={this.state.qtyOrdered}

  itemNo={this.state.itemNo}
  selectedCustomer={this.state.selectedCustomer}
  orderdate={this.state.orderdate}
  dueDate={this.state.dueDate}
  jobDesc={this.state.jobDesc}
  id={this.state.orderdetailid}

>
</JobCreations>
)}

{(showCustomerSlideout &&
                <Customerorderslideout
                  closeSlideOut={this.closeSlideOut}
                  orderid={this.state.orderid}
                >


                </Customerorderslideout>
              )}

          </Container>

          {(this.state.isSpinner || this.state.isSaveSpinner) && (
            <div className="loader-spinner d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="success" />
            </div>
          )}

        </React.Fragment>
      </>
    );
  }
}
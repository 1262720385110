import React from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  Container,
  Dropdown,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import DatePicker from "react-datepicker";
import { Utils } from "../../Common/Utilis";
import { InvoiceService } from "../../Common/Services/InvoiceService";
import { resolve, reject } from "q";
import chevronRight from "../../Common/Assets/Images/icons/bx-chevron-right.svg";
import "../../App.scss";

import { CustomerSearch } from "../../Common/Components/CustomerSearch";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { ProductService } from "../../Common/Services/ProductService";
import { ConfirmationModal } from "../../Common/Components/ConfirmationModal";
import { ConfirmationModalNonTable } from "../../Common/Components/ConfirmationModalNonTable";
import { FiChevronDown } from "react-icons/fi";
import moment from "moment-timezone";
import { confirmAlert } from "react-confirm-alert";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { MdBuild } from 'react-icons/md';
import { debug } from "console";
import Dropzone from "react-dropzone";
import {
  faPlus,
  faMinus,
  faPaperclip,
  faFile,
  faFileImage,
  faFileExcel,
  faFilePdf,
  faFileCsv,
  faFileWord,
} from "@fortawesome/free-solid-svg-icons";
import ScrollContainer from "react-indiana-drag-scroll";
import { CommentBox } from "../../Common/Components/CommentBox";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { SingleDropdownListWithoutSearch } from "../../Common/Components/SingleDropdownListWithoutSearch";

import PDFViewer from "../pdf-viewer";
let routeLeavingGuard: any = null;
let docViewerFiles: any = [];
let storage = JSON.parse(localStorage.getItem("storage")!);
let currentUtcTime = storage === null ? "America/Chicago" : (storage.currentUtcTime as any);


  //.format('MM/DD/YY');

export class InvoiceEntryslideout extends React.Component<any, any> {
  private scrollvdRef: any;
  private customerSearch: any;
  private trDate: any;
  private trdueDate: any;
  private checkAllRef: any = {};
  private scrollArea: any = {};
  private parentScroll: any;
  private scrollheight: any;
  private footerchk: any;
  private ChildComment: any;

  private templateColumns = [
    {
      dataField: "vendorPoNumber",
      text: "Order No",
      headerStyle: { width: '70px' },
    },
    {
      dataField: 'orderDate',
      text: 'Order Date',
      sort: true,

      headerStyle: { width: '70px' },
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <EllipsisWithTooltip placement="bottom">
            {moment(row.orderDate).format("MM/DD/YYYY")}
          </EllipsisWithTooltip>
        );
      },
    },
   
    {
      dataField: "description",
      text: "Description",
     // headerStyle: { width: '300px' },
      classes:"text-truncate",
      headerStyle: { width: "40%" },
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
         
          <div className="d-flex align-items-center txt-bold">
          <div style={{width: '550px'}}>
         <EllipsisWithTooltip placement="bottom">
           {row.description}
         </EllipsisWithTooltip>
       </div>
       </div>
        );
    
    },
    },

    {
      dataField: "amount",
      text: "Amount",   
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtamount" + rowIndex + "txt";
        let disable = true;
        return (
          <div>

<Form.Control
              tabIndex={this.state.tabEnable}
              id={id}
              type="text"
              // placeholder="Enter Qty"
              value={Utils.currencyFormat(row.amount)}
              onChange={(e) => this.updateState(e, row, "amount", id)}
              autoComplete="off"
              pattern="[+-]?\d+(?:[.,]\d+)?"
              maxLength={20}
              style={{textAlign:'right'}}
             // disabled={disable}
              title=""
            />
          </div>
          // <EllipsisWithTooltip placement="bottom">
          //   {Utils.currencyFormat(row.amount)}
          // </EllipsisWithTooltip>
        );
      },   
    },

  ];

  constructor(props: any) {
    super(props);
    this.parentScroll = React.createRef();
    this.scrollvdRef = React.createRef();
    this.trDate = React.createRef();
    this.ChildComment = React.createRef();
    this.state = {
      templateColumns: this.templateColumns,
      VendorCode:"",
      VendorName:"",
      PaymentMethodname:"Select",
      accountingperiodname:"",
      formData: {
        Id:0,
        InvoiceNo:'',
        TenantId:0,
        InvoiceDate:null,
        DueDate:null,
        AccountingPeriod:null,
        printpingCharge:0.0,
        Amount:0.0,
        TotalAmount:0.0,
        VendorCode:'',
        VendorName:'',
        VendorId:'',
        VendorInvoiceDetail:[],
        PaymentMethod:null,
      },
      invoicedetails :[

      ],
      Accountingperiod:[],
      PaymentMethod:[{name:"ACH",id:"ACH"},
      {name:"Check",id:"Check"}],
      
    };

  }


  beforeunload(e: { preventDefault: () => void; returnValue: boolean }) {
    if (
      this.state.isStateChanged &&
      routeLeavingGuard !== "routeLeavingGuardTrue"
    ) {
      e.preventDefault();
      e.returnValue = true;
    }
  }

  routeLeavingGuardConfirm = (childState: any) => {
    if (childState === true) {
      routeLeavingGuard = "routeLeavingGuardTrue";
    } else {
      routeLeavingGuard = null;
    }
  };

  componentDidMount() {
   this.getAccountingPeriods();
   this.getGetInvoice();  
  }

  getAccountingPeriods = () => {

 
    let accountingPeriod: any = [];
    let currentDate = new Date(Utils.convertUtcToTimezoneFormat(new Date(),currentUtcTime));


    currentDate.setDate(1);
    currentDate.setMonth(currentDate.getMonth() - 4);
    accountingPeriod.push({ id: moment(currentDate).format("MM/DD/YYYY"), name: moment(currentDate).format("MM/DD/YYYY")  });
    currentDate.setMonth(currentDate.getMonth() + 1);
    accountingPeriod.push({ id: moment(currentDate).format("MM/DD/YYYY"), name: moment(currentDate).format("MM/DD/YYYY") });
    currentDate.setMonth(currentDate.getMonth() + 1);
    accountingPeriod.push({ id: moment(currentDate).format("MM/DD/YYYY"), name: moment(currentDate).format("MM/DD/YYYY") });
    currentDate.setMonth(currentDate.getMonth() + 1);
    accountingPeriod.push({ id: moment(currentDate).format("MM/DD/YYYY"), name: moment(currentDate).format("MM/DD/YYYY") });
    currentDate.setMonth(currentDate.getMonth() + 1);
    accountingPeriod.push({ id: moment(currentDate).format("MM/DD/YYYY"), name: moment(currentDate).format("MM/DD/YYYY") });
    currentDate.setMonth(currentDate.getMonth() + 1);
    accountingPeriod.push({ id: moment(currentDate).format("MM/DD/YYYY"), name: moment(currentDate).format("MM/DD/YYYY") });
    currentDate.setMonth(currentDate.getMonth() + 1);
    accountingPeriod.push({ id: moment(currentDate).format("MM/DD/YYYY"), name: moment(currentDate).format("MM/DD/YYYY") });
    currentDate.setMonth(currentDate.getMonth() + 1);
    accountingPeriod.push({ id: moment(currentDate).format("MM/DD/YYYY"), name: moment(currentDate).format("MM/DD/YYYY") });
    currentDate.setMonth(currentDate.getMonth() + 1);
    accountingPeriod.push({ id: moment(currentDate).format("MM/DD/YYYY"), name: moment(currentDate).format("MM/DD/YYYY") });

    this.setState({ Accountingperiod: accountingPeriod }, () => { });

  };
  // getAccountingPeriods = () =>{ 
  //   let accountingPeriod: any = [];
  //   let currentDate =  formattedDate;
  //   currentDate.setDate(1);
  //   currentDate.setMonth(currentDate.getMonth() - 4);
  //   accountingPeriod.push({id:currentDate.toLocaleDateString(),name:currentDate.toLocaleDateString()});
  //   currentDate.setMonth(currentDate.getMonth() + 1);
  //   accountingPeriod.push({id:currentDate.toLocaleDateString(),name:currentDate.toLocaleDateString()});
  //   currentDate.setMonth(currentDate.getMonth() + 1);
  //   accountingPeriod.push({id:currentDate.toLocaleDateString(),name:currentDate.toLocaleDateString()});
  //   currentDate.setMonth(currentDate.getMonth() + 1);
  //   accountingPeriod.push({id:currentDate.toLocaleDateString(),name:currentDate.toLocaleDateString()});
  //   currentDate.setMonth(currentDate.getMonth() + 1);
  //   accountingPeriod.push({id:currentDate.toLocaleDateString(),name:currentDate.toLocaleDateString()});
  //   currentDate.setMonth(currentDate.getMonth() + 1);
  //   accountingPeriod.push({id:currentDate.toLocaleDateString(),name:currentDate.toLocaleDateString()});
  //   currentDate.setMonth(currentDate.getMonth() + 1);
  //   accountingPeriod.push({id:currentDate.toLocaleDateString(),name:currentDate.toLocaleDateString()});
  //   currentDate.setMonth(currentDate.getMonth() + 1);
  //   accountingPeriod.push({id:currentDate.toLocaleDateString(),name:currentDate.toLocaleDateString()});
  //   currentDate.setMonth(currentDate.getMonth() + 1);
  //   accountingPeriod.push({id:currentDate.toLocaleDateString(),name:currentDate.toLocaleDateString()});

  //   this.setState({Accountingperiod:accountingPeriod} ,()=>{});
   
  // };

  getGetInvoice = () => {

debugger;
    let requestlist = { ...this.state.requestlist };
    requestlist.invoiceId = this.props.invoiceId;
    requestlist.orderIds = this.props.orderIds.join(',');
    InvoiceService.GetVendorInvoice(requestlist)
  .then(async (result: any | null) => {
    
    if (result != null) {
     let formData = { ...this.state.formData };
     formData.Id = result.id;
     formData.InvoiceNo = result.invoiceNo;
     formData.TenantId = result.tenantId;
     formData.InvoiceDate = result.invoiceDate;
     formData.DueDate = result.dueDate;
     if(result.paymentMethod == null)
     formData.PaymentMethod = 'Select Payment Method';
    else 
     formData.PaymentMethod = result.paymentMethod;
     let currentDate =  new Date(Utils.convertUtcToTimezoneFormat(new Date(),currentUtcTime));
    currentDate.setDate(1);
    currentDate.setMonth(currentDate.getMonth());
     if(result.accountingPeriod == null)
      formData.AccountingPeriod = moment(currentDate).format("MM/DD/YYYY");
     else 
       formData.AccountingPeriod = result.accountingPeriod;

     formData.Amount = result.amount;
     formData.TotalAmount = result.totalAmount;
     formData.VendorCode = result.vendorCode;
     formData.VendorName = result.vendorName;
     formData.VendorId = result.customerId;
     debugger;
     this.setState({formData:formData, invoicedetails:result.vendorInvoiceDetail,PaymentMethodname: result.paymentMethod});
    }
    resolve();
  })
  .catch((err: any) => {
    toast.error(`Server Error, ${err}`);
    reject();
  });
  };

  
saveItem = (isPrintRequired:any) => {
  let formData = { ...this.state.formData };
   

  let isFormValid = true;
  let request: any = {};
 
  if(this.state.formData.InvoiceDate === null)
     this.state.formData.InvoiceDate =  new Date(Utils.convertUtcToTimezoneFormat(new Date(),currentUtcTime));

  if(this.state.formData.DueDate === null)
     this.state.formData.DueDate =  new Date(Utils.convertUtcToTimezoneFormat(new Date(),currentUtcTime));

     let invoicedetails: any = [];

     let jobList = this.state.invoicedetails;
     jobList.forEach(element => {
 
       let item: any = {};
     
       item.Amount = element.amount;
       item.Description = element.description;
       item.OrderDate = element.orderDate;
       item.ProductId = element.productId;
       item.vendorPoNumber = element.vendorPoNumber;
       item.OrderId = element.orderId;
       item.InvoiceId =  this.state.formData.Id;         
       invoicedetails.push(item);
     
     });
     //this.state.formData.OrderId=this.props.orderid;
     this.state.formData.VendorInvoiceDetail = invoicedetails;  

  request = Object.assign({}, JSON.parse(JSON.stringify(this.state.formData)));
  


  if(this.state.formData.PaymentMethod == null ||  this.state.formData.PaymentMethod =="Select Payment Method")
   {
    isFormValid = false;
    toast.success('Please select payment method.', {
      position: toast.POSITION.BOTTOM_RIGHT,
      containerId: "OrderSlideout",
    });
    this.setState({ isdefaultColDisabled: false, isSpinner: false });
    isFormValid = false;
    reject();
   }


   if(this.state.formData.InvoiceNo == null ||  this.state.formData.InvoiceNo.trim() === "")
    {
     isFormValid = false;
     toast.success('Please enter invoice no.', {
       position: toast.POSITION.BOTTOM_RIGHT,
       containerId: "OrderSlideout",
     });
     this.setState({ isdefaultColDisabled: false, isSpinner: false });
     isFormValid = false;
     reject();
    }
 
   
    if(this.state.formData.DueDate < this.state.formData.InvoiceDate)
      {
       isFormValid = false;
       toast.success('Please duedate cannot less than invoice date', {
         position: toast.POSITION.BOTTOM_RIGHT,
         containerId: "OrderSlideout",
       });
       this.setState({ isdefaultColDisabled: false, isSpinner: false });
       isFormValid = false;
       reject();
      }
 
      
  if (isFormValid) {
    this.setState({ isSaveSpinner: true });
    InvoiceService.SaveVendorInvoice(request)
      .then(async (result: any | null) => {
        if (result !== null) {
           this.setState({ isSaveSpinner: false });
           toast.success('Invoice Saved Successfully.', {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "OrderSlideout",
          });
        }
        
            this.closeSlideOut();

        resolve();
      })
      .catch((error) => {
        this.setState({ isdefaultColDisabled: false, isSpinner: false });
        reject();
      });
  }
};

printItem = () => {
  let request: any = {};
  request.invoiceId = this.props.invoiceId;  
  this.setState({ isSpinner:true});
  
  InvoiceService.DownloadVendorInvoiceFile(request)
    .then(async (result: any | null) => {
      //this.HidecloseSlideOut();
      this.setState({ showAfterSaveModelPopup: false, isSpinner:false, });
      resolve();
    })
    .catch((error) => {
      reject();
    this.setState({ showAfterSaveModelPopup: false, isSpinner:false, });
    });
};

  closeSlideOut = () => {
    this.props.closeSlideOut(this.state?.shouldReloadMainPage);
  };

  handleChange = (event: any, FieldType: string) => {
  
    let isStateChanged = true;
    
    let formData = { ...this.state.formData };

    
    if (FieldType === "InvoiceNo") {
      formData.InvoiceNo = event.target.value;
    }
    else if (FieldType === "InvoiceDate") {

      formData.InvoiceDate = event.toISOString();
      let txtID = "txtInvoiceDate";
        setTimeout(() => {
          this.trDate.current.setOpen(true);
          $("input[id^='" + txtID + "']").focus();
        }, 50);
    }
    else if (FieldType === "DueDate") {

      formData.DueDate = event.toISOString();
        let txtID = "txtDueDate";
        setTimeout(() => {
          this.trDate.current.setOpen(true);
          $("input[id^='" + txtID + "']").focus();
        }, 50);
    }

    this.setState({ formData, isStateChanged, isSpinner: false }, () => {

    });
  };


  handleSelectedItem1 = (control: any, id: any) => {
   
    if(control == 1)
      {
    this.state.formData.AccountingPeriod = id;
    this.setState({accountingperiodname:id});
      }
    else if(control == 2)
      {
    this.state.formData.PaymentMethod = id;
  this.setState({PaymentMethodname:id});
      }
  };

  deleteOrder = () => {
    this.setState({ isSpinner: true });
    let request = { ...this.state.requestlist };
    request.invoiceid = this.state.formData.Id;
    InvoiceService.DeleteVendorInvoice(request)
      .then(async (result: any | null) => {
        if (result !== null) {

          this.closeSlideOut();

          toast.success("Invoice has been deleted.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "OrderSlideout",
          });



          this.setState({ isSpinner: false });

        }
        resolve();
      })
      .catch((error) => {
        this.setState({ isdefaultColDisabled: false, isSpinner: false });
        reject();
      });
  };

  moreActions = (eventKey: any) => {
   

    if (eventKey == "Delete") {
      //this.deleteOrder();

      if (this.state.IsDeleted == false) {

        toast.success("Invoice cannot be delete due to payment is recieved.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "OrderSlideout",
        });

      }
      else {
        let masg = "";

        masg = "Are you sure you want to delete this invoice?";

        confirmAlert({
          title: "Delete Invoice",

          message: masg,
          buttons: [
            {
              label: "Yes",
              onClick: () => this.deleteOrder(),
            },
            {
              label: "No",
              onClick: () => reject(),
            },
          ],

          closeOnEscape: false,
          closeOnClickOutside: false,
        });
      }
    }

    if (eventKey == "print") {

      this.printItem();
    }
    
  };

  updateState = (e: any, row: any, type: any, txtID) => {
    if (this != undefined) {
      let isStateChanged = true;
      let start = e.target?.selectionStart;
      let end = e.target?.selectionEnd;

      let  Total=0;

     

      let invoicedetails = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.invoicedetails))
      );

     

      
 
     
       if (type === "amount") {
        e.preventDefault();
         
        let newVal = Utils.removeInvaildCharFromAmount(e.target.value);
        e.target.value = newVal;
      
        let itemRows = invoicedetails.filter((x)=>x.vendorPoNumber == row.vendorPoNumber);
        if(itemRows != null && itemRows.length> 0)
        {
          itemRows.forEach(element => {
            element.amount = e.target.value;
          });

        }

        const total = invoicedetails.reduce((sum, obj) => {
          debugger;
        
          const currentvalue = Number(obj.amount);
          return sum + currentvalue;
        }, 0);
        
                 
                  this.state.formData.TotalAmount=total;
       
        this.setState({ invoicedetails:invoicedetails, isStateChanged }, () => {
debugger;



          $("#tbltemplate")
            .find("input[id^='" + txtID + "']")
            .focus();
          let txtdesc = $("#tbltemplate").find(
            "input[id^='" + txtID + "']"
          )[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }
      
    }
  };
  render() {
    const {

      showjobrModal,

    } = this.state;

    var date =  new Date(Utils.convertUtcToTimezoneFormat(new Date(),currentUtcTime));
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var first = date.getDate() - date.getDay();

    return (
      //  <div className="transaction-slideout invoice-entry pos-fxd index-1000">

      <section>
        <div className="details-drawer"
          style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
        // className="back-drop1  viewer-panel1 opacity "
        >
          <ToastContainer
            containerId={"userDetailSlideoutcontainer"}
            autoClose={3000}
          />

          <div className="d-flex">
            {!this.state.isOpenViewer && (
              <div
                className="back-drop1  viewer-panel1 opacity "
                onClick={() => this.closeSlideOut()}
              ></div>
            )}

            <Container
              fluid

              className="body-sec viewer-panel back-drop p-0 "
              style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}

            >
              <div
                className="container-fluid py-1 bg-white d-flex align-items-center  border-bottom round-4"
              >
                <div className="divheader">
                  <h5 className="my-title">
                  {this.state.formData.InvoiceNo == "" && <span>Invoice Generate </span>}
              {this.state.formData.InvoiceNo != "" && (
                <span>Invoice No : {this.state.formData.InvoiceNo}</span>
              )}
                  </h5>


                  <div className="header-popout ml-auto">





                  <div className="container text-center">
  <div className="row align-items-start">
    <div className="col">
      </div>

      <div className="col">
                        {this.state.jobid != 0
                          //  && this.state.jobTrackerId === 0 
                          //                             && !this.state.processVendorPOCreated && !this.state.materialVendorPOCreated
                          &&

                          <div className="action">

                            <Dropdown
                              className="more-action"
                              
                              onSelect={(event: any) => { this.moreActions(event); }}
                            >
                              <Dropdown.Toggle
                                className="btn-outline-primary btn btn-primary more"
                                id="dropdown-more"
                              >
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                  <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                  <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                </svg>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {this.state.formData.Id > 0 && (
                                  <>
                                  
                                    <Dropdown.Item
                                      eventKey={"print"}
                                      key={1}
                                    >
                                      {"Print"}
                                    </Dropdown.Item>

                                   
                                   
                                    {true && (
                                      <Dropdown.Item
                                        eventKey={"Delete"}
                                        key={1}
                                      >
                                        {"Delete Invoice"}
                                      </Dropdown.Item>
                                    )}

                                  </>
                                )}

                              </Dropdown.Menu>
                            </Dropdown>



                          </div>


                        }
                      </div>

      <div className="col">
      <button
                          type="button"
                          className="btn btn-primary cross"
                          onClick={this.closeSlideOut}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                              fill="#ffffff"
                            />
                          </svg>
                        </button>
      </div>
      </div>
      </div>
                  </div>
                </div>
              </div>
              <div
                className="scroll"
              >

                <section className="pb-3">
                  <div className="row g-3">

                    <div className="col-12 col-md-3" >
                      <Form.Group controlId="customer">
                        <Form.Label className="font-semibold"> Vendor</Form.Label>
                        <div className="single-select">
                        <Form.Control type="text" id="txtCustomer"

value={this.state.formData.VendorName}


autoComplete='off'
disabled={true}
/>
                        </div>

                      </Form.Group>

                    </div>
                    <div className="col-12 col-md-3" >
                      <Form.Group controlId="customercode">
                        <Form.Label className="font-semibold">  Vendor Code</Form.Label>
                        <div className="single-select">
                        <Form.Control type="text" id="txtCustomercode"

value={this.state.formData.VendorCode}
style={{width:'52%'}}
autoComplete='off'
disabled={true}
/>
                        </div>

                      </Form.Group>
                    </div>

                   
                    
                    <div className="col-12 col-md-3" >
                     
                     <Form.Label className="font-semibold">  Payment Method</Form.Label>
                     <div  className="w-auto" >
                     <Form.Label>
                     { this.state.formData.PaymentMethod != null && <SingleDropdownListWithoutSearch
                     tabIndex={this.state.tabEnable}
                     itemList={this.state.PaymentMethod}
                     handleSelectedItem={this.handleSelectedItem1.bind(this,2)}                        
                     defaultItem={this.state.formData.PaymentMethod}
                     defaultText={"Select Payement"}
                     defaultName={this.state.PaymentMethodname}
                    
                     controlID="1"
                     id={"ddlPaymentMethod"}
                   />}
                    </Form.Label>
                     </div>

                  
                 </div>
                  

                    <div className="col-12 col-md-3" >
                      <Form.Group controlId="customercode">
                        <Form.Label className="font-semibold">Due  Date</Form.Label>
                        <div className="date-picker calender-picker">
                                   <DatePicker
                                     ref={this.trDate}
                                     id="txtDueDate"
                                     tabIndex={this.state.tabEnable}
                                     className="form-control px-0 w-75 text-center"
                                     placeholderText={"MM/DD/YY"}
                                     dateFormat="MM/dd/yy"
                                     selected={
                                       this.state.formData.DueDate === null
                                         ?  new Date(Utils.convertUtcToTimezoneFormat(new Date(),currentUtcTime))
                                         : this.state.formData.DueDate === ""
                                           ?  new Date(Utils.convertUtcToTimezoneFormat(new Date(),currentUtcTime))
                                           : new Date(this.state.formData.DueDate)
                                     }
                                     onChange={(event: any) =>
                                       this.handleChange(event, "DueDate")
                                     }
                                     //onClick={(e: any) =>this.onTAB("txtOrderDate-1", 0, e)}
                                     autoComplete="off"
                                   />
                                 </div>  

                      </Form.Group>
                    </div>


                    <div className="col-12 col-md-3" >
                      <Form.Group controlId="customercode">
                        <Form.Label className="font-semibold">  Invoice  No</Form.Label>
                        <div className="single-select">
                        <Form.Control type="text" id="txtCustomer"

value={this.state.formData.InvoiceNo}

onChange={(event: any) =>
  this.handleChange(event, "InvoiceNo")} 
autoComplete='off'
disabled={false}
/>
                        </div>

                      </Form.Group>
                    </div>

                    <div className="col-12 col-md-3" >
                      <Form.Group controlId="customercode">
                        <Form.Label className="font-semibold"> Invoice  Date</Form.Label>
                        <div className="date-picker calender-picker">
                                   <DatePicker
                                     ref={this.trDate}
                                     id="txtInvoiceDate"
                                     tabIndex={this.state.tabEnable}
                                     className="form-control px-0 w-75 text-center"
                                     placeholderText={"MM/DD/YY"}
                                     dateFormat="MM/dd/yy"
                                     selected={
                                       this.state.formData.InvoiceDate === null
                                         ?  new Date(Utils.convertUtcToTimezoneFormat(new Date(),currentUtcTime))
                                         : this.state.formData.InvoiceDate === ""
                                           ?  new Date(Utils.convertUtcToTimezoneFormat(new Date(),currentUtcTime))
                                           : new Date(this.state.formData.InvoiceDate)
                                     }
                                     onChange={(event: any) =>
                                       this.handleChange(event, "InvoiceDate")
                                     }
                                     //onClick={(e: any) =>this.onTAB("txtOrderDate-1", 0, e)}
                                     autoComplete="off"
                                   />
                                 </div>  

                      </Form.Group>
                    </div>

                    <div className="col-12 col-md-3" >
                     
                        <Form.Label className="font-semibold"> Accounting Period</Form.Label>
                        <div  className="w-auto" >
                        <Form.Label>
                        {this.state.formData.AccountingPeriod != null &&
                                  <SingleDropdownListWithoutSearch
                        tabIndex={this.state.tabEnable}
                        itemList={this.state.Accountingperiod}
                        handleSelectedItem={this.handleSelectedItem1.bind(this,1)}                        
                        defaultItem={this.state.formData.AccountingPeriod}
                      //  defaultText={"Select Employee"}
                        defaultName={this.state.accountingperiodname=="" ?  this.state.formData.AccountingPeriod : this.state.accountingperiodname}
                       
                        controlID="1"
                        id={"ddlAccounting"}
                      />}
                      </Form.Label>
                        </div>

                    
                    </div>


                  

                    <div className="col-12 col-md-3" >
                      <Form.Group controlId="customercode">
                        <Form.Label className="font-semibold"> Total  Amount</Form.Label>
                        <div className="single-select">
                        <Form.Control type="text" id="txtTotalAmount"
      value={Utils.currencyFormat(this.state.formData.TotalAmount)}
      className="form-controlCustom"
      style={{width:'52%'}}
      autoComplete='off'
      disabled={true}
    />
                        </div>

                      </Form.Group>
                    </div>
                  </div>
                </section>






                <section className="pb-3">

                  <div
                    className="table-responsive table table-borderless align-middle transactions-list"

                  >
                   {this.state.templateColumns.length > 0 && (
     
        <BootstrapTable
          id="tbltemplate"
          keyField="templateid"
          data={this.state.invoicedetails}
          columns={this.state.templateColumns}
        />
     
    )}
                  </div>

                
                </section>

                <div className={"footer-section viewer-panel"}
                  style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
                >
 <div>
 <div className="d-flex gap-3 align-items-center justify-content-end height-72">
                      <div className="d-flex gap-3 align-items-center">
                        {
                          (
                            <>
                              <Button
                                variant="primary"
                                type="button"
                                className="space btn btn-primary btn-discard"
                                onClick={this.closeSlideOut}
                              >
                                Discard
                              </Button>
                              <Button
                                id="btnSave"
                                variant="primary"
                                type="button"
                                onClick={() => this.saveItem(false)}
                               
                              >
                                {"Save"}
                              </Button>

                              {/* {this.state.formData.Id > 0 && <Button
                                id="btnPrint"
                                variant="primary"
                                type="button"
                                onClick={() => this.printItem()}
                               
                              >
                                {"Print"}
                              </Button>}
                               */}
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </Container>
          </div>

         

          {(this.state.isSpinner || this.state.isSaveSpinner) && (
            <div className="loader-spinner d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="success" />
            </div>
          )}






        </div>

      </section>
    );
  }
}

import axios from "axios";
import Instense from "./Axios-config";
import { API_ROOT } from "./Api-config";
import {
  ICustomerorder
  } from "../Contracts/ICustomerorder";
export class CustomerService {

  public static GetCustomerOrder = async (): Promise<
  ICustomerorder[] | null
> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let request = {} as any;
  request.tenantID = tenantID;
  
  const url = `/Customer/GetCustomerOrderList`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as ICustomerorder[];
   
    return result;
  });
};

public static GetCustomerShipping = async (): Promise<
ICustomerorder[] | null
> => {
let storage = JSON.parse(localStorage.getItem("storage")!);
let tenantID = storage === null ? 0 : (storage.tenantID as any);
let request = {} as any;
request.tenantID = tenantID;
const url = `/Customer/GetCustomerShippinglist`;
return Instense.get(url, { params: request }).then((response) => {
  let result = response.data.result as ICustomerorder[];
 
  return result;
});
};

public static GetCustomerShipped = async (): Promise<
ICustomerorder[] | null
> => {
let storage = JSON.parse(localStorage.getItem("storage")!);
let tenantID = storage === null ? 0 : (storage.tenantID as any);
let request = {} as any;
request.tenantid = tenantID;
const url = `/Customer/GetCustomerShippedlist`;
return Instense.get(url, { params: request }).then((response) => {
  let result = response.data.result as ICustomerorder[];
 
  return result;
});
};

 
public static GetPreviousCustomerOrder = async (request: any): Promise<any[] | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  // 
  request.tenantid = tenantID;
  const url = `/Customer/GetPreviousCustomerOrder`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static GetPendingInvoiceCustomerOrder = async (request: any): Promise<any[] | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  // 
  request.tenantid = tenantID;
  const url = `/Customer/GetPendingInvoiceCustomerOrder`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static GetAllCustomerOrder = async (request: any): Promise<any[] | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  // 
  request.tenantid = tenantID;
  const url = `/Customer/GeCustomerOrder`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static GeCustomerOrderInv = async (request: any): Promise<any[] | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  // 
  request.tenantid = tenantID;
  const url = `/Customer/GeCustomerOrderInv`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};
public static Getorderdata = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantid = tenantID;
  const url = `/Customer/GetCustomerOrderData`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static Getorderdataoptimize = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantid = tenantID;
  const url = `/Customer/GetCustomerOrderDataNew`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};
public static GetCustomerlistjob = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  
  request.tenantid = tenantID;
  const url = `/Customer/GetCustomerlistjob`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static GetCustomerMaster = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  
  request.tenantid = tenantID;
  const url = `/Customer/GetCustomerMaster`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};



public static GetCustomerById = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  request.tenantid = tenantID;
  const url = `/Customer/GetCustomerById`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static DownloadCustomerPDFFile = async (request: any,Isdownload : any): Promise<any | null> => {

  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let userName = storage === null ? "" : (storage.userName as any);
  request.tenantId = tenantID;
  request.UserName = userName;
  const url = `/Customer/GetCustomerPDF`;
  return Instense.get(url,{params:request,  responseType: "blob", headers: {
    'Accept': '*/*',
  }, } ).then(
    (response: any) => {
      // Check if the response is successful
      if (response.status === 200) {
        if(Isdownload==true)
        {

          let csvURL = window.URL.createObjectURL(response.data);
          let tempLink = document.createElement("a");
          tempLink.href = csvURL;
          tempLink.setAttribute("download", request.name);
          tempLink.click();
        }

        else
        {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        // Open the PDF in a new browser tab
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
        }
      } 
    }
  );
};



public static GetQuotationPDF = async (request: any): Promise<any | null> => {

  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let userName = storage === null ? "" : (storage.userName as any);
  request.tenantId = tenantID;
  request.UserName = userName;
  const url = `/Customer/GetQuotationPDF`;
  return Instense.get(url,{params:request,  responseType: "blob", headers: {
    'Accept': '*/*',
  }, } ).then(
    (response: any) => {
      // Check if the response is successful
      if (response.status === 200) {
        
        const blob = new Blob([response.data], { type: 'application/pdf' });
        // Open the PDF in a new browser tab
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
      } 
    }
  );
};
public static GetCustomerlist = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantid = tenantID;
  const url = `/Customer/GetCustomerlist`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static GetCustomerAddress = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantid = tenantID;
  const url = `/Customer/GetCustomerAddress`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static GetVendorContract = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantid = tenantID;
  const url = `/Customer/GetCustomerAddress`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};
 
public static GetContactDetails = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantid = tenantID;
  const url = `/Customer/GetContactDetails`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static SaveCustomerData = async (request: any): Promise<any> => {
  // 
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let userName = storage === null ? "" : (storage.userName as any);
  request.TenantID = tenantID;
  request.UserName = userName;
  const url = `/Customer/SaveCustomerData `;
  return Instense.post(url, request).then((response) => {
    // 
      let result = response.data.result as any[];
      return result;
  });
};

 
public static Saveorder = async (request: any): Promise<any> => {
  // 
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let userName = storage === null ? "" : (storage.userName as any);
  request.TenantID = tenantID;
  request.UserName = userName;
  const url = `/Customer/SaveOrder `;
  return Instense.post(url, request).then((response) => {
    // 
      let result = response.data.result as any[];
      return result;
  });
};

public static DownloadTranactionFile = async (
  request: any
): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);

  request.tenantId = tenantID;

  const url = `/Customer/DownloadTranactionFile`;
  return Instense.post(url, request, { responseType: "blob" }).then(
    (response: any) => {

      let csvURL = window.URL.createObjectURL(response.data);
      let tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", request.name);
      tempLink.click();
    }
  );
};



public static Savefiles = async (
  request: any, files: any[]
): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : storage.tenantID as any;
  request.tenantID = tenantID;




  const url = `/Customer/SaveFile `;
  const formData: FormData = new FormData();
  files.forEach(element => {
    formData.append('file', element);
  });

  formData.append('formField', JSON.stringify(request));
  return Instense.post(url, formData).then((response) => {
    let result = response.data.result as any;
    return result;
  });



};




public static GetUploadFileList = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
 
  request.tenantID = tenantID;
  const url = `/Customer/GetUploadFileList`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static GetUploadFileWithFileCode = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  // let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
  // debugger

  request.tenantID = tenantID;
  const url = `/Customer/GetUploadFileWithFileCode`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static DeleteUploadedFile = async (request: any): Promise<any[] | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  // let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
  // debugger

  request.tenantID = tenantID;
  const url = `/Customer/DeleteUploadedFile`;
  return Instense.post(url, request).then((response) => {
    let result = response.data.result as any;
    return result;
  });

};


//Quotations services

public static GetQuotationOrder = async (): Promise<
  ICustomerorder[] | null
> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let request = {} as any;
  request.tenantID = tenantID;
  
   const url = `/Customer/GetQuotationOrderList`;
 //const url = `/Customer/GetCustomerOrderList`;

  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as ICustomerorder[];
    return result;
  });
};

public static QuotataionSaveorder = async (request: any): Promise<any> => {
  // 
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let userName = storage === null ? "" : (storage.userName as any);
  request.TenantID = tenantID;
  request.UserName = userName;
  const url = `/Customer/SaveQuotationOrder`;
  return Instense.post(url, request).then((response) => {
    // 
      let result = response.data.result as any[];
      return result;
  });
};


public static SavePriceBeakup = async (request: any): Promise<any> => {
   
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let userName = storage === null ? "" : (storage.userName as any);
  request.tenantid = tenantID;
 
  const url = `/Customer/SavePriceBeakup `;
  return Instense.post(url, request).then((response) => {
    debugger;
      let result = response.data.result as any[];
      return result;
  });
};


public static SavePriceBreakupTenant = async (request: any): Promise<any> => {
   
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let userName = storage === null ? "" : (storage.userName as any);
  request.tenantid = tenantID;
 
  const url = `/Customer/SavePriceBreakupTenant `;
  return Instense.post(url, request).then((response) => {
    debugger;
      let result = response.data.result as any[];
      return result;
  });
};

public static GetPricebreakdown = async (request: any): Promise<any> => {
  // let request = {
  //   tenantId: 101,
  //   orderId: 0,
  //   partId: 0
  // };

  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let userName = storage === null ? "" : (storage.userName as any);
  request.tenantid = tenantID;
  const url = '/Customer/GetPriceBreakup';

  try {
    const response = await Instense.get(url, { params: request });
    let result = response.data.suggestions as any[];
    return result;
  } catch (error) {
    console.error('Error fetching price breakdown:', error);
    throw error;
  }
};



public static GetPricebreakdownTenant = async (): Promise<any> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let userName = storage === null ? "" : (storage.userName as any);
  let request = {
    tenantId: tenantID,
    orderId: 0,
    partId: 0
  };

  const url = '/Customer/GetPriceBreakupTenant';

  try {
    const response = await Instense.get(url, { params: request });
    let result = response.data.suggestions as any[];
    return result;
  } catch (error) {
    console.error('Error fetching price breakdown:', error);
    throw error;
  }
};
// public static SavePriceBeakup = async (request: any): Promise<any> => {
//   // 
//   let storage = JSON.parse(localStorage.getItem("storage")!);
//   let tenantID = storage === null ? 0 : (storage.tenantID as any);
//   let userName = storage === null ? "" : (storage.userName as any);
//   request.TenantID = tenantID;
//   request.UserName = userName;
//   const url = `/Customer/SavePriceBeakup`;
//   return Instense.post(url, request).then((response) => {
//     // 
//       let result = response.data.result as any[];
//       return result;
//   });
// };






public static GetQuotationUploadFileWithFileCode = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  // let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
  // debugger

  request.tenantID = tenantID;
  const url = `/Customer/GetQuotationUploadFileWithFileCode`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static QuotationSavefiles = async (
  request: any, files: any[]
): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : storage.tenantID as any;
  request.tenantID = tenantID;

  const url = `/Customer/QuotationSaveFile `;
  const formData: FormData = new FormData();
  files.forEach(element => {
    formData.append('file', element);
  });

  formData.append('formField', JSON.stringify(request));
  return Instense.post(url, formData).then((response) => {
    let result = response.data.result as any;
    return result;
  });

};

public static DeleteQuotationUploadedFile = async (request: any): Promise<any[] | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  // let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
  // debugger

  request.tenantID = tenantID;
  const url = `/Customer/DeleteQuotationUploadedFile`;
  return Instense.post(url, request).then((response) => {
    let result = response.data.result as any;
    return result;
  });

};

public static DownloadQutationTranactionFile = async (
  request: any
): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);

  request.tenantId = tenantID;

  const url = `/Customer/DownloadQutationTranactionFile`;
  return Instense.post(url, request, { responseType: "blob" }).then(
    (response: any) => {

      let csvURL = window.URL.createObjectURL(response.data);
      let tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", request.name);
      tempLink.click();
    }
  );
};

public static GetQuotataionorderdataoptimize = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantid = tenantID;
  const url = `/Customer/GetQuotationOrderDataNew`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

// public static ConvertedQuotationOrder = async (request: any): Promise<any | null> => {
//   let storage = JSON.parse(localStorage.getItem("storage")!);
//   let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
//   request.tenantid = tenantID;
//   const url = `/Customer/ConvertQuotationOrder`;
//   return Instense.get(url, { params: request }).then((response) => {
//     let result = response.data.result as any;
//     return result;
//   });
// };
public static ConvertedQuotationOrder = async (request: any): Promise<any> => {
  // 
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let userName = storage === null ? "" : (storage.userName as any);
  request.TenantID = tenantID;
  request.UserName = userName;
  const url = `/Customer/ConvertQuotationOrder`;
  return Instense.post(url, request).then((response) => {
    // 
      let result = response.data.result as any[];
      return result;
  });
};

public static CheckQuotationOrder = async (request: any): Promise<any> => {
  // 
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let userName = storage === null ? "" : (storage.userName as any);
  request.TenantID = tenantID;
  request.UserName = userName;
  const url = `/Customer/CheckQuotationOrder`;
  return Instense.post(url, request).then((response) => {
    // 
      let result = response.data.result as any[];
      return result;
  });
};


 
public static DeleteOrder = async (
  request:  any
): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : storage.tenantID as any;
  request.tenantId = tenantID;
  const url = `/Customer/DeleteOrder`;
  return Instense.delete(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static DeleteQuotation = async (
  request:  any
): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : storage.tenantID as any;
  request.tenantId = tenantID;
  const url = `/Customer/DeleteQuotation`;
  return Instense.delete(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};
}



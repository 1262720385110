import * as React from "react";
import { Container, Dropdown, Accordion, Form, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { VendorService } from "../../Common/Services/VendorService";
import { VendorMasterSlideout } from "../../Modules/Masters/VendorMasterSlideout";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { SingleDropdownListWithoutSearch } from "../../Common/Components/SingleDropdownListWithoutSearch";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
const { SearchBar } = Search;
import _ from "lodash";
 
import createDOMPurify from "dompurify";
 
import moment from "moment";

  
export class VendorMaster extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
         
          htmlItem: [],
          htmlErrorItem: [],
          htmlWarningItem: [],
          searcResult: [],
          orderid:0,
          selectedcolumn: "All",
          selectOptions: [
            { id: 'All', name: 'All'},
            { id: 'Vendor Code', name: 'Vendor Code' },
            { id: 'Vendor Name', name: 'Vendor Name' },
            { id: 'Address', name: 'Address' },
            { id: 'Phone Number', name: 'Phone Number' },
            { id: 'Status', name: 'Status' },
          ],
        };
        
     
      }
      componentDidMount() {
        let requestlist = { ...this.state.requestlist };
        this.getVendorMaster(requestlist);
      }

      
      handleSelectedItem1 = (control: any, id: any) => {
        let searcResult1 = Object.assign(
          [],
          JSON.parse(JSON.stringify(this.state.searcResult))
        );
        let chkisstatus = id == "Status" ? true : false;
        let chkisPhoneNumber = id == "Phone Number" ? true : false;
        let chkisaddress = id == "Address" ? true : false;
        let chkiscustomername = id == "Vendor Name" ? true : false;
        let chkiscustomercode = id == "Vendor Code" ? true : false;
    
    
        if (id == "All") {
          chkisstatus = true;
          chkisPhoneNumber = true;
          chkisaddress = true;
          chkiscustomername = true;
          chkiscustomercode = true;
    
        }
    
    
        this.setState({
          searcResult: [], selectedcolumn: id,  isstatus: chkisstatus ,isphone_number: chkisPhoneNumber, isaddress: chkisaddress,
          iscompanyname: chkiscustomername, isvendorcode: chkiscustomercode
        },
          () => {
            this.setState({ searcResult: searcResult1 });    
          }
    
    
        );
    
      };
 
      getVendorMaster = (requestlist: any) => {
 

        VendorService.GetVendorlist(requestlist)
    .then(async (result: any[] | null) => {
        
      if (result != null) {
        result.forEach((element) => {
          debugger;
          let defaultname: any = element.vendorContact.filter(
            (x) => x.isDefault == true
          );
          if(defaultname.length>0)
          {
          element.contactPerson=defaultname[0].firstname;
         element.phone_number=defaultname[0].phoneno;
          }
        });
        this.setState({ searcResult: result }, () => {
           
        });
      }
      resolve();
    })
    .catch((err: any) => {
      toast.error(`Server Error, ${err}`);
      reject();
    });
      };

      rowEvents = {
        onClick: (e: any, row: any, rowIndex: any) => {
          this.setState({ showSlideout: true ,vendorid:row.vendor_id,isSpinner:false});  
        },
      };
      
      showNewSlideOut = () => {
        this.setState({ showSlideout: true ,vendorid:0,isSpinner:false});  
      };
    
      closeSlideOut = () => {
        this.setState({ showSlideout: false, isSpinner:false, });
        let requestlist = { ...this.state.requestlist };
        this.getVendorMaster(requestlist);
    };
    


  render() {
    const customTotal = (from, to, size) => (
      <span className="text-muted">
        Showing {from} to {to} of {size} entries
      </span>
    );
    const options = {

      showTotal: true,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '10', value: 10
      },
      {
        text: '25', value: 25
      },
      {
        text: '50', value: 50
      },

      {
        text: 'All', value: this.state.searcResult.length
      }] // A numeric array is also available. the purpose of above example is custom the text
    };
  const {
    showSlideout,
} = this.state;

const columns = [
  {
    searchable: this.state.isvendorcode,
    dataField: 'vendorcode',
    text: 'Vendor Code',
    sort: true,
  },

  
  {
    searchable: this.state.iscompanyname,
    dataField: 'company_name',
    text: 'Vendor Name',
    sort: true,
  },
  {

    searchable: this.state.isaddress,
    dataField: 'address',
    text: 'Vendor Address',
  },
  
  
  {
    searchable: this.state.isphone_number,
    dataField: 'contactPerson',
    text: 'Contact Person',
  },

  
  {
    searchable: this.state.isphone_number,
    dataField: 'phone_number',
    text: 'Contact Phone',
  },

  {
    dataField: 'status',
    text: 'Status',
    sort: true,
    headerStyle: { width: '200px' },
    headerClasses: 'text-center',
    classes: 'text-center',
    
    searchable: this.state.isstatus,
    //searchable:this.state.isdueDate,
    formatter: (cell: any, row: any, rowIndex: any) => {
      return (
        
        <>
       
        <EllipsisWithTooltip placement="bottom">
          
            

              <span className={ row.status==="Active" ? "badge badge-pill badge-primary color-green" : "badge badge-pill badge-primary color-red"}> {row.status} </span>


          </EllipsisWithTooltip>
    
    
          </>

      );
  },
  },
];
    return (
      <>
        <React.Fragment>



          <Container fluid className="body-sec">
            <div className="page-heading underline d-flex  ">

            Vendor Master
              <div className="action-group d-flex flex-row ml-auto">
              <Button onClick={this.showNewSlideOut} >Add Vendor</Button>

              </div>
            </div>

            <section className="mt-3">
            <ToolkitProvider
        keyField="id"
        data={this.state.searcResult}
        columns={columns}

         //className="table table-striped table-borderless align-middle"
        search
      >
        {
          props => (


            <div >
              <section className="d-flex flex-wrap gap-3 pb-2">
                <div className="position-relative">
                  <SearchBar {...props.searchProps} />
                </div>

                <div>
                  <Form.Label>
                    <SingleDropdownListWithoutSearch
                      
                      itemList={this.state.selectOptions}
                      handleSelectedItem={this.handleSelectedItem1.bind(this, 1)}
                      defaultItem={this.state.selectedcolumn}
                      defaultText={"Select Filter"}
                      defaultName={this.state.selectedcolumn}
                      controlID="1"
                      id={"ddlselected"}

                    /></Form.Label>
                </div>

              </section>
              <section className="pb-3">
                <div className="table-responsive divbottom"  >
                  <div className="ml-auto transactions-list">
                  <BootstrapTable
                    {...props.baseProps}
                    rowEvents={this.rowEvents}
                    keyField="rowId"
                    pagination={paginationFactory(options)}

                  />
                  </div>
                </div>
            

              </section>
            </div>

          )
        }
 </ToolkitProvider>
</section>

{(showSlideout &&

  <VendorMasterSlideout
  closeSlideOut={this.closeSlideOut}
  vendorid={this.state.vendorid}
  id={this.state.id}
  >
  
  
  </VendorMasterSlideout>)}





          </Container>



        </React.Fragment>
      </>
    );
  }
}
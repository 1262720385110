import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
    
let storage = JSON.parse(localStorage.getItem("storage")!);

    let role = storage === null ? 0 : (storage.rolId as any);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);



    let userName = storage === null ? 0 : (storage.userName as any);

    const TawkMessenger = () => {
      return (
        <div>
          {/* Other content */}
          <TawkMessengerReact
            propertyId="66eacaea4cbc4814f7da1cf9"
            widgetId="1i82ih9n7"
           username={userName}
         //  Email=""
          />
        </div>
      );

};

export default TawkMessenger;
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  Container,
  Dropdown,
  Form,
  Button,
  Modal,
  ButtonGroup,
  ToggleButtonGroup,
  ToggleButton,
  Spinner,
  Accordion,
  Card,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import DatePicker from "react-datepicker";
import { Utils } from "../../Common/Utilis";
import { CustomerService } from "../../Common/Services/CustomerService";
import { resolve, reject } from "q";
import chevronRight from "../Common/Assets/Images/icons/bx-chevron-right.svg";
import { SingleDropdownListWithoutSearch } from "../../Common/Components/SingleDropdownListWithoutSearch";
import { SingleSearchDropdownListBootrap } from "../../Common/Components/SingleSearchDropdownListBootrap";
import { User } from "../../Common/Services/User";
import "../../App.scss";
import ".././slideout.scss";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { WorkstationService } from "../../Common/Services/WorkstationService";
import { FiChevronDown } from "react-icons/fi";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {MdBuild} from 'react-icons/md';
import { debug } from "console";
import Dropzone from "react-dropzone";
import {
  faPlus,
  faMinus,
  faPaperclip,
  faFile,
  faFileImage,
  faFileExcel,
  faFilePdf,
  faFileCsv,
  faFileWord,
} from "@fortawesome/free-solid-svg-icons";
import ScrollContainer from "react-indiana-drag-scroll";
import { CommentBox } from "../../Common/Components/CommentBox";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { JobOrderService } from "../../Common/Services/JobOrderService";

let routeLeavingGuard: any = null;
let docViewerFiles: any = [];
let storage = JSON.parse(localStorage.getItem("storage")!);
let currentUtcTime = storage === null ? "America/Chicago" : (storage.currentUtcTime as any);
const formattedDate  =   Utils.convertUtcToTimezoneFormat(new Date(),currentUtcTime);
export class ProcessJob extends React.Component<any, any> {
  private scrollvdRef: any;
  private customerSearch: any;
  private trDate: any;
  private trdueDate: any;
  private checkAllRef: any = {};
  private scrollArea: any = {};
  private parentScroll: any;
  private scrollheight: any;
  private footerchk: any;
  private ChildComment: any;
 
  GetTemplateData = (id: any = []) => {

    let saveRequest: any = {};
    saveRequest.id = id;

    saveRequest.tenantid = 0;

    let jobdetailsdata: any = [];

  };

  private templateColumns = [

    {
      dataField: 'step',
      text: 'Step No.',

    },
    // {
    //   dataField: 'type',
    //   text: 'Type',
    //   headerStyle: { width: '150px' },
    //   hidden: false,
    //   editable: false,
    //   headerAttrs: (column, colIndex) => (
    //     { 'tabIndex': '-1' }
    //   ),
    //   formatter: (cell: any, row: any, rowIndex: any) => {
    //     let id = "txttype" + rowIndex + "txt";
    //     let disable = false;
    //     return (
    //       <div>            
    //         <Form.Control tabIndex={this.state.tabEnable}
    //           id={id}
    //           type="text" placeholder="Enter Type" value={row.type}
    //           onChange={(e) => this.updateState(e, rowIndex, "type", id)}
    //           autoComplete='off'
    //           disabled={disable}
    //           title=""
    //           maxLength={500}
    //         />
    //       </div>

    //     );



    //   },
    // },
    {
      dataField: 'process',
      text: 'Process',
      headerStyle: { width: '200px' },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtprocess" + rowIndex + "txt";
        let disable = false;
        return (
          <div>
            { row.process/* <Form.Control tabIndex={this.state.tabEnable}
                        id={id}
                        type="text" placeholder="Enter Process" value={row.process}
                       // onChange={(e) => this.updateState(e, rowIndex, "partNo", id)}
                        autoComplete='off'
                        disabled={disable}
                        title=""
                        maxLength={500}
                    /> */}


            
          </div>
        );



      },

    },
    {
      dataField: 'jDescription',
      text: 'Description',
      headerStyle: { width: '500px' },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtdescription" + rowIndex + "txt";
        let disable = false;
        return (
          <div>
           
            <Form.Control tabIndex={this.state.tabEnable}
              id={id}
              type="text" placeholder="Enter Description" value={row.jDescription}
              onChange={(e) => this.updateState(e, rowIndex, "description", id)}
              autoComplete='off'
              disabled={disable}
              title=""
              maxLength={500}
            /> 
          </div>

        );



      },

    },
    {
      dataField: 'assignedId',
      text: 'Process By',
      headerStyle: { width: '400px' },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtprocessby" + rowIndex + "txt";
        let disable = row.status =="completed" ?true : false;
        
        return (
          <div>
          
           <SingleSearchDropdownListBootrap
              tabIndex={this.state.tabEnable}
              itemList={this.state.TypeUserlist}
              handleSelectedItem={this.handleSelectedItem.bind(row, 2 + '_' + rowIndex)}
              defaultItem={row.assignedId > 0 ? 'u:'+ row.assignedId :(row.workstationId > 0 ? 'w:'+ row.workstationId:"Unassigned")}
              defaultText="Select Process By"
              //defaultName={row.processby}
              controlID="1"
              defaultName={row.assignedName}
              id={"ddlprocess-" + rowIndex + "ddl"}
              //onTAB={this.onTAB.bind(this, "ddlprocess-" + rowIndex + "ddl", rowIndex)}
              isdisable={disable} 
            />
            
          </div>
        

        );



      },

    },
    {
      dataField: '',
      text: 'Start Time',
      headerStyle: { width: '100px' },
      hidden: false,
      editable: false,
      headerAttrs: (column, costartTimelIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
       
        let disable = false;
        return (
          <div>
            {row.startTime != null ?moment(row.startTime).format("MM/DD/YYYY"):''}          
          </div>
        );



      },

    },
    {
      dataField: 'endTime',
      text: 'End Time',
      headerStyle: { width: '70px' },
      hidden: false,
      editable: false,
      headerAttrs: (column, costartTimelIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
       
        let disable = false;
        return (
          <div>
            {row.endTime != null ?moment(row.endTime).format("MM/DD/YYYY"):''}          
          </div>
        );



      },

    },
    {
      dataField: 'status',
      text: 'Status',
      headerStyle: { width: '100px' },
      hidden: false,
      editable: false,
      headerAttrs: (column, costartTimelIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
       
        let disable = false;
        return (
          <div>
            {row.status}          
          </div>
        );



      },

    },
   
  ];

  constructor(props: any) {
    super(props);
    this.parentScroll = React.createRef();
    this.scrollvdRef = React.createRef();
    this.customerSearch = React.createRef();
    this.trDate = React.createRef();
    this.trdueDate = React.createRef();
    this.scrollheight = React.createRef();
   
    User.getAllUsers()
    .then(async (result: any | null) => {

      if (result !== null) {
          this.state.TypeUserlist.push({id:'u:0',name:'Select'});
        result.forEach(element => {
          let item: any = {};
          item.id = 'u:'+ element.user_UniqueID;
          item.name =  element.userName;
          item.status='Active';
          item.isStatusbadge=false;
          item.orderno=0;
          item.isWorkshop=0;
          this.state.TypeUserlist.push(item);
        });
      }
      resolve();
      // console.log(this.state.processList);
    })
    .catch((error) => {
      this.setState({ isSpinner: false });
      reject();
    });
     
    

    this.ChildComment = React.createRef();
    this.state = {
      tname: "",
      requestlistprev: { recordCount: 5, customerid: 0, tenantid: 0 },     
      prevOrderidselected: 0,
      TypeUserlist:[],
      templateColumns: this.templateColumns, 
      jobdetailsdata: [
        {
          assignedtoList: [], assignedto: "", assignedid: 0,
          statusList: [{ id: 0, employee: "Select" }],
          processList: [],
          processid: 0, srno: 1, type: 'Work', estimatehour: '',
          id: 0,
          jobid: 0,

          process: "",

          jdescription: "",

          tenantid: 0

        }
      ],
      FormData:[],
      CustomerPoNumber: "",
      itemNo:0,
            partname:"",
            partNo:"",
            qtyOrdered:0, 
            poNumber:0,
            dueDate:""
            ,jobDesc:"",
            id:0,
      productid: 0,
      Isadded: true,
      rowData: 0,
      tabEnable: 0,
      orderid: this.props.orderid,
      address: this.props.address,
      orderlistitem: [],
      totalCount: 0,
      currentZoom: 0.7,
      chkIds: [],
      formData: {
        OrderID: 0,
        CustomerID: 0,
        CustomerName: "",
        CustomerPoNumber: "",
        shippingInstruction: "",
        OrderDate: new Date( Utils.convertUtcToTimezoneFormat(new Date(),currentUtcTime)),
        Address: "",
        customercode: "",
        IsEdit: "",
        OrderItems: [],
      },
      fileSize: 5242880,
    };

    
    let requestlist = { ...this.state.requestlist };
    WorkstationService.GetWorkstations(requestlist)
    .then(async (result: any[] | null) => {
        
      if (result != null) {

       // let isDevider: any = { bName: "", id: 0, name: "Devider", status: "isDevider" };
        this.state.TypeUserlist.push({id:0,name:'Devider', status: "isDevider"});
        result.forEach(element => {
          let item: any = {};
          item.id = 'w:'+ element.id;
          item.name = element.workstationName;
          item.status='Active';
          item.isStatusbadge=true;
          item.orderno=1;
          item.isWorkshop=1;
          this.state.TypeUserlist.push(item);
        });
      }
      resolve();
    })
    .catch((err: any) => {
      toast.error(`Server Error, ${err}`);
      reject();
    });
    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.updatedCommentItem = this.updatedCommentItem.bind(this);

   
  }

  scrollToBottom = () => {
    this.parentScroll.current.scrollTo({
      //top: this.parentScroll.current.clientHeight,
      top: this.parentScroll.current.scrollHeight,
      behavior: "smooth",
    });
  };

  updatedCommentItem = () => {
    let updatedItem: any = {};
    this.setState({ isSave: true });
    updatedItem.transcationDate = this.state.formData.transcationDate;
    updatedItem.vendorName = this.state.formData.vendorName;
    updatedItem.amountTotal = this.state.totalAmount;
    return updatedItem;
};
CommentModalClose = () => {

  let uniqueno = this.state.formData.uniqueno;
  this.handleDiscard(uniqueno);
};

  closeSlideOut = () => {
    this.props.closeSlideOut(this.state?.shouldReloadMainPage);
  };

  routeLeavingGuardConfirm = (childState: any) => {
    if (childState === true) {
      routeLeavingGuard = "routeLeavingGuardTrue";
    } else {
      routeLeavingGuard = null;
    }
  };

  componentDidMount() { 
    this.getJobDetailItems();
  }

  getJobDetailItems()
  {     
    let requestlist = { ...this.state.requestlist };
    requestlist.jobId = this.props.jobid;
    JobOrderService.GetJobDetails(requestlist)
    .then(async (result: any[] | null) => {
      if (result != null) {  
       
        /*result.forEach(element => {
          if(element.assignedId === 0 && element.workstationId === 0)
          {
            element.assignedName = 'User: ' + this.props.assignedto;
            element.assignedId = this.props.jobassignedid;         
          }
        });*/
        // console.log(result); 
        this.setState({ jobdetailsdata: result }, () => {
           
        });
      }
      resolve();
    })
    .catch((err: any) => {
      
      toast.error(`Server Error, ${err}`);
      reject();
    });
  }

  

  

  updateState = (e: any, index: any, type: any, txtID) => {
    if (this != undefined) {
      let isStateChanged = true;
      let start = e.target?.selectionStart;
      let end = e.target?.selectionEnd;
      let orderData = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.orderData))
      );

  
      if (type === "partNo") {
        e.preventDefault();

        orderData[index].partNo = e.target.value;

      }
      
      if (type === "jobDesc") {
        e.preventDefault();

        orderData[index].jobDesc = e.target.value;

        this.setState({ orderData, isStateChanged }, () => {
          $("#tblOrder")
            .find("input[id^='" + txtID + "']")
            .focus();
          let txtdesc = $("#tblOrder").find(
            "input[id^='" + txtID + "']"
          )[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }


    
    }
  };

  
  handleChange = (event: any, FieldType: string) => {
    
  };

  handleDiscard(postID) {
    this.setState({ isStateChanged: false }, () => {
      this.closeSlideOut();
    });
  }

  onTAB = (control, rowIndex1, event) => {
    let controlID = control.split("-")[0];
    let rowIndex = Number(rowIndex1);

    if (event.key.toLowerCase() === "enter") {
      if (controlID === "txtOrderDate") {
        let txtID = "txtNotes";
        setTimeout(() => {
          $("input[id^='" + txtID + "']").focus();
        }, 50);
      }
    }

    if (event.key.toLowerCase() === "tab") {

      if (controlID === "txtduedate") {
        let txtID = "txtdescription" + rowIndex + "txt";

        $("#tblOrder")
          .find("input[id^='" + txtID + "']")
          .focus();
      }     
    }

    if (event.key.toLowerCase() === "backspace") {
      let controlID = control.split("-")[0];
      let rowIndex = Number(rowIndex1);
      let orderData = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.orderData))
      );
      let defaultPartname: any = [];
      let productList = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.productList))
      );

      
    }
  };

  HidecloseSlideOut() {
    this.props.closeSlideOut();
    this.setState({ isSaveSpinner: false });
  }


  Hidespinner() {
    this.setState({ isSpinner: false });

  }

  saveJobOrderfinal = (entryType, isCopy) => {

   
    // this.setState({ isSaveSpinner: true });

    let isFormValid = true;
    let request: any = {};


    let jobData = this.state.jobdetailsdata;

    jobData.forEach(element => {
      let item: any = {};
       
      item.id = element.id;
      item.jobid = element.jobId;
      item.assignedid = element.assignedId;
      item.workstationid = element.workstationId;
      item.tenantid = element.tenantId;
      this.state.FormData.push(item);
    });


    isFormValid = true;
    

    if (isFormValid) {

      JobOrderService.SaveJobData(this.state.FormData)
        .then(async (result: any | null) => {
          if (result !== null) {

            // this.setState({ isSaveSpinner: false });
                toast.success(this.props.jobno + " has been updated.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "OrderSlideout",
              });
            
            
            this.HidecloseSlideOut();

          }
          resolve();
        })
        .catch((error) => {
          this.setState({ isdefaultColDisabled: false, isSpinner: false });
          reject();
        });
    }
  };


  saveJobOrder = (entryType, isCopy) => {

      this.setState({ ispopupshow: false, isSpinner: true });
      this.saveJobOrderfinal("order", false);

  };

  isHandleAction() {
    // this.setState({ ispopupshow: false }, () => this.changeTransactionType(this.state.saveentryType, this.state.saverequest, false))
    //     request = Object.assign({}, JSON.parse(JSON.stringify(this.state.formData)));
    //       this.state.formData.OrderItems=this.state.orderData;
    //    ispopupshow: false;
    //
    //    CustomerService.Saveorder(this.state.formData)
    //    .then(async (result: any | null) => {
    //        if (result !== null) {
    //            this.setState({ isdefaultColDisabled: false });
    //            if (result.saveStatus === "Success") {
    //                toast.success("saved for " + this.state.formData.vendorName + " successfully.", {
    //                    position: toast.POSITION.BOTTOM_RIGHT,
    //                    containerId: "OrderSlideout",
    //                });
    //            }
    //            else {
    //                toast.error(result.message, {
    //                    position: toast.POSITION.BOTTOM_RIGHT,
    //                    containerId: "OrderSlideout",
    //                });
    //            }
    //        }
    //        resolve();
    //    })
    //    .catch((error) => {
    //        this.setState({ isdefaultColDisabled: false });
    //        reject();
    //    });
  }



 

  hoverOutEllipsis = () => {
    $(".tooltip").removeClass("show");
  };


  handleSelectedItem = (control: any, id: any) => {
 
    let controlID = Number(control.split('_')[0]);
    if (controlID === 2) {
      let type = id.split(':')[0];
      let typeId = Number(id.split(':')[1]);
      let rowIndex = Number(control.split('_')[1]);
      let jobdetailsdata = Object.assign([], JSON.parse(JSON.stringify(this.state.jobdetailsdata)));
      let filterRow = this.state.TypeUserlist.filter(x => x.id === id);

      //
      if(type === 'u')
      {
      this.state.jobdetailsdata[rowIndex].assignedId = typeId; //filterRow[0].id;
      this.state.jobdetailsdata[rowIndex].workstationId = 0; 

      }
      else
      {
      this.state.jobdetailsdata[rowIndex].workstationId = typeId; //filterRow[0].id;
      this.state.jobdetailsdata[rowIndex].assignedId = 0;
      }
      this.state.jobdetailsdata[rowIndex].assignedName = filterRow[0].name;

     /* this.setState({ jobdetailsdata: [] }, () => {
        this.setState({ jobdetailsdata }, () => {
          let txtID = 'ddlprocess-' + (rowIndex) + "ddl";
          $("#tbltemplate").find("button[id^='" + txtID + "']").focus();
        });
      });
      */
    }
    else if (controlID === 3) {

      //let rowIndex = Number(control.split('_')[1]);
     // let jobdetailsdata = Object.assign([], JSON.parse(JSON.stringify(this.state.jobdetailsdata)));
      //let filterRow = this.state.assigneddatatoList.filter(x => x.id === id);


      //jobdetailsdata[rowIndex].assignedto = filterRow[0].name;
    //  jobdetailsdata[rowIndex].assignedid = filterRow[0].id;

    //  this.setState({ jobdetailsdata: [] }, () => {
        //this.setState({ jobdetailsdata }, () => {
         // let txtID = 'ddlassign-' + (rowIndex) + "ddl";
        //  $("#tbltemplate").find("button[id^='" + txtID + "']").focus();
      //  });
    //  });


    }
    else if (controlID === 5) {

      let rowIndex = Number(control.split('_')[1]);
      let itemdetailsdata = Object.assign([], JSON.parse(JSON.stringify(this.state.itemdetailsdata)));
      let filterRow = this.state.itemListdatam.filter(x => x.id === id);


      itemdetailsdata[rowIndex].itemname = filterRow[0].name;
      itemdetailsdata[rowIndex].description = filterRow[0].desc;
      itemdetailsdata[rowIndex].qty = filterRow[0].qty;
      itemdetailsdata[rowIndex].sizedesc=filterRow[0].sizedesc;

      
      //itemdetailsdata[rowIndex].assignedid = filterRow[0].id;

      this.setState({ itemdetailsdata: [] }, () => {
        this.setState({ itemdetailsdata: itemdetailsdata });

      });
    };
  };

  handleSelectedItem1 = (control: any, id: any) => {
    
    if (this != undefined) {
     
      let isStateChanged = true;

      let formData = { ...this.state.formData };
      let controlID = Number(control.split("_")[0]);
      let rowIndex = Number(control.split("_")[1]);

      let orderData = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.jobdetailsdata))
      );
      let defaultPartname: any = [];
      let productList = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.TypeUserlist))
      );
      if (controlID === 1) {
        
        //  let id = "txtpartno" + rowIndex + "txt";
      }
    }
  };

  handleOutsideClick = (rowIndex: any, e: any) => {
      
    let isStateChanged: any;
    isStateChanged = true;

    let btnId = "processby" + rowIndex + "btn";
    let id = "processby" + rowIndex + "ddl";
  

    $("#tbltemplate")
      .find("button[id^='" + btnId + "']")
      .css("display", "block");
    $("#tbltemplate")
      .find("div[id^='" + id + "']")
      .css("display", "none");
  };

 

  handleRowSelectionCheck = (e, rowData, Orderid, isAll) => {
    let orderData: any = [];
    
    orderData = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.orderData))
    );

    let previousOrderData = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.previousOrderData))
    );

    if (this != undefined) {
      let orderArray: any = [];

      orderArray = previousOrderData.filter(
        (x) => x.orderID === Orderid && x.mainType == 2
      );

      let orderlist: any = [];
      if (orderArray.length > 0) {
        orderArray.forEach((element: any, index: any) => {
          if (rowData === index) {
            let orderDatacheck = this.state.orderData.filter(
              (x) =>
                x.productid === element.productid ||
                x.partname === element.partname
            );
            if (orderDatacheck.length > 0) {
              this.setState({
                isDuplicate: true,
                Isadded: false,
                Orderid: Orderid,
                rowData: rowData,
              });
            } else {
              if (isAll === "all") {
                this.handleRowSelectionn(e, 0, Orderid);
              } else {
                this.handleRowSelection(e, rowData, Orderid);
              }
            }
          }
        });
      }
    }
  };

  handleRowSelection = (e, rowData, Orderid) => {


    let orderData: any = [];
    let defaultPartname: any = [];
    let isStateChanged = true;
    orderData = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.orderData))
    );

    let previousOrderData = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.previousOrderData))
    );

    if (this != undefined) {
      let orderArray: any = [];

      orderArray = previousOrderData.filter(
        (x) => x.orderID === Orderid && x.mainType == 2
      );

      let orderlist: any = [];
      if (orderArray.length > 0) {
        orderArray.forEach((element: any, index: any) => {
          if (rowData === index) {
            let defaultPartname: any = [];
            let objPartname: any = {};
            objPartname.partname = element.partname;
            objPartname.label = element.partname;
            objPartname.id = element.productid;

            objPartname.partno = element.partNo;

            objPartname.tenantid = 0;
            objPartname.unit = element.unit;
            objPartname.unitPrice = element.unitPrice;
            objPartname.noofday = null;
            objPartname.jobDesc = element.jobDesc;

            defaultPartname.push(objPartname);
            element.defaultPartname = defaultPartname;

            element.itemNo = index + 1;
            element.dueDate = new Date( Utils.convertUtcToTimezoneFormat(new Date(),currentUtcTime));
            element.qtyOrdered = 0;
            // element.unitPrice = element.unitPrice === '' ? '' : !isNaN(element.unitPrice) ? Utils.currencyFormatWithOutdoller(element.unitPrice) : element.unitPrice;

            orderData.push(element);
            let orderlistItem: any = [];
            //   dueDate:'',jobDesc:'',qtyOrdered:'',unit:'',unitPrice:'',discount:'',total:''
            orderlistItem.itemNo = index + 1;
            orderlistItem.partname = element.partname;
            orderlistItem.partno = element.partNo;
            orderlistItem.dueDate = new Date( Utils.convertUtcToTimezoneFormat(new Date(),currentUtcTime));
            //element.dueDate;
            orderlistItem.jobDesc = element.jobDesc;
            orderlistItem.qtyOrdered = 0;
            //element.qtyOrdered;
            orderlistItem.unit = element.unit;
            orderlistItem.unitPrice = element.unitPrice;
            orderlistItem.discount = element.discount;
            orderlistItem.total = element.total;
            orderlistItem.productid = element.productid;
            orderlist.push(orderlistItem);
          }
        });
      } else {
        orderData = Object.assign(
          [],
          JSON.parse(JSON.stringify(this.state.orderData))
        );
      }

      this.setState({ orderData: orderData, defaultPartname, isStateChanged });

      this.setState({ orderData: [] }, () => {
        const orderDataNewc = orderData
          .filter((item) => item.partname !== "")
          .map((item, index) => {
            item.itemNo = index + 1;

            return item;
          });

        this.setState(
          {
            orderData: orderDataNewc,
          },
          () => { }
        );
      });
    }
  };

  handleRowSelectionn = (e, rowData, Orderid) => {
    
    let orderData: any = [];
    let defaultPartname: any = [];
    let isStateChanged = true;
    orderData = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.orderData))
    );

    let previousOrderData = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.previousOrderData))
    );

    if (this != undefined) {
      let orderArray: any = [];

      orderArray = previousOrderData.filter(
        (x) => x.orderID === Orderid && x.mainType == 2
      );

      let orderlist: any = [];
      if (orderArray.length > 0) {
        orderArray.forEach((element: any, index: any) => {
          // element.unitPrice = element.unitPrice === '' ? '' : !isNaN(element.unitPrice) ? Utils.currencyFormatWithOutdoller(element.unitPrice) : element.unitPrice;

          orderData.push(element);
          let orderlistItem: any = [];
          orderlist.push(orderlistItem);
        });
      } else {
        orderData = Object.assign(
          [],
          JSON.parse(JSON.stringify(this.state.orderData))
        );
      }

      this.setState({ orderData: orderData, defaultPartname, isStateChanged });

      this.setState({ orderData: [] }, () => {
        const orderDataNewc = orderData
          .filter((item) => item.partname !== "")
          .map((item, index) => {
            item.itemNo = index + 1;

            return item;
          });

        this.setState(
          {
            orderData: orderDataNewc,
          },
          () => { }
        );
      });
    }
  };
  
  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      //this.setState({ showSlideout: true ,orderid:row.orderID
      // });
      this.handleRowSelectionCheck(e, rowIndex, row.orderID, "no");
    },
  };
 
  setTotalPages = (totalpage) => {
    let isextractShow =
      this.state.savedfiles.length === 1 && totalpage === 1 ? true : false;
    this.setState({ isextractShow, isExtractOption: false });
  };
  

  isDeleteattachment(file) {
    let newfiles: any = [];
    this.state.savedfiles.forEach((element, idx) => {
      if (Number(file.fileUniqueno) !== Number(element.fileUniqueno)) {
        newfiles.push(element);
      }
    });
    this.handleFileDelete(newfiles, file);
  }






  isDeleteAttachment(file) { 
    let newfiles: any = [];
    this.state.savedfiles.forEach((element, idx) => {
      if (Number(file.fileUniqueno) !== Number(element.fileUniqueno)) {
        newfiles.push(element);
      }
    });
    // this.handleFileAttachement(newfiles, file);
  }
  handleFileDelete(newfiles, file) {
    let request: any = {};
    request.fileNo = file.fileUniqueno;
    request.orderid = file.uniqueNo;

    this.handleDeleteFileFromDb(request, newfiles, 2);
  }
  handleDeleteFileFromDb(request, newfiles, id) {
    let fileno = request.fileNo;
    CustomerService.DeleteUploadedFile(request)
      .then(async (result: any | null) => {
        if (result !== null) {
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  }
  downloadTranactionFile = (file: any) => {
    CustomerService.DownloadTranactionFile(file)
      .then(async (result: any | null) => {
        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ isSaveSpinner: false });
      });
  };
  handleZoom = (event) => {
    let currZoom = this.state.currentZoom;
    let updateZoom;
    if (event === "zoomIn") {
      updateZoom = currZoom * 1.5;
    } else {
      updateZoom = currZoom / 1.5;
    }
    // console.log("updateZoom-" + updateZoom);
    this.setState({ currentZoom: updateZoom });
  };
  handleAllCheckbox(event) {
    for (let i = 0; i < this.state.savedfiles.length; i++) {
      this["myRef" + i].current.handleAllCheckbox(event);
    }
  }


   

 
   
  render() {
    const whiteBgFormatter = (column, colIndex) => {
      return <div className="white-bg"></div>;
    };

    const renderPreviousTableChild = (mainrow) => {
      let SplitPreviousCOADetail: any = [];
      SplitPreviousCOADetail = this.state.previousOrderData.filter(
        (x) => x.orderID === mainrow.orderID && x.mainType == 2
      );

      // this.setState({ prevOrderidselected:  mainrow.orderID  });

     
    };
   

  

    const handleUpdate = (newtext) => {
      toggleSwitch("doc-view");
      setTimeout(function () {
        var currentPos: any = $(`#${newtext}`).offset()?.top;
        var marginArea = currentPos - 138;
        $(".scroll-area").animate(
          {
            scrollTop: marginArea,
          },
          500
        );
      }, 500);
    };


    const toggleSwitch = (event: any) => {
      $(".scroll-area").animate(
        {
          scrollTop: 0,
        },
        500
      );
    };
   
   
    return (
      //  <div className="transaction-slideout invoice-entry pos-fxd index-1000">

      <section>
      <div className="details-drawer"
        style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
      // className="back-drop1  viewer-panel1 opacity "
      >
      <div className="trasaction-slideout invoice-entry pos-fxd index-1000 ">
        <ToastContainer
          containerId={"userDetailSlideoutcontainer"}
          autoClose={3000}
        />

        <div className="d-flex">
        <div
              className="back-drop1  viewer-panel1 opacity "
              onClick={() => this.closeSlideOut()}
            ></div>
          <Container
            fluid
         //   ref={this.parentScroll}
            className="body-sec viewer-panel back-drop p-0"
            style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
          
          >
            <div
                className="container-fluid py-1 bg-white d-flex align-items-center  border-bottom round-4"
              >
                <div className="divheader">
                  <h5 className="my-title">
 
{this.state.jobid != 0 && (
  <span>Job No :  {this.props.jobno}</span>
)}

</h5>

              <div className="header-popout ml-auto">
              <div className="container text-center">
  <div className="row align-items-start">
    <div className="col">
      </div>

      <div className="col">
      <button
                          type="button"
                          className="btn btn-primary cross"
                          onClick={this.closeSlideOut}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                              fill="#ffffff"
                            />
                          </svg>
                        </button>
      </div>
      </div>
      </div>
              </div>
            </div>
            </div>
            

            <div
                className="scroll"
              >

                <section className="pb-3">
                  <div className="row g-3">

                    <div className="col-12 col-md-12" >
                    <div className="row">


  <div className="col-sm-3">
    <div className="card">
    <div className="card-header">
  Order Details
  </div>
      <div className="card-body">
        <h5 className="card-title"> {this.props.customerpo}</h5>
        <p className="card-text text-xs"> {this.props.customername} <br></br>  {moment(this.props.orderdate).format("MM/DD/YYYY")}
        <br></br><br></br>
        Owner - {this.props.assignedto}
        </p>
 
      </div>
    </div>
  </div>
  <div className="col-sm-3">
    <div className="card">
    <div className="card-header">
    Part Details
  </div>
      <div className="card-body">
        <h5 className="card-title">{this.props.partname}</h5>
        <p className="card-text text-xs">{this.props.partno} <br></br>Order QTY - {this.props.qty} <br></br><br>
        </br>
       Due Date - {moment(this.props.duedate).format("MM/DD/YYYY")}
        </p>
        

      </div>
    </div>
  </div>

                      </div>
                      </div>
                      </div>
                      </section>

            
          
           
           

                      <section className="pb-3">

<div
  className="table table-borderless align-middle transactions-list"

>
                  {this.state.templateColumns.length > 0 && (
                   
                      <BootstrapTable
                        id="tbltemplate"
                        keyField="templateid"
                        data={this.state.jobdetailsdata}
                        columns={this.state.templateColumns}
                      />
                   
                  )}
                </div>
                <div className="filter-sec height-172">
 <br></br>
 <br></br>
 <br></br>
 <br></br>
</div>
                </section>

      
              </div>
            
              {(this.state.orderid != 0 &&
                                        <div className="footer-show" >
                                           
                                            <CommentBox
                                                ref={this.ChildComment}
                                                hotelid={this.state.hotelValue}
                                                entryType={this.props.pageType}
                                                uniqueNo={this.state.formData.actionType === "copy" ? 0 : this.props.pageType === "Invoice" ? this.state.formData.uniqueno : this.state.formData.incompleteUniqueno}
                                                precommentlist={this.state.htmlcomment}
                                                parentScrollMethod={this.scrollToBottom}
                                                updatedCommentItem={this.updatedCommentItem}
                                                commentModalClose={this.CommentModalClose}
                                            
                                            />
                                        </div>
                                        // </div>
                                    )}
             
              <div className={"footer-section viewer-panel"}
               style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
              >
                <div>
                <div className="d-flex gap-3 align-items-center justify-content-end height-72">
                      <div className="d-flex gap-3 align-items-center">
                      {
                      //this.state.isStateChanged &&
                        //this.state.formData.CustomerID > 0 && 
                        (
                          <>
                            <Button
                              variant="primary"
                              type="button"
                              className="space btn btn-primary btn-discard"
                              onClick={() =>
                                this.handleDiscard(this.state.orderUniqueNo)
                              }
                            >
                              Discard
                            </Button>
                            <Button
                              id="btnSave"
                              variant="primary"
                              type="button"
                              onClick={() => this.saveJobOrder("order", false)}
                              disabled={
                                this.state.formData.CustomerID === 0
                                  ? false
                                  : this.state.isDisabled
                              }
                              onKeyDown={(e: any) =>
                                this.onTAB("btnSave-1", 0, e)
                              }
                            >
                              {"Save"}
                            </Button>
                          </>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-sec">
                <div className="d-flex flex-wrap mr-auto"></div>
              </div>

           
 
          </Container>

          {this.state.ispopupshow && (
             <div
             //className="d-flex align-items-center pos-fxd2 index-1000"
             >
               <div className="react-confirm-alert-overlay confirm-modal undefined">
                <div className="react-confirm-alert">
                  <div className="react-confirm-alert-body">
                    <h1>Upload Confirmation</h1>
                    Do you need to attach an order?
                    <div className="react-confirm-alert-button-group">
                      <button
                        type="button"
                        className="btn"
                        onClick={() => this.saveJobOrderfinal("order", false)}
                      >
                        No
                      </button>
                      <div className="upload-link">
                        <button type="button" className="btn">
                          Yes
                        </button>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

        

          {(this.state.isSpinner || this.state.isSaveSpinner) && (
            <div className="loader-spinner d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="success" />
            </div>
          )}
        </div>
      </div>
      </div>
      </section>
    );
  }
}

import * as React from "react";
import {
  Container,
  Dropdown,
  Form,
  Button,
  Modal,
  ButtonGroup,
  ToggleButtonGroup,
  ToggleButton,
  Spinner,
  Accordion,
  Card,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import { PartTemplateService } from "../../Common/Services/PartTemplateService";
import paginationFactory from "react-bootstrap-table2-paginator";

import { CustomerService } from "../../Common/Services/CustomerService";
import { CustomerSearch } from "../../Common/Components/CustomerSearch";
import { SingleSearchDropdownList } from "../../Common/Components/SingleSearchDropdownList";
import _ from "lodash";
import { ConfirmationModal } from "../../Common/Components/ConfirmationModal";
import createDOMPurify from "dompurify";
import { Utils } from "../../Common/Utilis";
import moment from "moment";
import { ProductService } from "../../Common/Services/ProductService";
import DatePicker from "react-datepicker";
export class ProductSlideout extends React.Component<any, any> {
  private parentScroll: any;
  private singleSerch: any;
  private customerSearch: any;

  private templateColumns = [

    {
      dataField: 'srno',
      text: 'No.',

    },
    {
      dataField: 'type',
      text: 'Type',
      headerStyle: { width: '150px' },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txttype" + rowIndex + "txt";
        let disable = false;
        return (
          <div>
            <Form.Control tabIndex={this.state.tabEnable}
              id={id}
              type="text" placeholder="Enter Type" value={row.type}
              onChange={(e) => this.updateState(e, rowIndex, "type", id)}
              autoComplete='off'
              disabled={disable}
              title=""
              maxLength={500}
            />
          </div>

        );



      },
    },
    {
      dataField: 'processName',
      text: 'Process',
      headerStyle: { width: '200px' },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtprocess" + rowIndex + "txt";
        let disable = false;
        return (
          <div>
            {/* <Form.Control tabIndex={this.state.tabEnable}
                    id={id}
                    type="text" placeholder="Enter Process" value={row.process}
                   // onChange={(e) => this.updateState(e, rowIndex, "partNo", id)}
                    autoComplete='off'
                    disabled={disable}
                    title=""
                    maxLength={500}
                /> */}


            <SingleSearchDropdownList
              tabIndex={this.state.tabEnable}
              itemList={row.processList}
              handleSelectedItem={this.handleSelectedItem.bind(row, 1 + '_' + rowIndex)}
              defaultItem={row.processName}
              defaultText={"Search Process"}
              defaultName={row.processName}
              controlID="1"
              id={"ddlprocess-" + rowIndex + "ddl"}
            //onTAB={this.onTAB.bind(this, "ddlprocess-" + rowIndex + "ddl", rowIndex)}
            // disabled={disable}
            />
          </div>
        );



      },

    },
    {
      dataField: 'tDescription',
      text: 'Description',
      headerStyle: { width: '400px' },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtdescription" + rowIndex + "txt";
        let disable = false;
        return (
          <div>
            <Form.Control tabIndex={this.state.tabEnable}
              id={id}
              type="text" placeholder="Enter Description" value={row.tDescription}
              onChange={(e) => this.updateState(e, rowIndex, "description", id)}
              autoComplete='off'
              disabled={disable}
              title=""
              maxLength={500}
            />
          </div>

        );



      },

    },
    {
      dataField: 'estimatehour',
      text: 'Estimate Hours',
      headerStyle: { width: '100px' },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtestimate" + rowIndex + "txt";
        let disable = false;
        return (
          <div>
            <Form.Control tabIndex={this.state.tabEnable}
              id={id}
              type="text" placeholder="Enter Estimate Hours" value={row.estimatehour}
              onChange={(e) => this.updateState(e, rowIndex, "estimate", id)}
              autoComplete='off'
              disabled={disable}
              title=""
              maxLength={500}
            />
          </div>

        );



      },

    },

    {
      dataField: 'Attachment',
      text: '',
      //headerFormatter: this.iconFormatter,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let confirmModalContent = [
          {
            title: "Delete Row",
            desc: "Are you sure you want to delete?",
            cancleAction: "Cancel",
            ActionTrue: "Delete",
          },
        ];
        if (this.state.templateData.length === 1) {
          return (
            <div></div>
          );
        }
         

        else {
          return (
            <Dropdown className="more-action confirmation-modal"  tabIndex={this.state.tabDisable}>
              <Dropdown.Toggle className="btn-outline-primary btn btn-primary more fa-trash" id="dropdown-more-delete" tabIndex={this.state.tabDisable}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 5.83329C4.53917 5.83329 4.16667 5.83329 4.16667 5.83329V16.6666C4.16667 17.5866 4.91333 18.3333 5.83333 18.3333H14.1667C15.0867 18.3333 15.8333 17.5866 15.8333 16.6666V5.83329C15.8333 5.83329 15.4608 5.83329 15 5.83329H5ZM8.33333 15.8333H6.66667V8.33329H8.33333V15.8333ZM13.3333 15.8333H11.6667V8.33329H13.3333V15.8333ZM13.8483 3.33329L12.5 1.66663H7.5L6.15167 3.33329H2.5V4.99996H6.66667H13.3333H17.5V3.33329H13.8483Z" />
                </svg>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <ConfirmationModal
                  confirmModalContent={confirmModalContent}
                  ishandleDeletePosition={this.ishandleDeleteRow.bind(
                    this,
                    row
                  )}
                />
              </Dropdown.Menu>
            </Dropdown>
          );
        }
      },
    }
  ];

  handleSelectedItem = (control: any, id: any) => {
    
    let isStateChanged = true;
    let formData = { ...this.state.formData };
    let controlID = Number(control.split('_')[0]);
    let rowIndex = Number(control.split('_')[1]);
    let templateData = Object.assign([], JSON.parse(JSON.stringify(this.state.templateData)));
    if (controlID === 1) {
      let filterRow = this.state.processdataList.filter(x => x.id === id);

      //templateData[rowIndex].processList = this.state.processdataList.filter(x => x.id === filterRow[0].id);

      templateData[rowIndex].processName = filterRow[0].processName;
      templateData[rowIndex].processid = filterRow[0].id;
      templateData[rowIndex].tDescription = filterRow[0].pDescription;

      this.setState({ templateData: [] }, () => {
        this.setState({ templateData, isStateChanged }, () => {
          let txtID = 'ddlprocess-' + (rowIndex) + "ddl";
          $("#tbltemplate").find("button[id^='" + txtID + "']").focus();
        });
      });

    }

  };
  updateState = (e: any, index: any, type: any, txtID) => {
    if (this != undefined) {
      let isStateChanged = true;
      let start = e.target?.selectionStart;
      let end = e.target?.selectionEnd;
      let templateData = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.templateData))
      );


      if (type === "type") {
        e.preventDefault();

        templateData[index].type = e.target.value;

        this.setState({ templateData, isStateChanged }, () => {
          $("#tbltemplate")
            .find("input[id^='" + txtID + "']")
            .focus();
          let txtdesc = $("#tbltemplate").find(
            "input[id^='" + txtID + "']"
          )[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }
      if (type === "description") {
        e.preventDefault();

        templateData[index].tDescription = e.target.value;

        this.setState({ templateData, isStateChanged }, () => {
          $("#tbltemplate")
            .find("input[id^='" + txtID + "']")
            .focus();
          let txtdesc = $("#tbltemplate").find(
            "input[id^='" + txtID + "']"
          )[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }

      if (type === "estimate") {
        e.preventDefault();
        let newVal = Utils.removeInvaildCharFromAmount(e.target.value);
        e.target.value = newVal;
        ///const rx_live = /^[-]?\d*(?:[.]\d*)?$/;
        templateData[index].estimatehour = e.target.value;

        this.setState({ templateData, isStateChanged }, () => {
          $("#tbltemplate")
            .find("input[id^='" + txtID + "']")
            .focus();
          let txtdesc = $("#tbltemplate").find(
            "input[id^='" + txtID + "']"
          )[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }



    }
  };
  constructor(props: any) {
    super(props);
    this.handleNewRow = this.handleNewRow.bind(this);
    this.GetProcesslist = this.GetProcesslist.bind(this);
     this.GetTemplateData=this.GetTemplateData.bind(this);     
    this.customerSearch = React.createRef();
    this.state = {
      templateid: this.props.templateid,
       
      htmlItem: [],
      htmlErrorItem: [],
      htmlWarningItem: [],
      searcResult: [],
      customerList: [],
      templatename: "",
      processdataList: [],
      templatelistitem: [],
      templateColumns: this.templateColumns,
      formData: {
        templateid: 0,
        templatename: "",
        TemplateDetails: [],
        noofday:0,
        



      },
      templateData: [
        {
          processList: [],
          processName: "", processid: 0, srno: 1, type: 'Work', tDescription: '', estimatehour: '',
        }

      ],

      newRow: [
        {
          processList: [],
          processName: "", processid: 0, srno: 1, type: 'Work', tDescription: '', estimatehour: '',
        }

      ],
    };


  }
  componentDidMount() {
    let requestlist = { ...this.state.requestlist };
   // let requestlist = { ...this.state.requestlist };
    if (this.customerSearch.current !== null) {
      this.customerSearch.current.GetCustomerTranlist(requestlist);
    }
    //this.GetProcesslist(requestlist);
    this.GetCustomerlist(requestlist);
   
  }


  

  

  GetProductById = () => {
    let request: any = {};
    request.productid = this.props.productid;
    ProductService.GetProductById(request)
    .then(async (result: any | null) => {
      if (result !== null) {
        let custommerSelected: any = [];
        let formData = { ...this.state.formData };        

        formData.Id = result.id;
        formData.partname = result.partname;
        formData.firstline=result.partname.split('\n')[0];
        formData.partno = result.partno;
        formData.tenantid = result.tenantid;
        formData.UnitPrice = result.unitPrice;
      
        formData.Noofday = result.noofday;
        formData.pdescription = result.pdescription;
        formData.customerid = result.customerid;
        if(this.state.customerList.length > 0)
        {
          
         let customer = this.state.customerList.filter((x)=>x.customer_id == result.customerid);

        custommerSelected.company_name = customer != null && customer.length> 0 ? customer[0].company_name :'';
        //custommerSelected.address = mainArray.address;
        custommerSelected.customer_id = result.customerid;
        formData.customercode=customer[0].customercode;
        //custommerSelected.customercode = mainArray.customercode;
        this.customerSearch?.current.handleSelectFromParent(
          custommerSelected
        );

        }

        this.setState({
          formData: formData,
        },
          () => {  });
      }
      resolve();
    })
    .catch((error) => {
      this.setState({ isSpinner: false });
      reject();
    });

    
  };

  saveProduct = () => {
    let formData = { ...this.state.formData };

    let isFormValid = true;
    let request: any = {};


    request = Object.assign({}, JSON.parse(JSON.stringify(this.state.formData)));



    if (request.partname === "") {
      toast.error("Please enter part name.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "OrderSlideout",
      });
      // this.setState({ isSaveSpinner: false });
      return false;
    }


    isFormValid = true;


    if (isFormValid) {
      this.setState({ saveRequest: request, ispopupshow: false });

      ProductService.SaveProductMaster(request)
        .then(async (result: any | null) => {
          if (result !== null) {
            
            // this.setState({ isSaveSpinner: false });


            toast.success(result.result, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "OrderSlideout",
            });
          }
          this.closeSlideOut();
          

          resolve();
        })
        .catch((error) => {
          this.setState({ isdefaultColDisabled: false, isSpinner: false });
          reject();
        });
    }
  };
  GetTemplateData = () => {
    
    let saveRequest: any = {};
    saveRequest.id = this.state.templateid;
    saveRequest.tenantid = 0;

    let templateData: any = [];
   // templateData = Object.assign([], JSON.parse(JSON.stringify(this.state.templateData)));
    PartTemplateService.GetTemplatedata(saveRequest)
      .then(async (result: any | null) => {
        if (result !== null) {


          let formData = { ...this.state.formData };
          

          formData.templatename = result[0].templatename;
         
          let orderArray: any = [];
          orderArray = result[0].templateDetails;
          if (orderArray.length > 0) {
            orderArray.forEach((element, idx) => {

               let filterRow = this.state.processdataList.filter(x => x.id === orderArray[idx].processid);

              
               
              element.processList = this.state.processdataList;


               element.processName = filterRow[0].processName;
              element.processid = orderArray[idx].processid;

              templateData.push(element);
            });
          }
          else {
            templateData = Object.assign(
              [],
              JSON.parse(JSON.stringify(this.state.templateData))
            );
          }
          ;

          this.setState({
            tempateData: templateData,
            formData: formData,
          },
            () => {
              this.setState({ tempateData: [] }, () => {
                this.setState({ templateData: templateData });
          
              });       
  console.log(templateData);
            });
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ isSaveSpinner: false });
        reject();
      });
  };

  ishandleDeleteRow(row: any, mode: any) {
    if (mode) {

      let tempateData = Object.assign([], JSON.parse(JSON.stringify(this.state.tempateData)));

      this.setState({ tempateData: [] }, () => {


        const tempateDataNewc = tempateData.filter(item => item.templateno !== row.templateno).map((item, index) => {

          item.templateno = index + 1;


          return item;
        });

        this.setState({
          tempateData: tempateDataNewc

        },
          () => {


          });
      });
    }
  }

  closeSlideOut = () => {
    this.props.closeSlideOut(this.state?.shouldReloadMainPage);

  };

  handleNewRow = () => {

    let templateData: any = [];
    let newRow: any = {};
    let isStateChanged = true;

    
    templateData = Object.assign([], JSON.parse(JSON.stringify(this.state.templateData)));
    newRow = Object.assign({}, JSON.parse(JSON.stringify(this.state.newRow)));

    let maxValueOfRow = Math.max(...templateData.map(o => o.srno), 0);
    let len = templateData.length;
    let maxRno = maxValueOfRow;
    let maxSrno = templateData[len - 1].srno;
    newRow[0].srno = Number(maxSrno) + 1;
    newRow[0].processList[1].id = Number(maxSrno) + 1;
    let filterRow = this.state.processdataList.filter(x => x.srno === Number(maxSrno) + 1);

    if (filterRow.length != 0) {
      newRow[0].tDescription = filterRow[0].pDescription;

      newRow[0].processName = filterRow[0].processName;
      newRow[0].processid = filterRow[0].id;
    }
    templateData.push(newRow[0]);
    this.setState({ tempateData: [] }, () => {
      this.setState({ templateData: templateData, isStateChanged });

    });




  };

  handleDiscard(postID) {
    this.setState({ isStateChanged: false }, () => {
      this.closeSlideOut();
    });
  }

  GetProcesslist = (requestlist: any) => {




    let templateData: any = [];
    templateData = Object.assign([], JSON.parse(JSON.stringify(this.state.templateData)));
    let newRow = this.state.newRow;
    PartTemplateService.GetProcessList(requestlist)
      .then(async (result: any | null) => {
        if (result !== null) {



          templateData.forEach(element => {
            element.processList = result;

          });
          newRow.forEach(element => {
            element.processList = result;

          });
          //  newRow.processList=result;


          this.setState({ templateData: [], processdataList: [] },
            () => {
              this.setState({
                templateData: templateData,
                newRow: newRow,
                processdataList: result,
              }
                ,
                () => {
                  let formData = { ...this.state.formData };

                  this.GetTemplateData();

                  console.log(templateData);
                }

              );
            });
        }
        resolve();
        // console.log(this.state.processList);
      })
      .catch((error) => {
        this.setState({ isSpinner: false });
        reject();
      });

  };

   

  HidecloseSlideOut() {
    this.props.closeSlideOut();
    this.setState({ isSaveSpinner: false });
  }

 

  savetemplate = (entryType, isCopy) => {
    let formData = { ...this.state.formData };

    let isFormValid = true;
    let request: any = {};


    let templateData = this.state.templateData;
    this.state.formData.TemplateDetails = templateData;

    request = Object.assign({}, JSON.parse(JSON.stringify(this.state.formData)));



    if (request.templatename === "") {
      toast.error("Please enter template name.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "OrderSlideout",
      });
      // this.setState({ isSaveSpinner: false });
      return false;
    }


    isFormValid = true;


    if (isFormValid) {
      this.setState({ saveRequest: request, ispopupshow: false });

      PartTemplateService.SaveTemplateData(request)
        .then(async (result: any | null) => {
          if (result !== null) {
            
            // this.setState({ isSaveSpinner: false });


            toast.success(result.result, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "OrderSlideout",
            });
          }
          if (result.message == "Success") {
            this.HidecloseSlideOut();
          }


          resolve();
        })
        .catch((error) => {
          this.setState({ isdefaultColDisabled: false, isSpinner: false });
          reject();
        });
    }
  };



  handleChange = (event: any, FieldType: string) => {
    let isStateChanged = true;
    let start = 0;
    let end = 0;

    let formData = { ...this.state.formData };

    if (FieldType === "partname") {
      formData.partname =
        event.target.value.trim().length > 0 ? event.target.value : "";
    }
    else if (FieldType === "partno") {
      formData.partno =
        event.target.value.trim().length > 0 ? event.target.value : "";
    }
    else if (FieldType === "pdescription") {
      formData.pdescription =
        event.target.value.trim().length > 0 ? event.target.value : "";
    }
    
    else if (FieldType === "UnitPrice") {
      formData.UnitPrice =
        event.target.value.trim().length > 0 ? event.target.value : 0;
    }
    else if (FieldType === "DeliveryTime") {
      formData.noofday =
        event.target.value.trim().length > 0 ? event.target.value : 0;
    }
    
    this.setState({ formData, isStateChanged, isSpinner: false }, () => {

    });
  };
  updateCustomerList = (result: any) => {
   
    this.setState({ customerList: [] }, () => {
      this.setState({ customerList: result });
    });
  };

  handleCustomerChange = (e: any) => {
    let isStateChanged = true;
    if (e.length > 0) {
      let requestlist = { ...this.state.requestlist };

      let formData = { ...this.state.formData };
      requestlist.vid = e[0].customer_id;
      formData.CustomerID = e[0].customer_id;
      formData.CustomerName = e[0].company_name;
      formData.address = e[0].address;
      formData.customercode = e[0].customercode;
      this.setState({
        CustomerName: e[0].company_name,
        address: e[0].address,
        customercode: e[0].customercode,
        isStateChanged:true
      });
      this.state.formData.CustomerID = e[0].customer_id;
      this.state.formData.CustomerName = e[0].company_name;

    
      
    
    } 
  };

  GetCustomerlist = (requestlist: any) => {
    CustomerService.GetCustomerlist(requestlist)
      .then(async (result: any | null) => {
        if (result !== null) {
          this.setState({ customerList: result, isSpinner: false });
        }
        this.GetProductById();
        resolve();
      })
      .catch((error) => {
        this.setState({ isSaveSpinner: false });
        reject();
      });
  };

  render() {
    const {

    } = this.state;
 

    return (
      //  <div className="transaction-slideout invoice-entry pos-fxd index-1000">

      <section>
        <div className="details-drawer"
          style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
        // className="back-drop1  viewer-panel1 opacity "
        >
          <ToastContainer
            containerId={"userDetailSlideoutcontainer"}
            autoClose={3000}
          />

          <div className="d-flex">
            {!this.state.isOpenViewer && (
              <div
                className="back-drop1  viewer-panel1 opacity "
                onClick={() => this.closeSlideOut()}
              ></div>
            )}

            <Container
              fluid

              className="body-sec viewer-panel back-drop p-0 "
              style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}

            >
              <div
                className="container-fluid py-1 bg-white d-flex align-items-center  border-bottom round-4"
              >
                <div className="divheader">
                  <h5 className="my-title">
                  {this.state.templateid == 0 && <span>New Part</span>}
              {this.state.templateid != 0 && (
                <span>
                  Part Name : {this.state.formData.firstline}
                </span>
              )}        
              </h5>


                  <div className="header-popout ml-auto">
                  <div className="container text-center">
  <div className="row align-items-start">
    <div className="col">
      </div>

      <div className="col">
      <button
                          type="button"
                          className="btn btn-primary cross"
                          onClick={this.closeSlideOut}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                              fill="#ffffff"
                            />
                          </svg>
                        </button>
      </div>
      </div>
      </div>
                  </div>
                </div>
              </div>
              <div
                className="scroll"
              >

                <section className="pb-3">
                  <div className="row g-3">

                  <div className="col-12 col-md-6" >
        <div className="card h-100">
          <div className="card-header">
            Part Details
          </div>
          <div className="card-body">
            <div className="row g-3">
            <div className="col-md-5" >
              <Form.Group controlId="customerName">
                       <div className="parent my-data">
                         <Form.Label className="font-semibold">Part Number</Form.Label>
                         <div
                           tabIndex={this.state.tabEnable}
                           className="type-head"
                         >
                           <Form.Control
                             type="text"
                             id="txtTemplate"
                             value={this.state.formData.partno}
                             onChange={(event: any) =>
                               this.handleChange(event, "partno")
                             }
                             autoComplete="off"
                             title=""
                           />
                         </div>
                       </div>
                     </Form.Group>

              </div>
              <div className="col-md-12" >
              <Form.Group controlId="customerName">
                    <div className="parent my-data">
                      <Form.Label className="font-semibold">Part Description</Form.Label>
                      <div
                        tabIndex={this.state.tabEnable}
                        className="type-head"
                      >
                        <Form.Control
                          as="textarea"
                          id="txtpartname"
                          value={this.state.formData.partname}
                          onChange={(event: any) =>
                            this.handleChange(event, "partname")
                          }
                          autoComplete="off"
                          rows={6}
                          title=""
                        />
                      </div>
                    </div>
                  </Form.Group>
              </div>
              <div className="row g-3">

              <div className="col-md-6" >
              <Form.Group controlId="customerName">
                       <div className="parent my-data">
                         <Form.Label className="font-semibold">Unit Price</Form.Label>
                         <div
                           tabIndex={this.state.tabEnable}
                           className="type-head"
                         >
                           <Form.Control
                             type="text"
                             id="txtTemplate"
                             value={this.state.formData.UnitPrice}
                             onChange={(event: any) =>
                               this.handleChange(event, "UnitPrice")
                             }
                             autoComplete="off"
                             title=""
                           />
                         </div>
                       </div>
                     </Form.Group>
              </div>
              <div className="col-md-6" >
              <Form.Group controlId="customerName">
                       <div className="parent my-data">
                         <Form.Label className="font-semibold">Delivery Time</Form.Label>
                         <div
                           tabIndex={this.state.tabEnable}
                           className="type-head"
                         >
                           <Form.Control
                             type="text"
                             id="txtTemplate"
                             value={this.state.formData.noofday}
                             onChange={(event: any) =>
                               this.handleChange(event, "DeliveryTime")
                             }
                             autoComplete="off"
                             title=""
                           />
                         </div>
                       </div>
                     </Form.Group>
              </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="col-12 col-md-6" >
        <div className="card h-100">
          <div className="card-header">
            Customer Details
          </div>
          <div className="card-body">
            <div className="row g-3">
            <div className="row g-3">
            <div className="col-md-6" >
            <Form.Group controlId="customerName">
                        <div className="parent my-data">
                          <Form.Label className="font-semibold">
                            Customer Name
                          </Form.Label>
                          <div
                            tabIndex={this.state.tabEnable}
                            className="type-head"
                          >
                            <CustomerSearch
                              ref={this.customerSearch}
                              updateCustomerList={this.updateCustomerList}
                              handleCustomerChange={
                                this.handleCustomerChange
                              }
                            />
                          </div>
                        </div>
                      </Form.Group>

              </div>
              <div className="col-md-6" >
              <Form.Group controlId="customerName">
                        <div className="parent my-data">
                        <Form.Label className="font-semibold">Customer Code</Form.Label>
                         <div
                           tabIndex={this.state.tabEnable}
                           className="type-head"
                         >
                           <Form.Control
                             type="text"
                             id="txtTemplate"
                             value={this.state.formData.customercode}
                             onChange={(event: any) =>
                               this.handleChange(event, "CustomerCode")
                             }
                             autoComplete="off"
                             title=""
                           />
                          </div>
                        </div>
                      </Form.Group>
              </div>
             </div>
             
            </div>
          </div>
        </div>

      </div>

                    {/* <div className="col-12 col-md-3" >
                    <Form.Group controlId="customerName">
                    <div className="parent my-data">
                      <Form.Label className="font-semibold">Part Name</Form.Label>
                      <div
                        tabIndex={this.state.tabEnable}
                        className="type-head"
                      >
                        <Form.Control
                          type="text"
                          id="txtpartname"
                          value={this.state.formData.partname}
                          onChange={(event: any) =>
                            this.handleChange(event, "partname")
                          }
                          autoComplete="off"
                          title=""
                        />
                      </div>
                    </div>
                  </Form.Group>

                    </div> */}
                    {/* <div className="col-12 col-md-3" >
                    <Form.Group controlId="customerName">
                       <div className="parent my-data">
                         <Form.Label className="font-semibold">Part No</Form.Label>
                         <div
                           tabIndex={this.state.tabEnable}
                           className="type-head"
                         >
                           <Form.Control
                             type="text"
                             id="txtTemplate"
                             value={this.state.formData.partno}
                             onChange={(event: any) =>
                               this.handleChange(event, "partno")
                             }
                             autoComplete="off"
                             title=""
                           />
                         </div>
                       </div>
                     </Form.Group>
                    </div> */}
                    {/* <div className="col-12 col-md-3" >
                    <Form.Group controlId="customerName">
                       <div className="parent my-data">
                         <Form.Label className="font-semibold">Unit Price</Form.Label>
                         <div
                           tabIndex={this.state.tabEnable}
                           className="type-head"
                         >
                           <Form.Control
                             type="text"
                             id="txtTemplate"
                             value={this.state.formData.UnitPrice}
                             onChange={(event: any) =>
                               this.handleChange(event, "UnitPrice")
                             }
                             autoComplete="off"
                             title=""
                           />
                         </div>
                       </div>
                     </Form.Group>
                    </div> */}

                    {/* <div className="col-12 col-md-3" >
                    <Form.Group controlId="customerName">
                        <div className="parent my-data">
                          <Form.Label className="font-semibold">
                            Customer Name
                          </Form.Label>
                          <div
                            tabIndex={this.state.tabEnable}
                            className="type-head"
                          >
                            <CustomerSearch
                              ref={this.customerSearch}
                              updateCustomerList={this.updateCustomerList}
                              handleCustomerChange={
                                this.handleCustomerChange
                              }
                            />
                          </div>
                        </div>
                      </Form.Group>
                    </div> */}

                    
                    

                    
                  </div>
                  {/* <div className="row g-3">

<div className="col-12 col-md-3" >
<Form.Group controlId="customerName">
<div className="parent my-data">
  <Form.Label className="font-semibold">Description</Form.Label>
  <div
    tabIndex={this.state.tabEnable}
    className="type-head"
  >
    <Form.Control
      type="text"
      id="txtpartname"
      value={this.state.formData.pdescription}
      onChange={(event: any) =>
        this.handleChange(event, "pdescription")
      }
      autoComplete="off"
      title=""
    />
  </div>
</div>
</Form.Group>

</div>
<div className="col-12 col-md-3" >

</div>
<div className="col-12 col-md-3" >

</div>

<div className="col-12 col-md-3" >

</div>





</div> */}
                </section>






             

                <div className={"footer-section viewer-panel"}
                  style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
                >
  <div>
                    <div className="d-flex align-items-center justify-content-end height-72">
                      <div className="d-flex align-items-center">
                      {this.state.isStateChanged && (
                        <>
                          <Button variant="primary" type="button" className="space btn btn-primary btn-discard" onClick={() => this.handleDiscard(this.state.orderUniqueNo)}>
                            Discard
                          </Button>
                          <Button id="btnSave" variant="primary" type="button"
                            onClick={() => this.saveProduct()}
                          // disabled={this.state.formData.templatename === "" ? true : this.state.isDisabled}
                          //onKeyDown={(e: any) => this.onTAB('btnSave-1', 0, e)}

                          >{"Save"}</Button>

                        </>
                      )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </Container>
          </div>

          1

          {(this.state.isSpinner || this.state.isSaveSpinner) && (
            <div className="loader-spinner d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="success" />
            </div>
          )}






        </div>

      </section>
    );
  }
}

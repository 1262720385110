import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface AboutModalProps {
    show: boolean;
    handleClose: () => void;
}

class AboutModal extends React.Component<AboutModalProps> {
    render() {
        const { show, handleClose } = this.props;

        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Body   style={{ 
                        fontFamily: "OpenSans", 
                        textAlign: 'center', 
                        border: '2px solid black',
                        borderRadius: '0'
                    }}
                >
                    <div style={{textAlign:'center'}}>
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="850" height="180" viewBox="0 0 2000.000000 580.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,580.000000) scale(0.100000,-0.100000)"
fill="#052c65" stroke="none">
<path d="M3550 4964 c-114 -15 -177 -27 -250 -48 -30 -8 -73 -20 -95 -26 -22
-6 -49 -15 -60 -20 -11 -4 -56 -23 -100 -40 -358 -143 -682 -412 -910 -756
-128 -193 -241 -460 -280 -664 -9 -47 -21 -101 -26 -120 -6 -19 -12 -141 -14
-270 -2 -170 -1 -202 4 -115 9 138 26 284 41 335 54 190 96 302 161 425 141
270 377 526 629 681 81 51 249 134 315 158 28 10 59 21 70 26 11 5 47 16 80
25 33 9 71 21 85 26 122 42 499 53 715 21 64 -10 99 -18 220 -52 76 -21 180
-64 280 -115 102 -51 293 -173 317 -202 11 -14 -2 -30 -101 -124 -62 -60 -119
-109 -127 -109 -7 0 -43 18 -79 40 -36 22 -67 40 -70 40 -2 0 -28 11 -57 24
-29 13 -80 32 -113 41 -33 9 -70 21 -83 26 -13 5 -83 17 -157 26 -126 15 -144
15 -270 0 -74 -9 -144 -21 -157 -26 -13 -5 -48 -16 -78 -26 -56 -17 -69 -23
-175 -70 -255 -115 -490 -374 -606 -667 -61 -155 -87 -365 -69 -554 12 -128
13 -131 60 -277 29 -91 66 -171 119 -257 11 -19 21 -39 21 -44 0 -6 -122 -130
-271 -278 -219 -216 -269 -271 -264 -287 7 -23 169 -191 259 -269 68 -59 213
-157 339 -230 92 -52 315 -145 407 -169 137 -35 180 -45 245 -54 105 -15 481
-23 540 -11 28 6 93 18 145 27 103 17 109 19 250 66 103 33 126 43 240 97 532
252 936 748 1074 1317 10 39 21 88 27 110 19 80 31 213 34 375 4 173 -2 157
-24 -65 -19 -194 -72 -375 -166 -570 -48 -99 -142 -255 -191 -315 -116 -142
-207 -231 -344 -336 -111 -85 -355 -224 -394 -224 -7 0 -16 -3 -20 -7 -10 -11
-141 -57 -206 -73 -165 -41 -247 -50 -455 -50 -115 -1 -225 4 -243 9 -18 5
-36 10 -40 10 -23 0 -111 14 -137 21 -16 5 -70 21 -120 36 -108 32 -160 53
-284 116 -92 47 -226 132 -286 182 l-30 25 105 106 c58 59 112 110 120 115 10
6 36 -4 80 -31 111 -67 248 -119 420 -160 70 -17 335 -17 410 0 226 52 389
122 538 233 l83 62 -155 155 -155 155 -43 -32 c-139 -106 -337 -160 -532 -148
-89 6 -110 11 -216 48 -213 74 -413 288 -474 508 -10 36 -22 78 -27 94 -14 47
-10 229 6 301 71 305 281 524 580 606 164 45 367 25 535 -52 124 -58 257 -176
322 -287 l28 -47 439 439 440 439 -77 85 c-67 74 -125 129 -247 231 -107 90
-364 238 -515 296 -25 10 -54 21 -65 25 -11 5 -47 16 -80 25 -33 9 -71 21 -85
26 -147 50 -542 73 -760 43z"/>
</g>
</svg>
</div>
                    <div style={{ fontSize: '40px', fontWeight: 'bold', marginBottom: '10px' ,textAlign:'center'}}>
                        CIMMPLE
                    </div>
                    <div style={{ fontSize: '22px', textAlign:'center', marginBottom: '10px' , padding:'5px'}}>
                        Connected Intelligent Manufacturing Management Platform for<br></br> Logistics Efficiency
                    </div>
                    <div style={{fontSize: '22px',textAlign:'center', marginBottom: '10px', padding:'60px' }}>
                    Please write to us at <span style={{fontWeight:'bold'}}>contact@cimmple.com</span>
                    </div>
                   
                    <div style={{fontSize: '20px',textAlign:'center', marginBottom: '10px',marginTop:'60px'}}>
                        CIMMPLE<sup style={{fontSize:'11px'}}>TM</sup> is a registered trademark. <br></br>
                        All rights reserved.
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default AboutModal;

import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import * as React from "react";
import {
  Container,
  Dropdown,
  Accordion,
  Card,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import { VendorService } from "../Common/Services/VendorService";
import { Utils } from "../Common/Utilis";
import _ from "lodash";
import paginationFactory from "react-bootstrap-table2-paginator";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import createDOMPurify from "dompurify";
import chevronRight from "../../Common/Assets/Images/icons/bx-chevron-right.svg";
import moment from "moment";
import { JobTrackerServices } from "../Common/Services/JobTrackerServices";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { DetailedReport } from "./DetailedReport";
import { SingleDropdownListWithoutSearch } from "../Common/Components/SingleDropdownListWithoutSearch";
const { SearchBar } = Search;

import "react-tabs/style/react-tabs.css";
export class ReportsSlideout extends React.Component<any, any> {
  private parentScroll: any;

  constructor(props: any) {
    super(props);
    this.parentScroll = React.createRef();
    this.state = {
      data: [
        {
          reportNo: "RPT#123",
          reportType: "Customer order",
          reportName: "Monthly status report",
          reportdate: "2024-08-17T06:12:31.78",
          repeat: "Monthly",
        },
        {
          reportNo: "RPT#1234",
          reportType: "Vendor order",
          reportName: "Weekly status report",
          reportdate: "2024-08-17T06:12:31.78",
          repeat: "Weekly",
        },
      ],

      selectedcolumn: "All",
      taboption: "first",
      showDetailedReport: false,
      selectOptions: [
        {
          id: "All",
          name: "All",
        },
        { id: "Report No", name: "Report No" },
        { id: "Report Type", name: "Report Type" },
        { id: "Report Name", name: "Report Name" },
      ],
    };
  }

  shownewslideout = () => {
    this.setState({
      showSlideout: true,
    });
  };

  showslideout = () => {
    this.setState({ showSlideout: true, isSpinner: false });
  };

  closeSlideOut = () => {
    this.props.closeSlideOut(true);
  };

  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      if (e.target.tagName !== "BUTTON") {
        this.setState({
          showSlideout: true,
        });
      }
    },
  };

  handleSelect = (key: string | null) => {
    this.setState({ taboption: key || "first" });
  };

  render() {
    const customTotal = (from, to, size) => (
      <span className="text-muted">
        Showing {from} to {to} of {size} entries
      </span>
    );
    const options = {
      showTotal: true,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "25",
          value: 25,
        },
        {
          text: "50",
          value: 50,
        },

        {
          text: "All",
          value: this.state.data.length,
        },
      ], // A numeric array is also available. the purpose of above example is custom the text
    };

    const qualitycolumns = [
      {
        dataField: "srno",
        text: "Sr No.",
        hidden: false,
        editable: false,
        headerStyle: { width: "70px" },
        headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
        formatter: (cell: any, row: any, rowIndex: any) => {
          return <div>{rowIndex + 1}</div>;
        },
      },

      {
        dataField: "reportName",
        text: "Report Name",
        sort: true,
        classes: "text-truncate",
        headerStyle: { width: "10%" },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex align-items-center txt-bold">
              <div style={{ width: "150px" }}>
                <EllipsisWithTooltip placement="bottom">
                  {row.reportName}
                </EllipsisWithTooltip>
              </div>
            </div>
          );
        },
      },
      {
        dataField: "",
        text: "Action",

        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="btn-group">
              <Dropdown
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="more-action"
              >
                <Dropdown.Toggle
                  className="btn-outline-primary btn btn-primary-white more"
                  id={"dropdown-more" + rowIndex}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    eventKey={"comments"}
                    onClick={() => {
                      this.setState({ showDetailedReport: true });
                    }}
                  >
                    Run Report{" "}
                  </Dropdown.Item>
                  <Dropdown.Item eventKey={"comments"}>Delete </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          );
        },
      },
    ];

    const { showSlideout } = this.state;

    return (
      <section>
        <div
          className="details-drawer"
          style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
          // className="back-drop1  viewer-panel1 opacity "
        >
          <div
            className="back-drop1  viewer-panel1 opacity "
            style={{ minWidth: "25%", maxWidth: "100%", height: "100vh" }}
            /*  className="back-drop1  viewer-panel1 opacity " */
            onClick={() => this.closeSlideOut()}
          ></div>

          <Container
            fluid
            ref={this.parentScroll}
            className="body-sec viewer-panel back-drop p-2"
            style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
          >
            <div className="page-heading underline d-flex  ">
              Create Report
              <div className="d-flex flex-row ml-auto">
                <button
                  type="button"
                  className="btn btn-primary cross"
                  onClick={this.closeSlideOut}
                  style={{ marginLeft: "10px" }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                      fill="#ffffff"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <Tab.Container
              transition={false}
              activeKey={this.state.taboption}
              onSelect={this.handleSelect}
            >
              <section className="pb-3">
                <Nav className="nav nav-underline border-bottom">
                  <Nav.Item className="nav-item">
                    <Nav.Link eventKey="first">
                      Customer Orders
                      <span className="badge badge-light">
                        {this.state.data.length}
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item">
                    <Nav.Link eventKey="second">
                      Vendor Orders
                      <span className="badge badge-light">
                        {" "}
                        {this.state.data.length}
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item">
                    <Nav.Link eventKey="third">
                      Quality
                      <span className="badge badge-light">
                        {this.state.data.length}
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </section>

              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <ToolkitProvider
                    keyField="id"
                    data={this.state.data}
                    columns={qualitycolumns}
                    search
                  >
                    {(props) => (
                      <div>
                        <section className="d-flex flex-wrap gap-3 pb-2">
                          <div className="position-relative">
                            <SearchBar {...props.searchProps} />
                          </div>

                          <div>
                            <Form.Label>
                              <SingleDropdownListWithoutSearch
                                itemList={this.state.selectOptions}
                                // handleSelectedItem={this.handleSelectedItem1.bind(
                                //   this,
                                //   1
                                // )}
                                defaultItem={this.state.selectedcolumn}
                                defaultText={"Select Filter"}
                                defaultName={this.state.selectedcolumn}
                                controlID="1"
                                id={"ddlselected"}
                              />
                            </Form.Label>
                          </div>
                        </section>
                        <section className="pb-3" style={{marginLeft:"5px"}}>
                          <div className="table-responsive divbottom">
                            <div className="ml-auto transactions-list">
                              <BootstrapTable
                                {...props.baseProps}
                                rowEvents={this.rowEvents}
                                pagination={paginationFactory(options)}
                              />
                            </div>
                          </div>
                        </section>
                      </div>
                    )}
                  </ToolkitProvider>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <ToolkitProvider
                    keyField="id"
                    data={this.state.data}
                    columns={qualitycolumns}
                    search
                  >
                    {(props) => (
                      <div>
                        <section className="d-flex flex-wrap gap-3 pb-2">
                          <div className="position-relative">
                            <SearchBar {...props.searchProps} />
                          </div>

                          <div
                          //className="input-group w-auto"
                          >
                            <Form.Label>
                              <SingleDropdownListWithoutSearch
                                itemList={this.state.selectOptions}
                                // handleSelectedItem={this.handleSelectedItem1.bind(
                                //   this,
                                //   1
                                // )}
                                defaultItem={this.state.selectedcolumn}
                                defaultText={"Select Filter"}
                                defaultName={this.state.selectedcolumn}
                                controlID="1"
                                id={"ddlselected"}
                              />
                            </Form.Label>
                          </div>
                        </section>
                        <section className="pb-3" style={{marginLeft:"5px"}}>
                          <div className="table-responsive divbottom">
                            <div className="ml-auto transactions-list">
                              <BootstrapTable
                                id="tblGenerated"
                                {...props.baseProps}
                                keyField="rowId"
                                rowEvents={this.rowEvents}
                                pagination={paginationFactory(options)}
                                defaultSorted={[
                                  {
                                    dataField: "reportDate",
                                    order: "desc",
                                  },
                                ]}
                              />
                            </div>
                          </div>
                        </section>
                      </div>
                    )}
                  </ToolkitProvider>
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <ToolkitProvider
                    keyField="id"
                    data={this.state.data}
                    columns={qualitycolumns}
                    search
                  >
                    {(props) => (
                      <div>
                        <section className="d-flex flex-wrap gap-3 pb-2">
                          <div className="position-relative">
                            <SearchBar {...props.searchProps} />
                          </div>

                          <div>
                            <Form.Label>
                              <SingleDropdownListWithoutSearch
                                itemList={this.state.selectOptions}
                                // s
                                defaultItem={this.state.selectedcolumn}
                                defaultText={"Select Filter"}
                                defaultName={this.state.selectedcolumn}
                                controlID="1"
                                id={"ddlselected"}
                              />
                            </Form.Label>
                          </div>
                        </section>
                        <section className="pb-3" style={{marginLeft:"5px"}}>
                          <div className="table-responsive divbottom">
                            <div className="ml-auto transactions-list">
                              <BootstrapTable
                                {...props.baseProps}
                                rowEvents={this.rowEvents}
                                pagination={paginationFactory(options)}
                              />
                            </div>
                          </div>
                        </section>
                      </div>
                    )}
                  </ToolkitProvider>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Container>

          {(this.state.isSpinner || this.state.isSaveSpinner) && (
            <div className="loader-spinner d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="success" />
            </div>
          )}

          {this.state.showDetailedReport && <DetailedReport closeSlideout={()=>{this.setState({showDetailedReport:false});}} />}
        </div>
      </section>
    );
  }
}

import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

import DatePicker from "react-datepicker";
import * as React from "react";
import { Container, Dropdown, Accordion, Card, Button ,Form,Spinner,} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import {Utils}  from "../../Common/Utilis";
import{DateFilter} from "../../Common/Components/DateFilter";
import _ from "lodash";
import paginationFactory from 'react-bootstrap-table2-paginator';
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import createDOMPurify from "dompurify";
import chevronRight from "../../Common/Assets/Images/icons/bx-chevron-right.svg";
import moment from "moment";
import { JobTrackerServices } from "../../Common/Services/JobTrackerServices";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import {JobCreations}  from '../JobCreations';
import {NCRSlideout}  from '../Job/NCRSlideout';
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import { SingleDropdownListWithoutSearch } from "../../Common/Components/SingleDropdownListWithoutSearch";

const { SearchBar } = Search;


//import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import 'react-tabs/style/react-tabs.css';


const DOMPurify = createDOMPurify(window);

export class NCRReport extends React.Component<any, any> {
  private trDate: any;
  constructor(props: any) {
    super(props);
    this.trDate = React.createRef();
    this.state = {
      isOrderDate:true,
      isvendorname:true,
      isvendorOrderType:true,
      isvendorPoNumber:true,
      htmlItem: [],
      htmlErrorItem: [],
      htmlWarningItem: [],
      searcResult: [],      
      isNCRDate:true,      
      isPartNo:true,
      isPartName:true,
      isCustomerName:true,
      isExplanation:true,
      isJobNo:true,
      selectedcolumn:"All",
      selectOptions :  [ {
        id: 'All',name: 'All'},
      {  id: 'Part No',name: 'Part No'},
        {id: 'Customer Name',name:  'Customer Name'},
        {id: 'Part Name',name:  'Part Name'},
        { id: 'Job #',name:  'Job No.'},       
        { id: 'Explanation',name:  'Explanation'},       
     ],
    };
    

 
  }
 

  componentDidMount() { 
   this.getJobItems();
  }

  getJobItems()
  {
   
    let requestlist = { ...this.state.requestlist };
    requestlist.checkForReworkCount = 0;
    requestlist.from = this.state.fromDate;
    requestlist.to = this.state.toDate;

    JobTrackerServices.GetNCRJobData(requestlist)
    .then(async (result: any[] | null) => {
      if (result != null) {
       
        this.setState({ searcResult: result,isSpinner:false }, () => {
           
        });
      }
      resolve();
    })
    .catch((err: any) => {
      toast.error(`Server Error, ${err}`);
      reject();
    });
  }

 


  

  closeSlideOut = () => {
    this.setState({ showJobSlideout: false,showSlideout:false, isSpinner:false });
    this.getJobItems();
};
 rowEvents = {
  onClick: (e: any, row: any, rowIndex: any) => {
   
    if (e.target.tagName !== 'BUTTON') 
    {     
     
     this.setState({ showSlideout: true ,id:row.id,jobid:row.jobId,jobno:row.jobNo, isSpinner:false,}); 
    } 
  
  },
};

handleSelectedItem1 = (control: any, id: any) => {
  let searcResult1 = Object.assign(
    [],
    JSON.parse(JSON.stringify(this.state.searcResult))
  );

  let isPartNo = id == "Part No" ? true : false;
  let isCustomerName = id == "Customer Name" ? true : false;
  let isPartName = id == "Part Name" ? true : false;
  let isJobNo = id == "Job #" ? true : false;
  let isNCRDate = id == "NCR Date" ? true : false;
  let isExplanation = id == "Explanation" ? true : false;

  if (id == "All") {  
    isNCRDate=true;      
    isPartNo=true;
    isPartName=true;
    isCustomerName=true;
    isExplanation=true;
    isJobNo=true;
  }


  this.setState({
    searcResult: [], selectedcolumn: id, isNCRDate: isNCRDate, isPartNo: isPartNo,
    isPartName: isPartName, isjobNo: isJobNo,isCustomerName:isCustomerName,isExplanation:isExplanation
  },
    () => {
      this.setState({ searcResult: searcResult1 });
    }

  );

};

handleSelectedItem = (rowIndex: any,control: any,rowid:any, value: any) => {
  let searcResultReceiving = Object.assign(
    [],
    JSON.parse(JSON.stringify(this.state.searcResultReceiving))
  );

 
 
    let selectedRow = searcResultReceiving.filter((x)=> x.rowId == rowid);
    if(selectedRow.length > 0)
       selectedRow[0].received = value;
if(value=="Partial")
  {
    selectedRow[0].qtyReceived = 0;
  }
  this.setState({
    searcResultReceiving: searcResultReceiving,isStateChanged: true
  },
    () => {
      this.setState({ searcResultReceiving: searcResultReceiving});
    }
  );
};





 
reopenSlideOutWithReworkJob = () => {
  this.setState({ showJobSlideout: false}, () => {
   
   let rNo = Utils.extractIncrementedRNumber(this.state.jobNo);
   this.setState({ showJobSlideout: true,showCustomerSlideout: false, jobid: 0,rNo:rNo});
  });
  

};

showJobSlideOut = (row,type) => {  

  if(type === 'job')
  {
   
    let jobNo =  Utils.removeRNumber(row.jobNo);
    let itemNo = Utils.extractNumberAfterHyphen(jobNo);
    let rNo = Utils.extractRNumber(row.jobNo);
    this.setState({ showJobSlideout: true,showCustomerSlideout: false, orderid:row.orderDetailId ,jobid: row.jobId,qtyOrdered:row.qty,partname:row.partName,partNo:row.partNo,selectedCustomer:row.customerName,productid:row.partId,CustomerPoNumber:row.customerPoNumber,itemNo:itemNo,jobNo:row.jobNo,rNo:rNo});
  }
   
};

printOrder = (ncrid,jobId) => {
  let request: any = {};
  request.ncrId = ncrid;
  request.jobId = jobId;

  this.setState({ isSpinner: true });

  JobTrackerServices.DownloadNCRFile(request)
    .then(async (result: any | null) => {
      //this.HidecloseSlideOut();
      this.setState({ showAfterSaveModelPopup: false, isSpinner: false, });
      resolve();
    })
    .catch((error) => {
      reject();
      this.setState({ showAfterSaveModelPopup: false, isSpinner: false, });
    });
};

handleChange = (event: any, FieldType: string) => {
  
 
 if (FieldType === "From") {
  this.setState({ fromDate:event.toDateString()},()=>{
    
  });  
  }
  else if (FieldType === "To") {
    this.setState({ toDate:event.toDateString()},()=>{     
    });
  }
};

applySearch = () => {
  this.setState({isSpinner:true});
  this.getJobItems();
};


   render() { 
    const customTotal = (from, to, size) => (
      <span className="text-muted">
        Showing {from} to {to} of {size} entries
      </span>
    );
    const options = {

      showTotal: true,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '10', value: 10
      },
      {
        text: '25', value: 25
      },
      {
        text: '50', value: 50
      },

      {
        text: 'All', value: this.state.searcResult.length
      }] // A numeric array is also available. the purpose of above example is custom the text
    };

    const columns = [
  
      
      {
        dataField: 'ncrNo',
        text: 'NCR No',
        sort: true,
        headerStyle: { width: '100px' },
        searchable: this.state.isNCRNo,
        hidden:false,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
             <EllipsisWithTooltip placement="bottom">
             {row.ncrNo}
             </EllipsisWithTooltip>
          );
        },
      }, 
      
      {
        dataField: 'ncrDate',
        text: 'NCR Date',
        sort: true,
        headerStyle: { width: '100px' },
        searchable:this.state.isNCRDate,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
              <EllipsisWithTooltip placement="bottom">
                  {row.ncrDate != null && row.ncrDate.length > 0 ? moment(row.ncrDate).format("MM/DD/YYYY"):''}
              </EllipsisWithTooltip>
          );
      },
      },
      {
        dataField: 'isFixed',
        text: 'NCR Type',
        sort: true,
        headerStyle: { width: '100px' },
        //searchable:this.state.isExplanation,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
             <EllipsisWithTooltip placement="bottom">
             {row.isFixed ? "Process":"Material"}
             </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: 'jobNo',
        text: 'Job No.',
        sort: true,
        headerStyle: { width: '100px' },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
             <EllipsisWithTooltip placement="bottom">
             <Button className="btn-link" onClick={() => this.showJobSlideOut(row,'job')}>
             {row.jobNo}
             </Button>
             </EllipsisWithTooltip>
          );
        },
        searchable:this.state.isJobNo,
      },
      {
        dataField: 'partNo',
        text: 'Part No',
        sort: true,
        headerStyle: { width: '150px' },
        searchable:this.state.isPartNo,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
             <EllipsisWithTooltip placement="bottom">
             {row.partNo}
             </EllipsisWithTooltip>
          );
        },
      }, 
      {
        dataField: 'partName',
        text: 'Part Description',
        sort: true,
        classes:"text-truncate",
          headerStyle: { width: "10%" },
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
             
              <div className="d-flex align-items-center txt-bold">
              <div style={{width: '150px'}}>
             <EllipsisWithTooltip placement="bottom">
               {row.partName}
             </EllipsisWithTooltip>
           </div>
           </div>
            );
        
        },
      },     
      {
        dataField: 'customerName',
        text: 'Customer Name',
        sort: true,
        hidden:true,
        headerStyle: { width: '150px' },
        searchable:this.state.isCustomerName,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
             <EllipsisWithTooltip placement="bottom">
             {row.customerName}
             </EllipsisWithTooltip>
          );
        },
      },
    
     
      {
        dataField: 'ncrStatus',
        text: 'Status',
        sort: true,
        headerStyle: { width: '100px' },
        //searchable:this.state.isExplanation,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
             <EllipsisWithTooltip placement="bottom">
             {row.ncrStatus}
             </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: 'TrackerStatus',
        text: 'Recomended Action',
        sort: true,
        headerStyle: { width: '100px' },
        //searchable:this.state.isExplanation,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
             <EllipsisWithTooltip placement="bottom">
             {row.trackerStatus}
             </EllipsisWithTooltip>
          );
        },
      },
      // {
      //   dataField: '',
      //   text: 'Print',
      //   sort: true,
      //   hidden: false,
      //   headerStyle: { width: '150px' },
      //  // searchable:this.state.isExplanation,
      //   formatter: (cell: any, row: any, rowIndex: any) => {
      //     return (
      //       <Button variant="primary"   className="btn btn-primary px-4" onClick={()=>this.printOrder(row.id,row.jobId)}> Print</Button>
      //     );
      //   },
      // },
    ];

   

  

 

    const {
      
      
  } = this.state;

 
  

 

 
  




    return (
      <>
        <React.Fragment>



          <Container fluid className="body-sec">
            <div className="page-heading underline d-flex  ">

               Non-Compliance Report 
              <div className="action-group d-flex flex-row ml-auto">

              </div>
            </div>
            <section className="mt-3">
            <ToolkitProvider
                    keyField="id"

                    data={ this.state.searcResult.filter((x)=>x.trackerStatus !== null && x.ncrNo !==null && x.trackerStatus.length > 0) }
                    columns={ columns }
                    search
                  >
                    {
                      props => (


                        <div >
                          <section className="d-flex flex-wrap gap-3 pb-2">
                            <div className="position-relative">
                              <SearchBar {...props.searchProps} />
                            </div>

                            <div
                            className='px-2' 
                            >
                              <Form.Label>
                                <SingleDropdownListWithoutSearch

                                  itemList={this.state.selectOptions}
                                  handleSelectedItem={this.handleSelectedItem1.bind(this, 1)}
                                  defaultItem={this.state.selectedcolumn}
                                  defaultText={"Select Filter"}
                                  defaultName={this.state.selectedcolumn}
                                  controlID="1"
                                  id={"ddlselected"}

                                /></Form.Label>
                            </div>

                            <div className='leftmargindate' >

<Form.Group controlId="date">
  <div className="date-picker calender-picker">
    <DatePicker
      ref={this.trDate}
      id="txtFrom"
      tabIndex={this.state.tabEnable}
      className="form-control px-0 w-100 text-center"
      placeholderText={"From"}
      dateFormat="MM/dd/yy"
      selected={
        (this.state.fromDate === null || this.state.fromDate === undefined)
          ? null
          : this.state.fromDate === ""
            ? null
            : new Date(this.state.fromDate)
      }
      onChange={(event: any) =>this.handleChange(event, "From") }
      //onClick={(e: any) =>this.onTAB("txtOrderDate-1", 0, e)}
      autoComplete="off"
      disabled={false}
    />
  </div>


</Form.Group>

                             </div>
                             <div  >





 
  <div className='childs'>
  <Form.Group controlId="date">
  <div className="date-picker calender-picker">
    <DatePicker
      ref={this.trDate}
      id="txtFrom"
      tabIndex={this.state.tabEnable}
      className="form-control px-0 w-100 text-center"
      placeholderText={"To"}
      dateFormat="MM/dd/yy"
      selected={
        (this.state.toDate === null || this.state.toDate === undefined)
          ? null
          : this.state.toDate === ""
            ? null
            : new Date(this.state.toDate)
      }
      onChange={(event: any) =>this.handleChange(event, "To") }
      //onClick={(e: any) =>this.onTAB("txtOrderDate-1", 0, e)}
      autoComplete="off"
      disabled={false}
    />
   
</div>
 </Form.Group>
</div>
<div className='childs'>
<Button variant="primary"   className="btn btn-primary px-4" onClick={()=>this.applySearch()}> Apply</Button>
</div>



                             </div>

                          { false &&   <DateFilter
                             toDate = {this.state.toDate}
                             fromDate = {this.state.fromDate}
  handleChange = {this.handleChange}
  applySearch={this.applySearch}

>
</DateFilter>}

                          </section>
                          <section className="pb-3">
                            <div className="table-responsive divbottom"  >
                              <div className="ml-auto transactions-list">
                              <BootstrapTable id ="tblGenerated"
          { ...props.baseProps }
          keyField="rowId"
          rowEvents={this.rowEvents}
        pagination={ paginationFactory(options) }
        defaultSorted={[
          {
            dataField: 'ncrDate',
            order: 'desc' 
          }
        ]}
        />
                              </div>
                            </div>
                          </section>
                        </div>

                      )
                    }
                  </ToolkitProvider>

</section>



            {(this.state.isSpinner || this.state.isSaveSpinner) && (
            <div className="loader-spinner d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="success" />
            </div>
          )}



{this.state.showJobSlideout && (

<JobCreations
  closeSlideOut={this.closeSlideOut}
  reopenSlideOutWithReworkJob = {this.reopenSlideOutWithReworkJob}
  jobid={this.state.jobid}
  orderid={this.state.orderid}
  CustomerPoNumber={this.state.CustomerPoNumber}
  poNumber={this.state.poNumber}
  productid={this.state.productid}
  partname={this.state.partname}
  partNo={this.state.partNo}
  qtyOrdered={this.state.qtyOrdered}

  itemNo={this.state.itemNo}
  selectedCustomer={this.state.selectedCustomer}
  orderdate={this.state.orderdate}
  dueDate={this.state.dueDate}
  jobDesc={this.state.jobDesc}
  id={this.state.orderid}
  rNo={this.state.rNo}

>
</JobCreations>
)}

{this.state.showSlideout && (

<NCRSlideout
  closeSlideOut={this.closeSlideOut}
  jobid={this.state.jobid}
  id={this.state.id}
  jobno={this.state.jobno}
  readonly={true}

>
</NCRSlideout>
)}

          </Container>



        </React.Fragment>
      </>
    );
  }
}
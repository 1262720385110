import * as React from "react";
import { Row, Col, Button, Card, Spinner, Form, Modal } from "react-bootstrap";
import { User } from "../Common/Services/User";
import { Link, Redirect } from "react-router-dom";
import logo from "../Common/Assets/Images/logo-brand.png";
import { IUser } from "../Common/Contracts/IUser";
import { resolve, reject } from "q";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faTimes, faSave,  } from "@fortawesome/free-solid-svg-icons";
import { Cookies } from "react-cookie";
 
import { ToastContainer, toast } from "react-toastify";
 
import { Utils } from "../Common/Utilis";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";

const cookies = new Cookies();
class LoginComponent extends React.Component<any, any> {
  formDefaults = {
    userNameVal: { value: "", isValid: true, message: "" },
    userPwd: { value: "", isValid: true, message: "" },
  };
  constructor(props: any) {
    super(props);
    this.state = {
      needhelp: true,
      isDeeplink: false,
      deeplink: "/home",
      isLoading: false,
      inError: false,
      user_UniqueID: "0",
      userName: "",
      submitted: false,
      errorMessage: "",
      isCaptcha: false,
      captchaLoginButton: false,
      isBlocked: false,
      isValidateLogin: false,
      loginButtonSpinner: false,
      isShowinvalidCaptcha: 1,
      isMarketing: false,
      isMerge: "",
      isLaborModule: "",
      currentUtcTime: "",
      mergeURL: "",
      unmergeURL: "",
      mergeToken: "",
      ...this.formDefaults,
      //Modal
      modalShow: false,
      modalHelpShow: false,
      primaryInput: { email: "", mobile: "" },
      mobileReq: { required: false, message: "optional", style: "" },
      emailReq: { required: true, message: "Required", style: "text-danger" },
      primaryInputError: { mobileError: "", emailError: "" },
      modalValue: {
        username: "",
        tenantid: "",
        email: "",
        phone: "",
        IsEmail: "",
      },
      usId: { username: "", tenantid: "", uniqueid: "" },
      modalValueusId: {
        username: "",
        tenantid: "",
        email: "",
        phone: "",
        IsEmail: "",
      },
      primarymode: [
        { isPrimary: false, value: "email", label: "Email address" },
        { isPrimary: true, value: "mobile", label: "Mobile number" },
      ],
      notPrimaryError: "",
      isEmail: 1,
      isCookiePresent: false,
      currentDomain: window.location.hostname,
      isMaintenance: 1,
      showIncompleteSlideOut: false,
      client_id:"",
      idleLogOutMessage:"",
      showAlert:false,
    };
  }
  showAlertMessage = () => {	
    confirmAlert({	
      // title: "title",	
      message:	
        `To enjoy our website's full features, please disable your pop-up blocker. To do this, click on the Settings menu of your web browser and find the pop-up blocker option. Then, select 'Always allow pop-ups from this site' or add our website to the allowed list. Thank you for visiting our website!`,	
      buttons: [	
        {	
          label: "OK",	
          onClick: () => {window.close();} 	
        },	
        // {	
        //   label: "No",	
        //   onClick: () => { },	
        // },	
      ],	
      closeOnEscape: false,	
      closeOnClickOutside: false,	
    });	
  };	

  componentDidMount() {
 
    this.isUnderMaintenance();
    let deepParam = String(localStorage.getItem("deepParam"));
    let deepURL = String(localStorage.getItem("deepURL"));
    
    
    if (deepParam.includes("?")) {
      this.setState({ isDeeplink: true, deeplink: deepURL + deepParam });
    } else {
      this.setState({ isDeeplink: false, deeplink: "/home" });
    }
       const paramObject=  this.getURLParamsValues();
    if(this.state.isMaintenance === 1 && this.state.client_id == "" && paramObject?.isRedirectFromFreshDesk !=1 )	
    {	 
           
  
      
      if(paramObject?.client_id ){	
        this.setState({client_id:paramObject.client_id});
      }

      var pop = window.open("https://www.google.com",'windowname','toolbar=no,location=no,status=no,menubar=no,scrollbars=no,width=1,height=1,left=5,top=3');	
      if(pop == null){	
        this.setState({showAlert:true})	;
        // alert("Popups must be enabled.");	
      }else{	
        this.setState({showAlert:false});	
        // alert("Popups is disabled.");	
        pop && pop.close();	
        if(paramObject?.client_id  && paramObject?.isRedirectFromFreshDesk !=1){	
          const userdetail = JSON.parse(localStorage.getItem('storage') ||"");	
         const token = localStorage.getItem('token');
         this.autoLogin((Utils.getCookie("userName") || userdetail.userName),(Utils.getCookie("token") || token),paramObject);	
          
          return;	


        }
       
      }	
    }

    if(localStorage.getItem("logOutFromIdlePopUp")) {

       localStorage.removeItem("logOutFromIdlePopUp");

       this.setState({idleLogOutMessage:"You have been logged out due to inactivity"})  ;
    }
    // localStorage.clear();
    // sessionStorage.clear();
  }


  getURLParamsValues = (): any => {	
    const paramObject: any = {	
      response_type: this.getParamValueByParamName("response_type"),	
      client_id: this.getParamValueByParamName("client_id"),	
      scope: this.getParamValueByParamName("scope"),	
      state: this.getParamValueByParamName("state"),	
      redirect_uri:this.getParamValueByParamName("redirect_uri"),	
      nonce:this.getParamValueByParamName("nonce"),
      isRedirectFromFreshDesk:this.getParamValueByParamName("isRedirectFromFreshDesk")  ||  ((this.getParamValueByParamName("client_id")
      && !Utils.getCookie("userName")) ?1 : ""),
    };
    return paramObject;	
  };


  freshDeskOperation = (): any => {	
    const paramObject: any = this.getURLParamsValues();	
    if(paramObject.client_id){	
      this.setState({client_id:paramObject.client_id});
      const userdetail = JSON.parse(localStorage.getItem('storage') ||"");
    if(Utils.getCookie("userName") || userdetail?.userName) {
    
   	
      const token = localStorage.getItem('token');
      this.autoLogin((Utils.getCookie("userName") || userdetail.userName),(Utils.getCookie("token") || token),paramObject);	
     
    } else {
      const queryString = new URLSearchParams(paramObject).toString();

      this.props?.history.push(""+ queryString);


    }
  }
  };

  autoLogin(userName,token,paramObject){	
    User.AutoLogin(userName, token,paramObject.nonce)	
        .then(async (response: any) => {	
          let result = response.user;
          if (result) {
              this.setState({
                errorMessage: "",
                isBlocked: false,
                isCaptcha: false,
                captchaLoginButton: false,
                usId: {
                  username: result.userName,
                  tenantid: result.tenantID,
                  uniqueid: result.user_UniqueID,
                },
              });
  
              let errorMessage = this.state.errorMessage;
              this.letModalVal();
              if (result.user_UniqueID === 0) {
                errorMessage = "Please enter the valid username/password.";
                if (this.state.isShowinvalidCaptcha === 2) {
                  this.setState({ isBlocked: false, isCaptcha: true });
                } else {
                  this.setState({
                    isShowinvalidCaptcha: this.state.isShowinvalidCaptcha + 1,
                  });
                }
                this.setState({
                  errorMessage: errorMessage,
                  loginButtonSpinner: false,
                });
              } else {
                    localStorage.setItem("showIncompleteSlideOut", result?.showIncompleteSlideOut?.toString());
                    localStorage?.setItem("PopUpState", "");

                    this.setState({
                      userName: result.userName,
                      isMerge: result.isMerge,
                      isLaborModule: result.isLaborModule,
                      currentUtcTime: result.currentUtcTime,
                      mergeURL: result.mergeURL,
                      unmergeURL: result.unmergeURL,
                      mergeToken: result.mergeToken,
                      user_UniqueID: result.user_UniqueID,
                      isValidateLogin: true,
                      loginButtonSpinner: false,
                      showIncompleteSlideOut: result.showIncompleteSlideOut,
                      isDuplicatePhone: result.isDuplicatePhone,
                      isDuplicateEmail: result.isDuplicateEmail,
                      primaryMethod: result.primaryMethod
                    });
                     
                    if(paramObject?.client_id){	
                      localStorage.setItem("expirationTime", response?.expirationTime);
                      Utils.setCookie("expirationTime", response?.expirationTime ,1); 
                     
                      let url = paramObject.redirect_uri + "?state="+paramObject.state+"&id_token="+ response.token;//Utils.getCookie("token");
                      var win = window.open(url,'windowname','toolbar=no,location=no,status=no,menubar=no,scrollbars=no,width=1,height=1,left=5,top=3');

                      Utils.setCookie("userName","" ,0);
                      Utils.setCookie("token", "" ,0);
                    
                      setTimeout(()=>{	
                        let faqUrl = this.state.lastFreshdeskUrl || (result as any)?.lastClickedFreshdeskUrl;
                        if(!this.state.showAlert && faqUrl != null){
                          window.location.replace(faqUrl);
                        win?.close();
                        }
                      },10000);
                        
                      return;	
                    }	
                    resolve();
                  
                } 
                
                this.setState({
                  isShowinvalidCaptcha: 1,
                  loginButtonSpinner: false,
                });
            } 
        })	
        .catch((error) => {	
          this.setState({ loginButtonSpinner: false });	
          toast.error(`Server Error: ${error}`, {	
            position: toast.POSITION.BOTTOM_RIGHT,	
            containerId: "Login",	
          });	
          reject();	
        });	
  }

  getParamValueByParamName(paramName: string): any {	
    const valuesArray = decodeURIComponent(this.props.history.location.search)?.replace('?', '')?.split('&');	
    const item = valuesArray?.find(r => r?.includes(paramName));	
    return     item ? item?.split('=')[1] as any : "";	
  }	

  isUnderMaintenance() {
    User.UnderMaintenance().then(async (result: any | null) => {
      if (result.message === "success") {
        this.setState({ isMaintenance: result.result });
        if (result.result === 1) {
          window.location.href = window.location.origin + "/Under-Maintenance";
          return false;
        }
      }
    });
  }

  handleClose = () => {
    this.setState({ modalShow: false, notPrimaryError: "" });
  };
  handleHelpShow = () => {
    this.setState({ modalHelpShow: true });
  };
  handleCloseHelp = () => {
    this.setState({ modalHelpShow: false });
  };



  handleCloseModel = () => {
    this.setState({ changepasswordpopupshow: false, loginButtonSpinner: false });
  };
  
  

  isPrimaryEmail = () => {
    this.setState({
      isEmail: 1,
      primarymode: [
        { isPrimary: true, value: "email", label: "Email address" },
        { isPrimary: false, value: "mobile", label: "Mobile number" },
      ],
      emailReq: { required: true, message: "Required", style: "text-danger" },
      mobileReq: { required: false, message: "optional", style: "" },
    });
  };

  isPrimaryMobile = () => {
    this.setState({
      isEmail: 0,
      primarymode: [
        { isPrimary: false, value: "email", label: "Email address" },
        { isPrimary: true, value: "mobile", label: "Mobile number" },
      ],
      mobileReq: { required: true, message: "Required", style: "text-danger" },
      emailReq: { required: false, message: "optional", style: "" },
    });
  };

  setPrimaryContact = (e: any) => {
    this.setState({ notPrimaryError: "" });
    if (e.target.value === "email") {
      this.isPrimaryEmail();
    } else {
      this.isPrimaryMobile();
    }
  };

  letModalVal = () => {
    let { primaryInput, emailReq, mobileReq, usId, isEmail } = this.state;
    if (emailReq.required) {
      this.setState({ isEmail: 1 });
    }
    if (mobileReq.required) {
      this.setState({ isEmail: 0 });
    }
    this.setState({
      modalValue: {
        username: usId.username,
        tenantid: usId.tenantid,
        user_UniqueID: usId.uniqueid,
        email: primaryInput.email || "",
        phone: primaryInput.mobile || "",
        IsEmail: isEmail,
      },
      primaryInput,
    });
  };

  piChange = (e: any) => {
    let { name, value } = e.target;
    let { primaryInput } = this.state;
    name === "mobile"
      ? (primaryInput[name] = value.replace(/\D/, ""))
      : (primaryInput[name] = value);
    this.letModalVal();
  };

  submitForm = (e: any) => {
    e.preventDefault();
    this.setState({ notPrimaryError: "Please Wait" });
    let { modalValue, primaryInput, isEmail } = this.state;
    User.UpdateUserValidateStatus(
      modalValue.username,
      "",
      modalValue.tenantid,
      primaryInput.email,
      primaryInput.mobile,
      isEmail,
      "",
      "loginModal"
    ).then(async (userResTo: any | null) => {
      this.setState({ notPrimaryError: "Please Wait" });
      if (userResTo.result.rVal === "1") {
        this.setState({ notPrimaryError: "" });
        this.setState({ modalShow: false });
        this.setState({
          user_UniqueID: modalValue.uniqueid,
          isValidateLogin: true,
        });
      } else {
        this.setState({
          primaryInput: {
            email: userResTo.result.email,
            mobile: userResTo.result.phone,
          },
        });
        this.setState({ modalShow: true });
        this.setState({
          notPrimaryError: `Please provide unique 
          ${!userResTo.result.email ? "Email Address" : ""} 
          ${!userResTo.result.email && !userResTo.result.phone ? " and " : ""}
          ${!userResTo.result.phone ? "Mobile Number" : ""}`,
        });
      }
    });
  };

  handleSubmit = (e: any) => {

debugger;
    e.preventDefault();
    this.setState({ submitted: true, errorMessage: "" });
    const form = e.target;
    
    const userName = form.elements.userNameVal.value;
    const password = form.elements.userPwd.value;
    let new_state = Object.assign({}, this.state);
    e.target.className += " was-validated";
    let userNameVal = new_state.userNameVal;
    let userPwd = new_state.userPwd;
    if (userName === "" || password === "") {
      if (userName === "") {
        userNameVal.isValid = false;
        userNameVal.message = "Username is required";
      }
      if (password === "") {
        userPwd.isValid = false;
        userPwd.message = "Password is required";
      }
    } else {
      
      this.setState({ loginButtonSpinner: true });
      let ssoparam = this.getURLParamsValues();
      User.loginNew(userName, password, "LoginPage",ssoparam?.nonce,ssoparam?.client_id ? true : false)
        .then(async (result: IUser | null) => { //IUser
       
          if (result) {

          
            this.setState({
              errorMessage: "",
              isBlocked: false,
              isCaptcha: false,
              captchaLoginButton: false,
              usId: {
                username: result.userName,
                tenantid: result.tenantID,
                uniqueid: result.user_UniqueID,
              },
            });

            let errorMessage = this.state.errorMessage;
            this.letModalVal();
            if (result.user_UniqueID === 0) {
              errorMessage = "Please enter the valid username/password.";
              if (this.state.isShowinvalidCaptcha === 2) {
                this.setState({ isBlocked: false, isCaptcha: true });
              } else {
                this.setState({
                  isShowinvalidCaptcha: this.state.isShowinvalidCaptcha + 1,
                });
              }
              this.setState({
                errorMessage: errorMessage,
                loginButtonSpinner: false,
              });
            }
            else if(result.pwdChangeStatus == 'req'){
              this.setState({changepasswordpopupshow:true});

            } else {
              if (result.message === "success") {
                User.ValidateUserStatus(
                  result.userName,
                  result.tenantID,
                  "loginModal"
                )
                  .then((userRes: any | null) => {
                   
                    localStorage.setItem("showIncompleteSlideOut", result?.showIncompleteSlideOut?.toString());
                    localStorage?.setItem("PopUpState", "");

                    if(ssoparam?.client_id && ssoparam?.isRedirectFromFreshDesk ==1 ){	
                      localStorage.setItem("expirationTimeForFreshDesk",  (localStorage.getItem("expirationTime") || ""));


                      Utils.setCookie("expirationTimeForFreshDesk", localStorage.getItem("expirationTime") ,1); 
                     // Utils.setCookie("tokenForFreshDesk", response.token ,1);
                      let url = ssoparam.redirect_uri + "?state="+ssoparam.state+"&id_token="+ localStorage.getItem("token");//Utils.getCookie("token");
                      let faqUrl = (result as any)?.lastClickedFreshdeskUrl;
                      // User.UpdateLastClickedFreshDeskUrl("").then((result)=> {
                      //   console.log(result);
                      //       });
                      Utils.setCookie("userName","" ,0);
                      Utils.setCookie("token", "" ,0);
                      Utils.setCookie("lastFreshdeskUrl", "" ,0);
                      if(!faqUrl) {
                        window.location.replace(url);
                      }
                      else {

                      var win = window.open(url,'windowname','toolbar=no,location=no,status=no,menubar=no,scrollbars=no,width=1,height=1,left=5,top=3');

                      setTimeout(()=>{	
                        if(!this.state.showAlert && faqUrl != null){
                          window.location.replace(faqUrl);
                          win?.close();

                        }
                      },10000);
                      }
                        
                      return;
                      resolve();	
                    }	


                    this.setState({
                      userName: result.userName,
                      isMerge: result.isMerge,
                      isLaborModule: result.isLaborModule,
                      currentUtcTime: result.currentUtcTime,
                      mergeURL: result.mergeURL,
                      unmergeURL: result.unmergeURL,
                      mergeToken: result.mergeToken,
                      user_UniqueID: result.user_UniqueID,
                      isValidateLogin: true,
                      loginButtonSpinner: false,
                      showIncompleteSlideOut: result.showIncompleteSlideOut,
                      isDuplicatePhone: result.isDuplicatePhone,
                      isDuplicateEmail: result.isDuplicateEmail,
                      primaryMethod: result.primaryMethod
                    });
                    //}
                    resolve();
                  })
                  .catch((error) => {
                    this.setState({ loginButtonSpinner: false });
                    toast.error(`Server Error: ${error}`, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      containerId: "Login",
                    });
                    reject();
                  });
              } 
          }

          }
          else{
            let errorMessage = this.state.errorMessage;
            errorMessage = "Please enter the valid username/password.";
              if (this.state.isShowinvalidCaptcha === 2) {
                this.setState({ isBlocked: false, isCaptcha: true });
              } else {
                this.setState({
                  isShowinvalidCaptcha: this.state.isShowinvalidCaptcha + 1,
                });
              }
              this.setState({
                errorMessage: errorMessage,
                loginButtonSpinner: false,
              });
              // toast.error(errorMessage, {
              //   position: toast.POSITION.BOTTOM_RIGHT,
              //   containerId: "Login",
              // });
          }
          resolve();
        })
        .catch((error) => {
          this.setState({ loginButtonSpinner: false });
          toast.error(`Server Error: ${error}`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "Login",
          });
          reject();
        });
    }
  };

  handleChangePassword  = (e: any) =>{
    User.ChangeOldPassword(this.state.userName, this.state.changepassword,0)
   // User.ChangeOldPassword(this.state.userName, this.state.changepassword,this.state.oldpassword)
    .then(async (result) => { 
     
           
        
        this.handleCloseModel();
this.setState({   loginButtonSpinner: false,});
     
      resolve();
    })
    .catch((error) => {
      this.setState({ loginButtonSpinner: false });
      toast.error(`Server Error: ${error}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "Login",
      });
      reject();
    });
    
  };

  handleChange = (e: any) => {
  
    const { name, value } = e.target;
    this.setState({ [name]: value });
    if (name === "userNameVal") {
      this.setState({
        errorMessage: "",
        isBlocked: false,
        userName:value,
      });
    }   
    else {
      if (value === "") {
        this.setState({ errorMessage: "" });
      }
    }
    this.setState({
      [e.target.name]: { value: e.target.value, isValid: !!e.target.value },
    });
  };
  handleConfirmPasswordChange = (e: any,name:any) => {
  if(name === "confirmchangepassword"){
    this.setState({ confirmchangepassword:e.target.value});
  } 
  else if(name === "changepassword"){
    this.setState({ changepassword:e.target.value});
  }
  else if(name === "oldpassword"){
    this.setState({ oldpassword:e.target.value});
  }
};

  requestADemo = () => {
    window.open("", "_blank");
  };

  verifyWithGUID = () => {
    let found = document.cookie
      .split(";")
      .filter((c) => c.trim().split("=")[0] === "");
    let cookieValue = found.length > 0 ? found[0].split("=")[1] : "";

    if (cookieValue === undefined) cookieValue = "";
    let currentURL = window.location.origin;
    if (cookieValue !== "") {
       
    } else {
      if (this.state.userNameVal.value === "") {
      
      } else {
        
      }
    }
  };

  closeSlideOut = () => {

  };

  render() {
    const {
      userNameVal,
      userPwd,
      isMarketing,
      mobileReq,
      primaryInputError,
      modalShow,
      modalHelpShow,
      primaryInput,
      primarymode,
    } = this.state;
    let ssoparam = this.getURLParamsValues();
    let Marketcss: any, loginMD: any, loginSpan: any, loginOffset: any;

    if (isMarketing) {
      loginMD = 7;
      loginSpan = 8;
      loginOffset = 2;
      Marketcss = "base-blue";
    } else {
      loginMD = 12;
      loginSpan = 4;
      loginOffset = 4;
      Marketcss = "base-blue d-none";
    }


   
    if (this.state.isValidateLogin) {
      
      let userName = this.state.userName;
      let mergeURL = this.state.mergeURL;
      let unmergeURL = this.state.unmergeURL;
      let mergeToken = this.state.mergeToken;
      let userRowData = {
        user_UniqueID: this.state.user_UniqueID,
        userName: this.state.userName,
        tenantid: this.state.tenantid,
        email: this.state.email,
        phone: this.state.phone,
        IsEmail: this.state.IsEmail
      };
      if (this.state.isMerge === "No") {
        window.location.href =
          unmergeURL +
          "?logInUser=" +
          userName +
          "&token=" +
          mergeToken +
          "&logInType=1";

        return "";
      } else if (
        this.state.isMerge === "Yes" &&
        this.state.isLaborModule === "No"
      ) {
        window.location.href =
          mergeURL +
          "?logInUser=" +
          userName +
          "&token=" +
          mergeToken +
          "&logInType=1";
        return "";
      } else if (this.state.isDeeplink) {
        return <Redirect
          to={{
            pathname: this.state.deeplink,
            state: {}
          }}
        />;
      } else if(this.state.client_id != "" && this.state.showAlert) {	
        return <></>;	
      } else if(this.state.client_id != ""){	
        return <h2>You are being redirected to the Knowledge Base. Please wait for a moment.</h2>;	
      }
      else if (ssoparam?.isRedirectFromFreshDesk ==1 ) {
        return <></>;
      }
      else {
        let _userRowData = {
          user_UniqueID: this.state.user_UniqueID,
          userName: this.state.userName,
          tenantid: this.state.tenantid,
          email: this.state.email,
          phone: this.state.phone,
          IsEmail: this.state.IsEmail,
          primaryMethod:this.state.primaryMethod
        };

        const PopUpState = {
          showIncompleteSlideOut: this.state.showIncompleteSlideOut,
          isChangeEHIDAccess: this.state.isChangeEHIDAccess,
          isChangeUserRole: this.state.isChangeUserRole,
          isPageCreateNewUser: this.state.isPageCreateNewUser,
          userData: _userRowData,
          isNewUserAdded: this.state?.isNewUserAdded,
          isDuplicatePhone: this.state?.isDuplicatePhone,
          isDuplicateEmail: this.state?.isDuplicateEmail,
          isMissingPhone: this.state?.isMissingPhone,
          isMissingEmail: this.state?.isMissingEmail,
          primaryMethod: this.state?.primaryMethod
        };



        localStorage.setItem("PopUpState", JSON.stringify(PopUpState));
        return <Redirect to={{
          pathname: '/Home',
          state: {
          }


        }}
        />;
      }
    } else {
      const Recaptcha = require("react-recaptcha");
      const callback = () => { };
      const verifyCallback = (response: any) => {
        this.setState({ captchaLoginButton: true });
      };


      const verifyCallback1 = (response: any) => {
        this.handleCloseHelp();
       
        this.setState({ needhelp: false });
      };

      const { isCaptcha, captchaLoginButton, isBlocked, modalHelpShow } = this.state;
      const isEnabled = isCaptcha && !captchaLoginButton;
      const isVisable = !isBlocked && isCaptcha && !modalHelpShow;

      let recaptchaInput;
      if (isVisable) {
        recaptchaInput = (
          <div>
            <Recaptcha
              id="captcha1"
              sitekey="6LfiUNoUAAAAAD_g22g7mvTdyrs2gW9XxQ8e14gH"
              render="explicit"
              verifyCallback={verifyCallback}
              onloadCallback={callback}
            />
          </div>
        );
      } else {
        recaptchaInput = <div></div>;
      }

      if(this.state.client_id != "" && this.state.showAlert)	
      {	
        this.showAlertMessage()	;
      }
      return (
        <>
         {this.state.client_id != "" && this.state.showAlert ? <></> : (this.state.client_id != "" && ssoparam.isRedirectFromFreshDesk !=1 ) &&		
           <h2>You are being redirected to the Knowledge Base. Please wait for a moment.</h2>	
          }
          {this.state.isMaintenance !== 1 && (this.state.client_id == ""  ||  ssoparam.isRedirectFromFreshDesk ==1) &&(
          <div className="login-bg">
         <div className="loginnew">
         <div className="form">
        {/* Passing handleSubmit parameter tohtml form onSubmit property */}
                                  <Form
                            className="frm-section"
                            name="loginForm"
                            onSubmit={(e: any) => this.handleSubmit(e)}
                          >
             <span>Cimmple</span>
           {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
             {/* <input
               type="email"
               name="email"
              //  onChange={handleChange}
              //  onBlur={handleBlur}
              //  value={values.email}
               placeholder="Enter email id / username"
               className="form-control inp_text"
               id="email"
             /> */}
                                       <Form.Control
                                type="text"
                                name="userNameVal"
                                value={userNameVal.value}
                                placeholder="Enter username"
                                onChange={(e: any) => this.handleChange(e)}
                               className="form-control inp_text"
                              />
             {/* If validation is not passed show errors */}
             {/* <p className="error">
               {errors.email && touched.email && errors.email}
             </p> */}
              {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
             {/* <input
               type="password"
               name="password"
              //  onChange={handleChange}
              //  onBlur={handleBlur}
              //  value={values.password}
               placeholder="Enter password"
               className="form-control"
             /> */}

                               <Form.Control
                                type="password"
                                name="userPwd"
                                value={userPwd.value}
                                placeholder="Enter Password"
                                onChange={(e: any) => this.handleChange(e)}
                               className="form-control inp_text"
                              />
              {/* If validation is not passed show errors */}
             {/* <p className="error">
               {errors.password && touched.password && errors.password}
             </p> */}
             {/* Click on submit button to submit the form */}
             {/* <button type="submit">Login</button> */}

                                    


                                           <Form.Group>{recaptchaInput}</Form.Group>
                              <Button
                                variant="primary"
                                // className={
                                //   this.state.loginButtonSpinner
                                //     ? "lg-btn action p-none"
                                //     : "lg-btn action"
                                // }
                                type="submit"
                                disabled={isEnabled || this.state.loginButtonSpinner}
                                style={{ fontFamily: "OpenSans-Semibold" }}
                                >
                                {this.state.loginButtonSpinner && (
                                  <Spinner
                                    className="mr-2"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                )}
                                
                                Login
                              </Button>
                                                         <div className="action txt-center">
                            {/* <Link             
                                                to="/login"
                              className="action-link"
                               >
                                Forgot your password?
                             </Link> */}
                           </div>
                              <span className="invalid-feedback">
                                 {userPwd.message}
                               </span>
                            <span className="invalid-feedback">
                                 {" "}
                                                                {this.state.errorMessage}
                              </span>

           </Form>
         </div>
       </div>
<div>



              {/* {this.state.changepasswordpopupshow && (
           
              <div className="react-confirm-alert-overlay confirm-modal undefined">
                <div className="react-confirm-alert">
                  <div className="react-confirm-alert-body" 
                  style={{ minWidth: "75%", width: "400px", maxWidth: "100%", height:"370px" }}
                  >
                  <div>  
                  <div className="d-flex">
                    <h1 style={{ paddingTop: "6px!important" }}> Change Password</h1>
                    <div className="header-popout ml-auto">
                 <button
                   type="button"
                   className="btn wht-bg cross"
                   onClick={this.handleCloseModel}
                 >
                   <svg
                     width="24"
                     height="24"
                     viewBox="0 0 24 24"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                   >
                     <path
                       d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                       fill="#84888C"
                     />
                   </svg>
                 </button>
               </div>
                  </div>
                  </div>
                    Please change your temporary password.
                   
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label className="font-semibold">
                      Change Password
                    </Form.Label>
            <Form.Control
              tabIndex={this.state.tabEnable}
              id="txtChangePassword"
              name="changepassword"
              type="password"
              placeholder="Enter Password"
              value={this.state.changepassword}
              onChange={(e: any) => this.handleConfirmPasswordChange(e,'changepassword')}
              //autoComplete="off"
              //disabled={disable}
              title=""
            />
              <br/>

              <Form.Label className="font-semibold">
                      Confirm Change Password
                    </Form.Label>
              
            <Form.Control
              tabIndex={this.state.tabEnable}
              id="txtConfirmChangePassword"
              type="password"
              name="confirmchangepassword"
              placeholder="Enter Password"
              value={this.state.confirmchangepassword}
              onChange={(e: any) => this.handleConfirmPasswordChange(e,'confirmchangepassword')}
              //autoComplete="off"
              //disabled={disable}
              title=""
            />            </Form.Group>
                       <br></br>
                       <div className="react-confirm-alert-button-group">
                      <button
                        type="button"
                        className="btn"
                      onClick={() => this.handleCloseModel()}
                      >
                        Cancel
                      </button>
                      
                      <div className="upload-link">
                        <button type="button" className="btn"
                        onClick={(e) => this.handleChangePassword(e)}
                        disabled = {this.state.confirmchangepassword !== this.state.changepassword || this.state.changepassword == ''}
                        >
                          Save
                        </button>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          
          )} */}
          {/* {this.state.changepasswordpopupshow && (
  <div className="react-confirm-alert-overlay confirm-modal undefined">
    <div className="react-confirm-alert">
      <div
        className="react-confirm-alert-body"
        style={{
          minWidth: "75%",
          width: "400px",
          maxWidth: "100%",
          height: "450px",
          fontFamily: "OpenSans-Semibold",
        }}
      >
        <div className="d-flex">
          <h1 style={{ paddingTop: "6px", marginBottom: "10px", fontSize:'20px'}}>Change Password</h1>
          <div className="header-popout ml-auto">
            <button
              type="button"
              className="btn wht-bg cross"
              onClick={this.handleCloseModel}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                  fill="#84888C"
                />
              </svg>
            </button>
          </div>
        </div>
        <div style={{ marginBottom: "20px" }}>Please change your temporary password.</div>

        <Form.Group controlId="oldPassword" style={{ marginBottom: "20px" }}>
          <Form.Label className="font-semibold" style={{ fontSize: "17px" }}>Old Password</Form.Label>
          <Form.Control
            tabIndex={this.state.tabEnable}
            id="txtOldPassword"
            name="oldpassword"
            type="password"
            placeholder="Enter Old Password"
            value={this.state.oldpassword}
            onChange={(e) => this.handleConfirmPasswordChange(e, 'oldpassword')}
          />
        </Form.Group>

        <Form.Group controlId="newPassword" style={{ marginBottom: "20px" }}>
          <Form.Label className="font-semibold" style={{ fontSize: "17px" }}>Change Password</Form.Label>
          <Form.Control
            tabIndex={this.state.tabEnable}
            id="txtChangePassword"
            name="changepassword"
            type="password"
            placeholder="Enter New Password"
            value={this.state.changepassword}
            onChange={(e) => this.handleConfirmPasswordChange(e, 'changepassword')}
          />
        </Form.Group>

        <Form.Group controlId="confirmNewPassword">
          <Form.Label className="font-semibold" style={{ fontSize: "17px" }}>Confirm New Password</Form.Label>
          <Form.Control
            tabIndex={this.state.tabEnable}
            id="txtConfirmChangePassword"
            type="password"
            name="confirmchangepassword"
            placeholder="Confirm New Password"
            value={this.state.confirmchangepassword}
            onChange={(e) => this.handleConfirmPasswordChange(e, 'confirmchangepassword')}
          />
        </Form.Group>

        <div className="react-confirm-alert-button-group" style={{ marginTop: "20px", display: "flex", justifyContent: "flex-start", gap: "10px" }}>
  <button
    type="button"
    className="btn"
    onClick={() => this.handleCloseModel()}
    style={{ alignSelf: "center" , fontSize:'13px'}}
  >
    Cancel
  </button>

  <button
    type="button"
    className="btn"
    onClick={(e) => this.handleChangePassword(e)}
    disabled={this.state.confirmchangepassword !== this.state.changepassword || this.state.changepassword === ''}
    style={{ alignSelf: "flex-start" ,fontSize:'13px' }} 
  >
    Save
  </button>
</div>

      

      </div>
    </div>
  </div>
)} */}
    {this.state.changepasswordpopupshow && (
  <div className="react-confirm-alert-overlay confirm-modal undefined">
    <div className="react-confirm-alert">
      <div
        className="react-confirm-alert-body"
        style={{
          minWidth: "75%",
          width: "400px",
          maxWidth: "100%",
          height: "auto",
          fontFamily: "OpenSans-Semibold",
        }}
      >
        <div className="text-end align-items-end">
         
          <div className="header-popout">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={this.handleCloseModel}
                style={{ cursor: 'pointer' }}
                >
                <path
                  d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                  fill="#84888C"
                />
              </svg>
          </div>
        </div>

        <div className="text-center">
            <h1 style={{ paddingTop: "6px", marginBottom: "40px", fontSize: '20px' }}>
              Change Password
            </h1>
          </div>
          {this.state.success && (
  <div style={{ margin: "20px 0", padding: "10px", border: "1px solid green", color: "green", backgroundColor: "#e6ffe6" }}>
    {this.state.success}
  </div>
)}

{this.state.error && (
  <div style={{ margin: "20px 0", padding: "10px", border: "1px solid red", color: "red", backgroundColor: "#ffe6e6" }}>
    {this.state.error}
  </div>
)}


        {/* <Form.Group controlId="oldPassword" style={{ marginBottom: "20px" }}>
          <Form.Label className="font-semibold" style={{ fontSize: "15px" }}>
            <span style={{ color: 'red' }}>*</span> Current Password
          </Form.Label>
          <Form.Control
            tabIndex={this.state.tabEnable}
            id="txtOldPassword"
            name="oldpassword"
            type="password"
            placeholder="Enter Current Password"
            value={this.state.oldpassword}
            onChange={(e) => this.handleConfirmPasswordChange(e, 'oldpassword')}
            style={{ backgroundColor: '#f0f0f0' }}
          />
        </Form.Group> */}

        <Form.Group controlId="newPassword" style={{ marginBottom: "20px" }}>
          <Form.Label className="font-semibold" style={{ fontSize: "15px" }}>
          <span style={{ color: 'red' }}>*</span> Password
          </Form.Label>
          <Form.Control
            tabIndex={this.state.tabEnable}
            id="txtChangePassword"
            name="changepassword"
            type="password"
            placeholder="Enter Password"
            value={this.state.changepassword}
            onChange={(e) => this.handleConfirmPasswordChange(e, 'changepassword')}
            style={{ backgroundColor: '#f0f0f0' }}
          />
        </Form.Group>

        <Form.Group controlId="confirmNewPassword">
          <Form.Label className="font-semibold" style={{ fontSize: "15px" }}>
          <span style={{ color: 'red' }}>*</span> Confirm Password
          </Form.Label>
          <Form.Control
            tabIndex={this.state.tabEnable}
            id="txtConfirmChangePassword"
            type="password"
            name="confirmchangepassword"
            placeholder="Confirm Password"
            value={this.state.confirmchangepassword}
            onChange={(e) => this.handleConfirmPasswordChange(e, 'confirmchangepassword')}
            style={{ backgroundColor: '#f0f0f0' }}
          />
        </Form.Group>

       
        <div style={{ marginTop: "20px"}}>
        <button
          type="button"
          className="btn btn-primary"
          onClick={(e) => this.handleChangePassword(e)}
          disabled={this.state.confirmchangepassword !== this.state.changepassword || this.state.changepassword === ''}
          style={{ width: "100%", marginTop: "20px", fontSize: '15px' }}
        >
          Update Password
        </button>
        </div>

        <br></br>
        <br></br>
        <br></br>
      </div>
    </div>
  </div>
)}
</div>

       </div>


//          <div className="login-bg">
//               <ToastContainer autoClose={3000} containerId={"Login"} />
//               <Row>
//                 <Col
//                   md={{ span: loginMD }}
//                   className={
//                     this.state.isMarketing
//                       ? "base-white login marketing"
//                       : "base-white login"
//                   }
//                 >
//                   <Row className="d-flex align-items-center login-container login">
//                     <Col md={{ span: loginSpan, offset: loginOffset }}>
//                       <div className="brand-img">
//                         <a href="https://www.cimmple.net" target="_blank">
                          
//                         </a>
//                       </div>
//                       <Card>
//                         <Card.Body>
//                           <div className="card-heading txt-center">
//                             Sign In
                            
//                           </div>
//                           <Form
//                             className="frm-section"
//                             name="loginForm"
//                             onSubmit={(e: any) => this.handleSubmit(e)}
//                           >
//                             <Form.Group controlId="formBasicEmail">
//                               <Form.Label>Username</Form.Label>
//                               <Form.Control
//                                 type="text"
//                                 name="userNameVal"
//                                 value={userNameVal.value}
//                                 placeholder="Enter username"
//                                 onChange={(e: any) => this.handleChange(e)}
//                                 className={
//                                   this.state.userNameVal.isValid
//                                     ? "valid"
//                                     : "invalid"
//                                 }
//                               />
//                               <span className="invalid-feedback">
//                                 {userNameVal.message}
//                               </span>
//                             </Form.Group>
//                             <Form.Group controlId="formBasicPassword">
//                               <Form.Label>Password</Form.Label>
//                               <Form.Control
//                                 type="password"
//                                 name="userPwd"
//                                 value={userPwd.value}
//                                 placeholder="Enter Password"
//                                 onChange={(e: any) => this.handleChange(e)}
//                                 className={
//                                   this.state.userPwd.isValid
//                                     ? "valid"
//                                     : "invalid"
//                                 }
//                               />
//                               <span className="invalid-feedback">
//                                 {userPwd.message}
//                               </span>
//                               <span className="invalid-feedback">
//                                 {" "}
//                                 {this.state.errorMessage}
//                               </span>
//                             </Form.Group>
//                             <div style={{ marginTop: "30px" }}>
//                               <Form.Group>{recaptchaInput}</Form.Group>
//                               <Button
//                                 variant="primary"
//                                 className={
//                                   this.state.loginButtonSpinner
//                                     ? "lg-btn action p-none"
//                                     : "lg-btn action"
//                                 }
//                                 type="submit"
//                                 disabled={isEnabled || this.state.loginButtonSpinner}
//                               >
//                                 {this.state.loginButtonSpinner && (
//                                   <Spinner
//                                     className="mr-2"
//                                     as="span"
//                                     animation="border"
//                                     size="sm"
//                                     role="status"
//                                     aria-hidden="true"
//                                   />
//                                 )}
                                
//                                 Login
//                               </Button>
//                             </div>
//                             <div className="action txt-center">
//                               <Link
//                                 to="/login"
//                                 className="action-link"
//                               >
//                                 Forgot your password?
//                               </Link>
//                             </div>


                            
//                             { this.state?.idleLogOutMessage &&
//                             <div className="pt-2 action txt-center">
//                             You have been logged out due to inactivity
//                             </div>
//     }
//                           </Form>
//                         </Card.Body>
//                       </Card>
//                       {(this.state.needhelp &&
//                         <div
//                           className="not-customer-txt text-center help"
//                           style={{ paddingTop: 12 }}
//                         >
//                           Need help?{" "}
//                           <Button variant="primary" >
//                             Contact support
//                           </Button>
//                         </div>
//                       )}
//                     </Col>
//                   </Row>
//                   <Row>
//                     <Col md={{ span: loginSpan, offset: loginOffset }}>
                    
//                     </Col>
//                   </Row>
//                 </Col>
//                 <Col md={{ span: 5 }} className={Marketcss}>
//                   <div className="bg-opacity"></div>
//                   <Row>
//                     <Col>
//                       <div className="login-body d-flex flex-column">
//                         <div className="title">Marketing Message</div>
//                         <div className="desc">
// {" "}
//                         </div>
//                         <div className="demo-link">
//                           <Button
//                             variant="primary"
//                             type="submit"
//                             className="btn-outline-primary"
//                             onClick={this.requestADemo}
//                           >
//                             Learn More
//                           </Button>
//                         </div>
//                       </div>
//                     </Col>
//                   </Row>
//                 </Col>
//               </Row>

//               {/*---Modal---*/}
//               <Modal
//                 show={modalShow}
//                 onHide={this.handleClose.bind(this)}
//                 aria-labelledby="contained-modal-title-vcenter"
//                 centered
//                 className="ifcusModal"
//                 keyboard={false}
//                 backdrop="static"
//               >
//                 <Modal.Header closeButton>
//                   <Modal.Title>
//                     Please provide primary contact information
//                   </Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                   {this.state.notPrimaryError ? (
//                     <p className="text-danger text-center my-2">
//                       <strong>{this.state.notPrimaryError}</strong>
//                     </p>
//                   ) : null}
//                   <div className="hasPadding">
//                     <div className="frm-section">
//                       <Form.Group>
//                         <Form.Label>Primary Contact</Form.Label>
//                         <Form.Control
//                           className="noReadOnly"
//                           as="select"
//                           onChange={this.setPrimaryContact}
//                           title="Select Primary Contact"
//                         >
//                           {primarymode.map((items: any, index: any) => (
//                             <option
//                               selected={items.isPrimary}
//                               key={index}
//                               value={items.value}
//                             >
//                               {items.label}
//                             </option>
//                           ))}
//                         </Form.Control>
//                       </Form.Group>
//                     </div>

//                     <Form
//                       onSubmit={this.submitForm.bind(this)}
//                       className="frm-section was-validated"
//                     >
//                       <Form.Group>
//                         <Form.Label>
//                           Enter Email Address{" "}
//                           <em className="text-danger">Required</em>
//                         </Form.Label>
//                         <Form.Control
//                           title="Enter email address"
//                           type="email"
//                           name="email"
//                           pattern="[a-Z0-9._%+-]+@[a-Z0-9.-]+\.[a-Z]{2,}$"
//                           className="form-control"
//                           value={primaryInput.email}
//                           onChange={this.piChange}
//                           autoComplete="off"
//                           required
//                           placeholder="Enter email address"
//                         />
//                         <span className="invalid-feedback">
//                           {primaryInputError.emailError}
//                         </span>
//                       </Form.Group>
//                       <Form.Group>
//                         <Form.Label>
//                           Enter Mobile Number{" "}
//                           <em className={mobileReq.style}>
//                             {mobileReq.message}
//                           </em>
//                         </Form.Label>
//                         <Form.Control
//                           type="text"
//                           title="Enter mobile number"
//                           className="form-control"
//                           name="mobile"
//                           value={primaryInput.mobile}
//                           pattern="^(0|[1-9][0-9]*)$"
//                           onChange={this.piChange}
//                           maxLength={10}
//                           minLength={10}
//                           autoComplete="off"
//                           required={mobileReq.required}
//                           placeholder="Enter mobile number"
//                         />
//                         <span className="invalid-feedback">
//                           {primaryInputError.mobileError}
//                         </span>
//                       </Form.Group>
//                       <Form.Group className="text-center">
//                         <Button
//                           type="submit"
//                           title="Update"
//                           disabled={this.state.submitDisable}
//                           variant="primary"
//                         >
//                           <FontAwesomeIcon icon={faSave} /> Update
//                         </Button>
//                         &nbsp;
//                         <Button
//                           type="reset"
//                           onClick={this.handleClose.bind(this)}
//                           variant="danger"
//                           title="Close"
//                         >
//                           <FontAwesomeIcon icon={faTimes} /> Close
//                         </Button>
//                       </Form.Group>
//                     </Form>
//                   </div>
//                 </Modal.Body>
//               </Modal>
//               {/*---Modal---*/}
//               <Modal className="help-captcha" show={modalHelpShow} onHide={this.handleCloseHelp.bind(this)} centered>
//                 <Modal.Header closeButton>
//                   <Modal.Title>Select Captcha</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                   <Recaptcha
//                     id="captcha2"
//                     sitekey="6LfiUNoUAAAAAD_g22g7mvTdyrs2gW9XxQ8e14gH"
//                     render="explicit"
//                     verifyCallback={verifyCallback1}
//                     onloadCallback={callback}
//                   />
//                 </Modal.Body>
             
//               </Modal>
//               {this.state.changepasswordpopupshow && (
//             <div className="d-flex align-items-center pos-fxd2 index-1000">
//               <div className="react-confirm-alert-overlay confirm-modal undefined">
//                 <div className="react-confirm-alert">
//                   <div className="react-confirm-alert-body">
//                   <div>  
//                   <div className="d-flex">
//                     <h1 style={{ paddingTop: "6px!important" }}> Change Password</h1>
//                     <div className="header-popout ml-auto">
//                  <button
//                    type="button"
//                    className="btn wht-bg cross"
//                    onClick={this.handleCloseModel}
//                  >
//                    <svg
//                      width="24"
//                      height="24"
//                      viewBox="0 0 24 24"
//                      fill="none"
//                      xmlns="http://www.w3.org/2000/svg"
//                    >
//                      <path
//                        d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
//                        fill="#84888C"
//                      />
//                    </svg>
//                  </button>
//                </div>
//                   </div>
//                   </div>
//                     Please change your temporary password.
                   
//                     <Form.Group controlId="exampleForm.ControlTextarea1">
//                     <Form.Label className="font-semibold">
//                       Change Password
//                     </Form.Label>
//             <Form.Control
//               tabIndex={this.state.tabEnable}
//               id="txtChangePassword"
//               name="changepassword"
//               type="password"
//               placeholder="Enter Password"
//               value={this.state.changepassword}
//               onChange={(e: any) => this.handleConfirmPasswordChange(e,'changepassword')}
//               //autoComplete="off"
//               //disabled={disable}
//               title=""
//             />
//               <br/>

//               <Form.Label className="font-semibold">
//                       Confirm Change Password
//                     </Form.Label>
              
//             <Form.Control
//               tabIndex={this.state.tabEnable}
//               id="txtConfirmChangePassword"
//               type="password"
//               name="confirmchangepassword"
//               placeholder="Enter Password"
//               value={this.state.confirmchangepassword}
//               onChange={(e: any) => this.handleConfirmPasswordChange(e,'confirmchangepassword')}
//               //autoComplete="off"
//               //disabled={disable}
//               title=""
//             />            </Form.Group>
//                        <br></br>
//                        <div className="react-confirm-alert-button-group">
//                       <button
//                         type="button"
//                         className="btn"
//                       onClick={() => this.handleCloseModel()}
//                       >
//                         Cancel
//                       </button>
                      
//                       <div className="upload-link">
//                         <button type="button" className="btn"
//                         onClick={(e) => this.handleChangePassword(e)}
//                         disabled = {this.state.confirmchangepassword !== this.state.changepassword || this.state.changepassword == ''}
//                         >
//                           Save
//                         </button>
                        
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           )}
//             </div>
          )}
        </>
      );
    }
  }
}
export const Login = LoginComponent;

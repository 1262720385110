import * as React from "react";
import { Container, Dropdown, Accordion, Card, Button, Form } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import { CustomerService } from "../Common/Services/CustomerService";
import { Quotationslideout } from './Quotationslideout';
import { ShippingSlideout } from './ShippingSlideout';
import { Utils } from "../Common/Utilis";
import _ from "lodash";
import paginationFactory from 'react-bootstrap-table2-paginator';
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import createDOMPurify from "dompurify";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { FaCircle, FaEllipsis } from "react-icons/fa6";
import moment from "moment";
import { SingleDropdownListWithoutSearch } from "../Common/Components/SingleDropdownListWithoutSearch";
//import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import chevronRight from "../Common/Assets/Images/icons/bx-chevron-right.svg";
import { Customerorderslideout } from './Customerorderslideout';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
const { SearchBar } = Search;
const DOMPurify = createDOMPurify(window);
export class Quotations extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.bindfilter = this.bindfilter.bind(this);
    this.state = {
      isstatus: true,
      isorderdate: true,
      isTotalAmount:true,
      iscustomername: true,
      iscustomercode: true,
      iscustomerpo: true,
      htmlItem: [],
      htmlErrorItem: [],
      htmlWarningItem: [],
      searcResult: [],
      shippingResult: [],
      shippedResult: [],
      orderid: 0,
      shipmentId: 0,
      jobIds: [],
      selectedcolumn: "All",
      selectOptions: [
        {

          id: 'All', name: 'All'
        },
        {

          id: 'Quotation No.', name: 'Quotation No.'
        },
        { id: 'Customer Code', name: 'Customer Code' },
        { id: 'Customer Name', name: 'Customer Name' },
        { id: 'Quotation Date', name: 'Quotation Date' },
        { id: 'Total Amount', name: 'Total Amount' },
        { id: 'Status', name: 'Status' },

      ],
    };
  }

  componentDidMount() {
    this.getCustomerItems();
    
  }

  getCustomerItems() {
    CustomerService.GetQuotationOrder()
      .then(async (result: any[] | null) => {
        if (result != null) {
         // console.log("getresults",result);
          this.setState({ searcResult: result }, () => {
            this.bindfilter();
          });
        }
        resolve();
      })
      .catch((err: any) => {
        toast.error(`Server Error, ${err}`);
        reject();
      });
  }

 


  shownewslideout = () => {
    this.setState({ showSlideout: true, orderid: 0, isSpinner: false, });
  };

  showslideout = () => {
    this.setState({ showSlideout: true, isSpinner: false, });
  };


  showShippingslideout = (row: any) => {

    if (this.state.shippingResult.filter((x) => x.selected === true && x.orderID === row.orderID).length === 0) {
      // alert('Please select jobs to ship under this order');
      // return;

      toast.error('Please select jobs to ship under this order');
      reject();
      return;
    }
    this.setState({
      showShippingSlideout: true, orderid: row.orderID,
      shipmentId: 0,
      jobIds: this.state.shippingResult.filter(x => x.selected === true && x.orderID === row.orderID).map(r => r.jobId),
      isSpinner: false,
    });
  };
  
  closeCustomerSlideOut = () => {
    this.setState({ showCustomerSlideout: false, isSpinner: false, });
    this.getCustomerItems();
 
  };
  closeSlideOut = () => {
    this.setState({ showSlideout: false, isSpinner: false, });
    this.getCustomerItems();
 
  };

  closeShippingSlideOut = () => {
    this.setState({ showShippingSlideout: false, isSpinner: false, });
    this.getCustomerItems();
   
  };

  rowEvents = {

    onClick: (e: any, row: any, rowIndex: any) => {

      debugger;
      if (row.rowType === undefined) {

        if(e.target.className=="badge badge-pill badge-primary color-green")
        {

          this.setState({
            showCustomerSlideout: true, customerorderid: row.customerOrderid, shipmentId: 0, isSpinner: false,
          });
        }
        else
        {

        if (row.shipmentNo !== undefined && row.shipmentNo.length > 0) {
          this.setState({
            showShippingSlideout: true, orderid: row.orderId,
            shipmentId: row.id,
            isSpinner: false,
          });

        }

        else
          this.setState({
            showSlideout: true, orderid: row.orderID, shipmentId: 0, isSpinner: false,
          });
        }
      }

    },
  };

  bindfilter = () => {
    let selectOptions = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.selectOptions))
    );

    this.setState({ selectOptions: selectOptions });

  };

  handleSelectedItem1 = (control: any, id: any) => {
    let searcResult1 = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.searcResult))
    );

    let chkisstatus = id == "Status" ? true : false;
    let chkisorderdate = id == "Quotation Date" ? true : false;
    let chkiscustomername = id == "Customer Name" ? true : false;
    let chkiscustomercode = id == "Customer Code" ? true : false;
    let chkiscustomerpo = id == "Quotation No." ? true : false;
    let chkisTotalAmount = id == "Total Amount" ? true : false;


    if (id == "All") {
      chkisstatus = true;
      chkisorderdate = true;
      chkiscustomername = true;
      chkiscustomercode = true;
      chkiscustomerpo = true;
      chkisTotalAmount=true;
    }


    this.setState({
      searcResult: [], selectedcolumn: id, isstatus: chkisstatus, isorderdate: chkisorderdate,
      iscustomername: chkiscustomername, iscustomercode: chkiscustomercode, iscustomerpo: chkiscustomerpo, isTotalAmount:chkisTotalAmount,
    },
      () => {
        this.setState({ searcResult: searcResult1 });

      }


    );

  };


  handleOnSelect = (row, isSelect) => {


    let shippingResult = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.shippingResult))
    );

    let listItem = shippingResult.filter(
      (x) => x.rowId === row.rowId
    );

    listItem[0].selected = isSelect;

    this.setState({ shippingResult: shippingResult }, () => { this.setState({ shippingResult: this.state.shippingResult }); });



  };

  handleOnSelectAll = (isSelect, rows) => {

    const ids = rows.map(r => r.rowId);

    let shippingResult = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.shippingResult))
    );

    let listItems = shippingResult.filter(
      (x) => ids.includes(x.rowId)
    );

    if (listItems.length > 0) {
      listItems.forEach((element: any, index: any) => {
        element.selected = isSelect;
      });
    };

    this.setState({ shippingResult: shippingResult });
  };

  render() {

    const columns = [

      {
        dataField: 'customerPoNumber',
        text: 'Quotation No.',
        sort: true,
        // headerStyle: { minWidth: '100px', width: '100px' },
        searchable: this.state.iscustomerpo,
        className: "text-truncate",
      },
      {
        dataField: 'customercode',
        text: 'Customer Code',
        sort: true,
        // headerStyle: { minWidth: "100px", width: '100px' },
        className: "text-truncate",

        searchable: this.state.iscustomercode,
      },
      {
        dataField: 'customerName',
        text: 'Customer Name',
        sort: true,
        searchable: this.state.iscustomername,
        className: "text-truncate",
        // headerStyle: { minWidth: "200px", Width: "200px" },

      },
      {
        dataField: 'orderDate',
        text: 'Quotation Date',
        sort: true,

        // headerStyle: { minWidth: "200px" },
        searchable: this.state.isorderdate,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {moment(row.orderDate).format("MM/DD/YYYY")}
            </EllipsisWithTooltip>
          );
        },
      },

      // ,
      {
        dataField: 'totalAmount',
        text: 'Total Amount',
        headerClasses: 'text-start pe-4',
        classes: 'text-start pe-4 divgreen',
        // headerStyle: { minWidth: "200px" },
        sort: true,
        searchable: this.state.isTotalAmount,
        //headerStyle: { width: '300px' },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {Utils.currencyFormat(row.totalAmount)}
            </EllipsisWithTooltip>
          );
        },
      },


      {
        dataField: 'status',
        text: 'Status',
        sort: true,
        headerStyle: { minWidth: "100px" , paddingLeft:"6"},
        searchable: this.state.isstatus,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return ( 
            <>

            <EllipsisWithTooltip placement="bottom" style={{paddingLeft:"6%"}} >
                
               <span className={row.status==null ? "badge badge-pill badge-primary color-orange" :  "badge badge-pill badge-primary color-green"}> {row.status==null? "Active" : row.status} </span>
              </EllipsisWithTooltip>
              </>
          );
      },
      },


    


    ];

   

  
    const selectOptions = [{
      id: 0, column: 'Quotation No.'
    },
    { id: 1, column: 'Customer Code' },
    { id: 2, column: 'Customer Name' },
    { id: 3, column: 'Quotation Date' },
    { id: 4, column: 'Status' },

    ];

    const {

      showSlideout,
      showShippingSlideout,
      showCustomerSlideout,

    } = this.state;


    const expandSplitRow = {
      onlyOneExpanding: false,
      parentClassName: "parent-expand-foo",
      renderer: (row: any, rowIndex: any) => renderItemTableChild(row, rowIndex),
      showExpandColumn: true,
      expandByColumnOnly: true,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <div></div>;
        }
        return <div></div>;
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };
    const whiteBgFormatter = (column, colIndex) => {
      return <div className="white-bg"></div>;
    };

    const renderItemTableChild = (mainrow, rowIndex) => {

      let splitDetail: any = [];
      splitDetail = this.state.shippingResult.filter(
        (x) => x.orderID === mainrow.orderID && x.rowType === 2
      );

      const columnCOAChild = [

        {
          dataField: "jobNo",
          text: "Job No.",
          classes: "expanding-bar",
          headerStyle: { minWidth: '100px', width: '100px' },
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (

              <EllipsisWithTooltip placement="bottom">
                {row.jobNo}
              </EllipsisWithTooltip>
            );
          },
        },
        {
          dataField: "totalQty",
          text: "Total Qty",
          hidden: false,
          classes: "expanding-bar",
          headerStyle: { minWidth: '100px', width: '100px' },
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
                {row.totalQty}
              </EllipsisWithTooltip>
            );
          },
        },
        {
          dataField: "shippingQty",
          text: "Completed Qty",
          hidden: false,
          classes: "expanding-bar",
          headerStyle: { minWidth: '100px', width: '100px' },
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
                {row.shippingQty}
              </EllipsisWithTooltip>
            );
          },
        },
        {
          dataField: "manualTracking",
          text: "Tracking",
          hidden: false,
          classes: "expanding-bar",
          headerStyle: { minWidth: '100px', width: '100px' },
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
                {row.manualTracking ? 'Manual' : 'Tracked'}
              </EllipsisWithTooltip>
            );
          },
        },
        {
          dataField: "rowId",
          text: "rowId",
          hidden: true,
          classes: "expanding-bar",
          headerStyle: { minWidth: '500px', width: '500px' },
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
                {row.rowId}
              </EllipsisWithTooltip>
            );
          },
        },
        {
          dataField: "",
          text: "",
          classes: "expanding-bar",
          headerClasses: "blankLastChildofChild",
          headerStyle: { minWidth: '500px', width: '500px' },
        },



      ];

      return (
        <>
          <div className='px-4 p-4'>
            <BootstrapTable
              id="tblCOAchild"
              keyField="rowId"
              data={splitDetail}
              columns={columnCOAChild}
              //rowEvents={this.rowEvents}
              selectRow={{
                mode: 'checkbox', clickToSelect: false, hideSelectAll: false,
                selected: splitDetail.filter((x) => x.selected === true).map(r => r.rowId),
                onSelect: this.handleOnSelect,
                onSelectAll: this.handleOnSelectAll,
                hideSelectColumn: false,
                selectColumnStyle: {
                  backgroundColor: '#5c82ab08'
                },
              }}

            />
          </div>
        </>
      );
    };
    const customTotal = (from, to, size) => (
      <span className="text-muted">
        Showing {from} to {to} of {size} entries
      </span>
    );
    // const options = {
    //   // pageStartIndex: 0,
    //   sizePerPageList: [{
    //     text: '10', value: 10
    //   }, {
    //     text: '25', value: 25
    //   }, {
    //     text: 'All', value: this.state.searcResult.length
    //   }],
    //   showTotal: true,
    //   paginationTotalRenderer: customTotal,
    //   sizePerPage: 10,
    //   // hideSizePerPage: false,
    //   // hidePageListOnlyOnePage: false,
    // };
    const options = {

      showTotal: true,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '10', value: 10
      },
      {
        text: '25', value: 25
      },
      {
        text: '50', value: 50
      },

      {
        text: 'All', value: this.state.searcResult.length
      }] // A numeric array is also available. the purpose of above example is custom the text
    };

    return (
      <>
        <React.Fragment>






          <Container fluid className="body-sec" >
            <section>

              <div className="page-heading d-flex">

                Quotations
                <div className="action-group d-flex flex-row ml-auto">
                  <Button onClick={this.shownewslideout}
                  // className="btn-primary wht-bg link-btn mr-auto"
                  >Add Quotation</Button>
                </div>
              </div>


              <Tab.Container transition={false} defaultActiveKey="first" >

                <Tab.Content>
                  <Tab.Pane eventKey="first">

                    <ToolkitProvider
                      keyField="id"
                      data={this.state.searcResult}
                      columns={columns}
                      //className="table table-striped table-borderless align-middle"
                      search
                    >
                      {
                        props => (


                          <div >
                            <section className="d-flex flex-wrap gap-3 pb-2">
                              <div className="position-relative">
                                <SearchBar {...props.searchProps} />
                              </div>

                              <div
                              //className="input-group w-auto"
                              >
                                <Form.Label>
                                  <SingleDropdownListWithoutSearch

                                    itemList={this.state.selectOptions}
                                    handleSelectedItem={this.handleSelectedItem1.bind(this, 1)}
                                    defaultItem={this.state.selectedcolumn}
                                    defaultText={"Select Filter"}
                                    defaultName={this.state.selectedcolumn}
                                    controlID="1"
                                    id={"ddlselected"}
                                    key="id"
                                  /></Form.Label>
                              </div>

                            </section>
                            <section className="pb-3">
                              <div className="table-responsive divbottom"  >
                                <div className="ml-auto transactions-list">
                                  <BootstrapTable
                                    {...props.baseProps}
                                    rowEvents={this.rowEvents}
                                    pagination={paginationFactory(options)}

                                  />
                                </div>
                              </div>
                            </section>
                          </div>

                        )
                      }
                    </ToolkitProvider>



                  </Tab.Pane>
                   
             
                </Tab.Content>

              </Tab.Container>


              {(showCustomerSlideout &&
                <Customerorderslideout
                  closeSlideOut={this.closeCustomerSlideOut}
                  orderid={this.state.customerorderid}
                
                >


                </Customerorderslideout>
              )}

              {(showSlideout &&
                <Quotationslideout
                  closeSlideOut={this.closeSlideOut}
                  orderid={this.state.orderid}
                
                >
                </Quotationslideout>
              )}



              {(showShippingSlideout &&
                <ShippingSlideout
                  closeSlideOut={this.closeShippingSlideOut}
                  orderid={this.state.orderid}
                  jobids={this.state.jobIds}
                  shipmentId={this.state.shipmentId}
                >


                </ShippingSlideout>
              )}
            </section>
          </Container>



        </React.Fragment>
      </>
    );
  }
}
import * as React from "react";
import { Container, Dropdown, Spinner, Form, Button ,OverlayTrigger,Tooltip} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import { PartTemplateService } from "../../Common/Services/PartTemplateService";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { PartTemplateSlideout } from './PartTemplateSlideout';
import _ from "lodash";
import { SingleDropdownListWithoutSearch } from "../../Common/Components/SingleDropdownListWithoutSearch";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
const { SearchBar } = Search;

import { ConfirmationModal } from "../../Common/Components/ConfirmationModal";
export class PartTemplate extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {

      htmlItem: [],
      htmlErrorItem: [],
      htmlWarningItem: [],
      searcResult: [],
      templateid: 0,
      selectedcolumn: "All",
      selectOptions: [
        { id: 'All', name: 'All'},
        { id: 'Sr No.', name: 'Sr No.' },
        { id: 'Template Name', name: 'Template Name' },
        { id: 'Status', name: 'Status' },
      ],
    };


  }
  componentDidMount() {
    let requestlist = { ...this.state.requestlist };
    this.getPartTemplate(requestlist);
  }

  ishandleDeleteRow(row: any, mode: any) {
    if (mode) {
    
      let searcResult = Object.assign([], JSON.parse(JSON.stringify(this.state.searcResult)));
      this.deleteTemplate(row.id);
      this.setState({ searcResult: [] }, () => {

       
        const searcResultNewc = searcResult.filter(item => item.id !== row.id);

        this.setState({
          searcResult: searcResultNewc
          , isStateChanged: 1
        },
          () => {


          });
      });
    }
  }
  handleSelectedItem1 = (control: any, id: any) => {
    let searcResult1 = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.searcResult))
    );

    let chkistemplatename = id == "Template Name" ? true : false;
    let chkisactive = id == "Status" ? true : false;
    let chkissrno = id == "Sr No." ? true : false;

    if (id == "All") {
      chkistemplatename = true;
      chkisactive = true;
      chkissrno=true;
    }


    this.setState({
      searcResult: [], selectedcolumn: id,  isactive: chkisactive,
      istemplatename: chkistemplatename,issrno:chkissrno,
    },
      () => {
        this.setState({ searcResult: searcResult1 });    
      }


    );

  };
  
  deleteTemplate = (id:any) => {
    let request: any = {};
    request.id = id;
    this.setState({ isSpinner: true });

    PartTemplateService.DeleteTMaster(request)
      .then(async (result: any | null) => {
        toast.success("Template has been deleted.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "OrderSlideout",
        });
        this.setState({ isSpinner: false, });
        
        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ isSpinner: false, });
      });

  };


  getPartTemplate = (requestlist: any) => {

    PartTemplateService.GetPartTemplatelist(requestlist)
      .then(async (result: any[] | null) => {

        if (result != null) {
          result.forEach((element, idx) => {
            element.srno = idx + 1;
          });
          
          this.setState({ searcResult: result }, () => {

          });
        }
        resolve();
      })
      .catch((err: any) => {
        toast.error(`Server Error, ${err}`);
        reject();
      });
  };

  shownewslideout = () => {
    this.setState({ showSlideout: true, templateid: 0 });
  };

  closeSlideOut = () => {
    
    this.setState({ showSlideout: false });
    let requestlist = { ...this.state.requestlist };
    this.getPartTemplate(requestlist);
  };
  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      
      if(e.target.tagName === 'TD')
      {
      this.setState({
        showSlideout: true, templateid: row.id
      });
     }

    },
  };


  render() {
    const customTotal = (from, to, size) => (
      <span className="text-muted">
        Showing {from} to {to} of {size} entries
      </span>
    );
    const options = {

      showTotal: true,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '10', value: 10
      },
      {
        text: '25', value: 25
      },
      {
        text: '50', value: 50
      },

      {
        text: 'All', value: this.state.searcResult.length
      }] // A numeric array is also available. the purpose of above example is custom the text
    };


    const {

      showSlideout,
      showReceivableslideout,

    } = this.state;

    const columns = [
       {
        searchable: this.state.issrno,
         dataField: 'srno',
         text: 'Sr No.',
         sort: true,
       },     
    
      {
        searchable: this.state.istemplatename,
        dataField: 'tname',
        text: 'Template Name',
        sort: true,
      },
      {
        
        searchable: this.state.isactive,
        dataField: 'status',
        text: 'Status',
        sort: true,
      },
      

    {
      dataField: 'Attachment',
      text: '',
      hidden:false,
      //headerFormatter: this.iconFormatter,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let confirmModalContent = [
          {
            title: "Delete Row",
            desc: "Are you sure you want to delete?",
            cancleAction: "Cancel",
            ActionTrue: "Delete",
          },
        ];
        if (this.state.searcResult.length === 1 || row.tname.toLowerCase() == 'custom') {
          return (
            <div></div>
          );
        }


        else {
          return (
            <Dropdown className="more-action confirmation-modal"  tabIndex={this.state.tabDisable}>
              <Dropdown.Toggle className="btn-outline-primary btn btn-primary more fa-trash" id="dropdown-more-delete" tabIndex={this.state.tabDisable}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 5.83329C4.53917 5.83329 4.16667 5.83329 4.16667 5.83329V16.6666C4.16667 17.5866 4.91333 18.3333 5.83333 18.3333H14.1667C15.0867 18.3333 15.8333 17.5866 15.8333 16.6666V5.83329C15.8333 5.83329 15.4608 5.83329 15 5.83329H5ZM8.33333 15.8333H6.66667V8.33329H8.33333V15.8333ZM13.3333 15.8333H11.6667V8.33329H13.3333V15.8333ZM13.8483 3.33329L12.5 1.66663H7.5L6.15167 3.33329H2.5V4.99996H6.66667H13.3333H17.5V3.33329H13.8483Z" />
                </svg>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <ConfirmationModal
                  confirmModalContent={confirmModalContent}
                  ishandleDeletePosition={this.ishandleDeleteRow.bind(
                    this,
                    row
                  )}
                />
              </Dropdown.Menu>
            </Dropdown>
          );
        }
      },
    }
    ];
    
 
  




    return (
      <>
        <React.Fragment>



          <Container fluid className="body-sec">
            <div className="page-heading underline d-flex  ">

            Job Templates
              <div className="action-group d-flex flex-row ml-auto">
              <Button onClick={this.shownewslideout}>Add Template</Button>
              </div>
            </div>

            <section className="mt-3">
            <ToolkitProvider
        keyField="id"
        data={this.state.searcResult}
        columns={columns}

         //className="table table-striped table-borderless align-middle"
        search
      >
        {
          props => (


            <div >
              <section className="d-flex flex-wrap gap-3 pb-2">
                <div className="position-relative">
                  <SearchBar {...props.searchProps} />
                </div>

                <div>
                  <Form.Label>
                    <SingleDropdownListWithoutSearch
                      
                      itemList={this.state.selectOptions}
                      handleSelectedItem={this.handleSelectedItem1.bind(this, 1)}
                      defaultItem={this.state.selectedcolumn}
                      defaultText={"Select Filter"}
                      defaultName={this.state.selectedcolumn}
                      controlID="1"
                      id={"ddlselected"}

                    /></Form.Label>
                </div>

              </section>
              <section className="pb-3">
                <div className="table-responsive divbottom"  >
                  <div className="ml-auto transactions-list">
                  <BootstrapTable
                    {...props.baseProps}
                    rowEvents={this.rowEvents}
                    keyField="rowId"
                    pagination={paginationFactory(options)}

                  />
                  </div>
                </div>
            

              </section>
            </div>

          )
        }
 </ToolkitProvider>
</section>
              {(showSlideout &&
                <PartTemplateSlideout
                  closeSlideOut={this.closeSlideOut}
                  templateid={this.state.templateid}
                >


                </PartTemplateSlideout>
              )}

{(this.state.isSpinner || this.state.isSaveSpinner) && (
            <div className="loader-spinner d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="success" />
            </div>
          )}
          </Container>



        </React.Fragment>
      </>
    );
  }
}
import * as React from "react";
import { Container, Dropdown, Accordion, Card, Button, Form } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import { CustomerService } from "../../Common/Services/CustomerService";
import { InvoiceService } from "../../Common/Services/InvoiceService";
import { Customerorderslideout } from '../Customerorderslideout';
import { Utils } from "../../Common/Utilis";
import _ from "lodash";
import paginationFactory from 'react-bootstrap-table2-paginator';
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import createDOMPurify from "dompurify";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import moment from "moment";
import { SingleDropdownListWithoutSearch } from "../../Common/Components/SingleDropdownListWithoutSearch";
//import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import chevronRight from "../../Common/Assets/Images/icons/bx-chevron-right.svg";
import {Receivableslideout}  from './Receivableslideout';
const { SearchBar } = Search;
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
const DOMPurify = createDOMPurify(window);

export class Receivable extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.bindfilter = this.bindfilter.bind(this);
    this.state = {
      footershow: false,
      selectedRows: [],
      selectedRowscustomerid: [],

      invoiceTotal: 0,
      isstatus: true,
      isorderdate: true,
      iscustomername: true,
      iscustomercode: true,
      iscustomerpo: true,
      htmlItem: [],
      htmlErrorItem: [],
      htmlWarningItem: [],
      searcResult: [],
      customerOrderResult: [],
      invoiceList: [],
      invoiceId: 0,
      orderIds: [],
      products:[],
      selectedcolumn: "All",
      selectOptions: [
        {

          id: 'All', name: 'All'
        },
        {

          id: 'Customer PO', name: 'Customer PO'
        },
        { id: 'Customer Code', name: 'Customer Code' },
        { id: 'Customer Name', name: 'Customer Name' },
        { id: 'Order Date', name: 'Order Date' },
        { id: 'Status', name: 'Status' },

      ],
    };


  }


  componentDidMount() {


    this.getCOItems();
    this.getInvoiceList();
  }




  getCOItems() {
    let saveRequest: any = {};


    saveRequest.recordCount = 0;
    CustomerService.GeCustomerOrderInv(saveRequest)
      .then(async (result: any[] | null) => {

        if (result != null) {
          // console.log(result);
          this.setState({ customerOrderResult: result,selectedRowscustomerid:[],footershow:false }, () => {
            this.bindfilter();
          });
        }
        resolve();
      })
      .catch((err: any) => {
        toast.error(`Server Error, ${err}`);
        reject();
      });
  }

  getInvoiceList = () => {
     
    let requestlist = { ...this.state.requestlist };
    InvoiceService.GetInvoiceList(requestlist)
  .then(async (result: any | null) => {
    
    if (result != null) {
      // console.log(result);
      this.setState({ invoiceList: result,selectedRowscustomerid:[] ,footershow:false,invoiceTotal:0,selectedRows:[]}, () => {
        this.bindfilter();
      });
    }
    resolve();
  })
  .catch((err: any) => {
    toast.error(`Server Error, ${err}`);
    reject();
  });
  };


  shownewslideout = () => {
    this.setState({ showSlideout: true, orderid: 0, isSpinner: false, });
  };

  showslideout = () => {
    this.setState({ showSlideout: true, isSpinner: false, });
  };


  showReceivableslideout = (row: any) => {
    if (this.state.customerOrderResult.filter((x) => x.selected === true).length === 0) {
      toast.error('Please select jobs to ship under this order');
      reject();
      return;
    }
    this.setState({
      showReceivableslideout: true, orderid: row.orderID,
      invoiceId: 0,
      orderIds: this.state.customerOrderResult.filter(x => x.selected === true).map(r => r.orderID),
      products: this.state.customerOrderResult.filter(x => x.selected === true).map(r => r.productid),
      isSpinner: false,
    });

  };
  showRcvslideout = () => {

    
    this.setState({
      showReceivableslideout: true ,
      invoiceId: 0,
      orderIds: this.state.customerOrderResult.filter(x => x.selected === true).map(r => r.orderID),
      products: this.state.customerOrderResult.filter(x => x.selected === true).map(r => r.productid),
      isSpinner: false,
      footershow:false ,
      //selectedRowscustomerid:[]
    });
  };
  closeSlideOut = () => {

   

 
    this.setState({ showSlideout: false, isSpinner: false,selectedRowscustomerid:[],footershow: this.state.selectedRows.length=0 ? false :true });
    //this.getCustomerItems();
  };

  closeInvoiceSlideOut = () => {
    this.setState({ showReceivableslideout: false, isSpinner: false, });
    //  this.getCustomerItems();
    this.getCOItems();
    this.getInvoiceList();
  };

  rowEvents = {


    onClick: (e: any, row: any, rowIndex: any) => {
      if (row.mainType === undefined) {

        if (row.invoiceNo !== 0 ) {
          this.setState({
            showReceivableslideout: true,
             orderid: row.orderId,
             invoiceId: row.id,
            isSpinner: false,
          });

        }
        else
          this.setState({
            showSlideout: true, orderid: row.orderID, invoiceId: 0, isSpinner: false,
          });

      }

    },
  };

  bindfilter = () => {
    let selectOptions = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.selectOptions))
    );

    this.setState({ selectOptions: selectOptions });

  };

  handleSelectedItem1 = (control: any, id: any) => {
    let searcResult1 = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.searcResult))
    );

    let chkisstatus = id == "Status" ? true : false;
    let chkisorderdate = id == "Order Date" ? true : false;
    let chkiscustomername = id == "Customer Name" ? true : false;
    let chkiscustomercode = id == "Customer Code" ? true : false;
    let chkiscustomerpo = id == "Customer PO" ? true : false;


    if (id == "All") {
      chkisstatus = true;
      chkisorderdate = true;
      chkiscustomername = true;
      chkiscustomercode = true;
      chkiscustomerpo = true;

    }


    this.setState({
      searcResult: [], selectedcolumn: id, isstatus: chkisstatus, isorderdate: chkisorderdate,
      iscustomername: chkiscustomername, iscustomercode: chkiscustomercode, iscustomerpo: chkiscustomerpo
    },
      () => {
        this.setState({ searcResult: searcResult1 });

      }


    );

  };


  handleOnSelect = (row, isSelect) => {
    
    let customerOrderResult = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.customerOrderResult))
    );

    let checklistItem = this.state.selectedRowscustomerid.filter(
      (x) => x !== row.orderID 
    );
    row.selected=isSelect;

    let { invoiceTotal, selectedRows, selectedcustomerid, selectedRowscustomerid } = this.state;


    let listItems = customerOrderResult.filter(
      (x) => x.rowId === row.rowId
    );

    if (isSelect) {
      selectedRows = [...this.state.selectedRows, row.rowId];
     

    } else {
      selectedRows = this.state.selectedRows.filter(x => x !== row.rowId);
    
    }
  
    if (checklistItem.length == 0) {
      if (isSelect) {
        
        listItems.forEach((element: any, index: any) => {
        invoiceTotal += element.totalAmount;

        });

        selectedcustomerid =row.customerID;
      }
      else {
        listItems.forEach((element: any, index: any) => {
        invoiceTotal -= element.totalAmount;

      });

        selectedcustomerid = 0;
      }


      if (isSelect) {
        selectedRows = [...this.state.selectedRows, row.rowId];
        selectedRowscustomerid = [...this.state.selectedRowscustomerid, row.orderID];


      } else {
        selectedRows = this.state.selectedRows.filter(x => x !==  row.rowId);
        selectedRowscustomerid = this.state.selectedRowscustomerid.filter(x => x !== row.orderID);
      }
    }
    else
    {

      toast.error("Each Customer PO will have single invoice.");
      
    }


    if (checklistItem.length == 0) {
      if (listItems.length > 0) {
        listItems.forEach((element: any, index: any) => {
  
          if (checklistItem.length == 0) {
            element.selected = isSelect;
          }
  
        });
      };

    }
  //  this.setState({ customerOrderResult: customerOrderResult }, () => { this.setState({ customerOrderResult: this.state.customerOrderResult }); });

  this.setState({  customerOrderResult: customerOrderResult, footershow: selectedRows.length==0 ? false :true, invoiceTotal: invoiceTotal, selectedRows: selectedRows });

  };

  handleOnSelectAll = (isSelect, rows) => {


    let checklistItem = this.state.selectedRowscustomerid.filter(
      (x) => x !== rows[0].orderID
    );

    const ids = rows.map(r => r.rowId);
    let { invoiceTotal, selectedRows, selectedcustomerid, selectedRowscustomerid } = this.state;
    let customerOrderResult = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.customerOrderResult))
    );

    let listItems = customerOrderResult.filter(
      (x) => ids.includes(x.rowId)
    );




    if (checklistItem.length == 0) {
      if (isSelect) {
        
        listItems.forEach((element: any, index: any) => {
        invoiceTotal += element.totalAmount;

        });

        selectedcustomerid = rows[0].customerID;
      }
      else {
        listItems.forEach((element: any, index: any) => {
        invoiceTotal -= element.totalAmount;

      });

        selectedcustomerid = 0;
      }


      if (isSelect) {
        selectedRows = [...this.state.selectedRows, rows[0].orderID];
        selectedRowscustomerid = [...this.state.selectedRowscustomerid, rows[0].orderID];


      } else {
        selectedRows = this.state.selectedRows.filter(x => x !== rows[0].orderID);
        selectedRowscustomerid = this.state.selectedRowscustomerid.filter(x => x !== rows[0].orderID);
      }
    }
    else
    {

      toast.error("if you make one invoice for multiples order then customer should be same ");
      
    }
    if (checklistItem.length == 0) {
    if (listItems.length > 0) {
      listItems.forEach((element: any, index: any) => {

        if (checklistItem.length == 0) {
          element.selected = isSelect;
        }

      });
    };
  }

    this.setState({ customerOrderResult: customerOrderResult, footershow: selectedRows.length==0 ? false :true, invoiceTotal: invoiceTotal, selectedRows: selectedRows, selectedRowscustomerid: selectedRowscustomerid });
  };

  render() {
    const customTotal = (from, to, size) => (
      <span className="text-muted">
        Showing {from} to {to} of {size} entries
      </span>
    );
    const options = {
    
      showTotal: true,
     hideSizePerPage: false,
     hidePageListOnlyOnePage: true,
   paginationTotalRenderer: customTotal,
     disablePageTitle: true,
   sizePerPageList: [{
     text: '10', value: 10
   },
    {
     text: '25', value: 25
   },
   {
     text: '50', value: 50
   },
   
   {
     text: 'All', value: this.state.searcResult.length
   }] // A numeric array is also available. the purpose of above example is custom the text
   };
    const columns = [
      // {
      //   dataField: 'orderID',
      //   text: '#',
      //   headerStyle: { width: '10px' },
      // },
      {
        dataField: 'customerPoNumber',
        text: 'Customer PO No.',
        sort: true,
        headerStyle: { width: '100px' },
        searchable: this.state.iscustomerpo,
      },
      {
        dataField: 'customercode',
        text: 'Customer Code',
        sort: true,
        headerStyle: { width: '10px' },
        searchable: this.state.iscustomercode,
      },
      {
        dataField: 'customerName',
        text: 'Customer Name',
        sort: true,
        searchable: this.state.iscustomername,
        // headerStyle: { width: '300px' },
      },


      {
        dataField: 'orderDate',
        text: 'Order Date',
        sort: true,

        headerStyle: { width: '100px' },
        searchable: this.state.isorderdate,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {moment(row.orderDate).format("MM/DD/YYYY")}
            </EllipsisWithTooltip>
          );
        },
      },

      // ,
      {
        dataField: 'totalAmount',
        text: 'Invoice Amount',
         
        headerClasses: 'amount',
        classes: 'text-end pe-4 divgreen',
        sort: true,
        searchable: false,
        //headerStyle: { width: '300px' },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {Utils.currencyFormat(row.totalAmount)}
            </EllipsisWithTooltip>
          );
        },
      },



      // {
      //   dataField: '',
      //   text: '',
      //   headerStyle: { width: '100px' },
     
      // },

      {
        dataField: 'status',
        text: 'Order Status',
        sort: true,
        headerStyle: { width: '200px' },
        headerClasses: 'text-center',
        classes: 'text-center',
        
        searchable: this.state.isstatus,
        //searchable:this.state.isdueDate,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            
            <>
           
            <EllipsisWithTooltip placement="bottom">
              
                
  
                  <span className={ row.status==="Active" ? "badge badge-pill badge-primary color-green" : "badge badge-pill badge-primary color-red"}> {row.status} </span>
  
  
              </EllipsisWithTooltip>
        
        
              </>
  
          );
      },
      },
  
      // {
      //   dataField: 'status',
      //   text: 'Order Status',
      //   sort: true,
      //   searchable: this.state.isstatus,
      //   headerStyle: { width: '700px' },
      // },
      
      // {
      //   dataField: 'Inv',
      //   text: '',
      //   sort: true,
      //   searchable: false,
      //   //headerStyle: { width: '300px' },
      //   formatter: (cell: any, row: any, rowIndex: any) => {


      //     return (
      //       <EllipsisWithTooltip placement="bottom">
      //         {<Button onClick={(x) => this.showReceivableslideout(row)}>Invoice Generate</Button>}
      //       </EllipsisWithTooltip>
      //     );
      //   },
      // },

    ];


    const invoiceColumns = [
      // {
      //   dataField: 'orderID',
      //   text: '#',
      //   headerStyle: { width: '10px' },
      // },
      {
        dataField: 'prefixInvoiceno',
        text: 'Invoice No.',
        sort: true,
        headerStyle: { width: '100px' },
        searchable: this.state.iscustomerpo,
      },
      {
        dataField: 'invoiceDate',
        text: 'Invoice Date',
        sort: true,

        headerStyle: { width: '100px' },
        searchable: this.state.isorderdate,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {moment(row.invoiceDate).format("MM/DD/YYYY")}
            </EllipsisWithTooltip>
          );
        },
      },
    
      {
        dataField: 'customerPoNumber',
        text: 'Customer PO No.',
        sort: true,
        headerStyle: { width: '100px' },
        searchable: this.state.iscustomername,
        // headerStyle: { width: '300px' },
      },

      {
        dataField: 'customerName',
        text: 'Customer Name',
        sort: true,
        searchable: this.state.iscustomername,
        headerStyle: { width: '300px' },

      },


      {
        dataField: 'dueDate',
        text: 'Due Date',
        sort: true,

        headerStyle: { width: '100px' },
        searchable: this.state.isorderdate,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {moment(row.dueDate).format("MM/DD/YYYY")}
            </EllipsisWithTooltip>
          );
        },
      },

      
      {
        dataField: 'accountingPeriod',
        text: 'Accounting Period',
        sort: true,
        searchable: this.state.iscustomername,
        // headerStyle: { width: '300px' },
      },

      {
        dataField: 'totalAmount',
        text: 'Total Amount',
        headerClasses: 'amount',
        classes: 'text-end pe-4 divgreen',
        sort: true,
        searchable: false,
        headerStyle: { width: '100px' },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {Utils.currencyFormat(row.totalAmount)}
            </EllipsisWithTooltip>
          );
        },
      },

      {
        dataField: '',
        text: '',

        sort: false,
        searchable: false,
        headerStyle: { width: '100px' },

      },

      // {
      //   dataField: '',
      //   text: '',
      //   headerStyle: { width: '100px' },
      // },


    ];

    const selectOptions = [{
      id: 0, column: 'Customer PO'
    },
    { id: 1, column: 'Customer Code' },
    { id: 2, column: 'Customer Name' },
    { id: 3, column: 'Order Date' },
    { id: 4, column: 'Status' },

    ];

    const {

      showSlideout,
      showReceivableslideout,

    } = this.state;


    const expandSplitRow = {
      onlyOneExpanding: true,
      parentClassName: "parent-expand-foo",
      renderer: (row: any, rowIndex: any) => renderItemTableChild(row, rowIndex),
      showExpandColumn: true,
      expandByColumnOnly: true,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <div></div>;
        }
        return <div></div>;
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          // this.setState({expanded:expanded},()=>{
          //   $('.selection-cell input').css('display', 'none');
          // });
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };
    const whiteBgFormatter = (column, colIndex) => {
      return <div className="white-bg"></div>;
    };

    const renderItemTableChild = (mainrow, rowIndex) => {

      let splitDetail: any = [];
      splitDetail = this.state.customerOrderResult.filter(
        (x) => x.orderID === mainrow.orderID && x.mainType === 2
      );

      const columnCOAChild = [
        // {
        //   dataField: "orderID",
        //   text: "#",
        //   hidden: false,
        // },    
        {
          dataField: "id",
          text: "#",
          hidden: true,
          classes:"expanding-bar",
        },
        {
          dataField: "itemNo",
          text: "#",
          hidden: true,
          classes:"expanding-bar",
        },
        {
          dataField: "srno",
          text: "Sr No.",
          hidden: false,
          editable: false,
          classes:"expanding-bar",
          headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
          formatter: (cell: any, row: any, rowIndex: any) => {

            return (
              <div>
                {rowIndex + 1}
              </div>
            );
          },
        },
        {
          dataField: "partNo",
          text: "Part No",
          classes:"expanding-bar",

        },
        {
          dataField: "partname",
          text: "Part Description",
         
          classes:"expanding-bar text-truncate",
          //classes:"text-truncate",
          headerStyle: { width: "10%" },
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
             
              <div className="d-flex align-items-center txt-bold">
              <div style={{width: '150px'}}>
             <EllipsisWithTooltip placement="bottom">
               {row.partname}
             </EllipsisWithTooltip>
           </div>
           </div>
            );
        
        },
        },



       

          {
            dataField: 'dueDate',
            text: 'Due Date',
            sort: true,
            classes:"expanding-bar",

            headerStyle: { width: '100px' },
            searchable: this.state.isorderdate,
            formatter: (cell: any, row: any, rowIndex: any) => {
              return (
                <EllipsisWithTooltip placement="bottom">
                  {moment(row.dueDate).format("MM/DD/YYYY")}
                </EllipsisWithTooltip>
              );
            },
          },
    
      

        // {
        //   dataField: "jobDesc",
        //   text: "Description",
        //   classes:"expanding-bar",
        // },

        {
          dataField: "unit",
          text: "Unit",
          classes:"expanding-bar",
        },
        {
          dataField: "qtyOrdered",
          text: "Qty Ordered",
          classes:"expanding-bar",

        },

        {
          dataField: "unitPrice",
          text: "Unit Price",
          classes:"expanding-bar",

        },

        {
          dataField: "discount",
          text: "Discount",
          classes:"expanding-bar",
        },
        {
          dataField: "totalAmount",
          text: "Total",
          classes:"expanding-bar text-end pe-4 divgreen",
          headerClasses: 'text-end pe-4',
          headerStyle: { minWidth: '100px',width:'100px' },
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
                {Utils.currencyFormat((row.qtyOrdered*row.unitPrice)-row.discount)}
              </EllipsisWithTooltip>
            );
          },

        },


        {
          dataField: "jobno",
          text: "Job Order",
          classes:"expanding-bar",

        }


      ];
      return (
        <>

         <div className='px-4 p-4'>
          <BootstrapTable
            id="tblCOAchild"
            keyField="rowId"
            data={splitDetail}
            columns={columnCOAChild}
            //rowEvents={this.rowEvents}
            selectRow={{
              mode: 'checkbox', clickToSelect: false, hideSelectAll: false,
              selected: splitDetail.filter((x) => x.selected === true).map(r => r.rowId),
              onSelect: this.handleOnSelect,
              onSelectAll: this.handleOnSelectAll,
              hideSelectColumn: false
            }}

          />
          </div>
        </>
      );
    };


    return (
      <>
        <React.Fragment>

        


            <Container fluid className="body-sec">
              <div className="page-heading underline d-flex  ">

             Invoices
                <div className="action-group d-flex flex-row ml-auto">
                  {/* <Button onClick={this.shownewslideout}>Add Customer Order</Button> */}
                </div>
              </div>

              <Tab.Container   transition={false} defaultActiveKey="first" >
    
    <section className="pb-3">
<Nav   className="nav nav-underline border-bottom">
<Nav.Item className="nav-item">
  <Nav.Link eventKey="first">Inbox 
  <span className="badge badge-light">{this.state.customerOrderResult.filter(
                    (x) => x.mainType == 1
                  ).length}</span>

  </Nav.Link>
</Nav.Item>
<Nav.Item className="nav-item">
  <Nav.Link eventKey="second">Generated
  <span className="badge badge-light"> {this.state.invoiceList.length}</span>
          
          </Nav.Link>
</Nav.Item>

</Nav>
</section>

<Tab.Content>
<Tab.Pane eventKey="first">

<ToolkitProvider
        keyField="id"
        data={this.state.customerOrderResult.filter((x) => x.mainType === 1)}
        columns={columns}

         //className="table table-striped table-borderless align-middle"
        search
      >
        {
          props => (


            <div >
              <section className="d-flex flex-wrap gap-3 pb-2">
                <div className="position-relative">
                  <SearchBar {...props.searchProps} />
                </div>

                <div 
                //className="input-group w-auto"
                >
                  <Form.Label>
                    <SingleDropdownListWithoutSearch
                      
                      itemList={this.state.selectOptions}
                      handleSelectedItem={this.handleSelectedItem1.bind(this, 1)}
                      defaultItem={this.state.selectedcolumn}
                      defaultText={"Select Filter"}
                      defaultName={this.state.selectedcolumn}
                      controlID="1"
                      id={"ddlselected"}

                    /></Form.Label>
                </div>

              </section>
              <section className="pb-3">
                <div className="table-responsive divbottom"  >
                  <div className="ml-auto transactions-list">
                  <BootstrapTable
                    {...props.baseProps}
                   // rowEvents={this.rowEvents}
                    keyField="rowId"
                  
                    expandRow={expandSplitRow}
                    pagination={paginationFactory(options)}

                  />
                  </div>
                </div>
                {(this.state.footershow &&
                            <div className="table-responsive TabFooter-Pay"
                            style={{ minWidth: "83.7%", width: "992px", maxWidth: "100%" }}
                            >
                              <div className="bulkSelectFooter">
                                {/* <span className="shift-selected-count">{this.state.selectedRows.length}
                                  {" "}Selected</span> */}
                                {/* <div className="FooterHorLine"></div> */}
                                <span className="shift-selected-count">Invoice Total: {Utils.currencyFormatWithOutdoller(this.state.invoiceTotal)}</span>
                                <div className="action-group d-flex flex-row mr-auto">
                                  <Button type="button"
                                  className="btn btn-primary"
                                  //  onClick={(e) => { this.handleBtnEvent(e, "", 'bulk') }}
                                   onClick={(x) => this.showRcvslideout()}
                                  >Invoice Generate</Button>
                                </div>
                              </div>
                            </div>)}

              </section>
            </div>

          )
        }
      </ToolkitProvider>



</Tab.Pane>
<Tab.Pane eventKey="second">

<ToolkitProvider
        keyField="id"
        
        data={this.state.invoiceList}
        columns={invoiceColumns}
        
        search
      >
        {
          props => (


            <div >
              <section className="d-flex flex-wrap gap-3 pb-2">
                <div className="position-relative">
                  <SearchBar {...props.searchProps} />
                </div>

                <div 
                //className="input-group w-auto"
                >
                  <Form.Label>
                    <SingleDropdownListWithoutSearch
                      
                      itemList={this.state.selectOptions}
                      handleSelectedItem={this.handleSelectedItem1.bind(this, 1)}
                      defaultItem={this.state.selectedcolumn}
                      defaultText={"Select Filter"}
                      defaultName={this.state.selectedcolumn}
                      controlID="1"
                      id={"ddlselected"}

                    /></Form.Label>
                </div>

              </section>
              <section className="pb-3">
                <div className="table-responsive divbottom"  >
                <div className="ml-auto transactions-list">
                  <BootstrapTable
                    {...props.baseProps}
                    rowEvents={this.rowEvents}
                    pagination={paginationFactory(options)}
                   // expandRow={expandSplitRow}

                  />
                  </div>

                </div>
              
{/* 
                            {(this.state.footershow &&
        <div className="TabFooter-Pay">
          <div className="bulkSelectFooter">
            <span className="shift-selected-count">{this.state.selectedRows.length}
              {" "}Selected</span>
            <div className="FooterHorLine"></div>
            <span className="shift-selected-count">Invoice Total: {Utils.currencyFormatWithOutdoller(this.state.invoiceTotal)}</span>
            <div className="action-group d-flex flex-row mr-auto">
              <Button type="button"
              //className="btn-outline-primary btnHeight"
              //  onClick={(e) => { this.handleBtnEvent(e, "", 'bulk') }}
               onClick={(x) => this.showRcvslideout()}
              >Invoice Generate</Button>
            </div>
          </div>
        </div>)} */}



              </section>
            </div>

          )
        }
      </ToolkitProvider>

</Tab.Pane>

</Tab.Content>

</Tab.Container>

              {/* <Tabs defaultIndex={0} onSelect={(index) => console.log(index)} selectedTabClassName="bg-white">
                <TabList>

                  <Tab><h6>Invoice Inbox <span className="badge badge-light">{this.state.customerOrderResult.filter(
                    (x) => x.mainType == 1
                  ).length}</span></h6></Tab>
                  <Tab><h6>Invoice Generated <span className="badge badge-light"> {this.state.invoiceList.length}</span></h6></Tab>
                </TabList>

                <TabPanel>


                  <ToolkitProvider

                    data={this.state.customerOrderResult.filter((x) => x.mainType === 1)}
                    columns={columns}

                    search
                  >
                    {
                      props => (
                        <div>

                          <SearchBar {...props.searchProps} />


                          <Form.Label>
                            <SingleDropdownListWithoutSearch
                              // tabIndex={this.state.tabEnable}
                              itemList={this.state.selectOptions}
                              handleSelectedItem={this.handleSelectedItem1.bind(this, 1)}
                              defaultItem={this.state.selectedcolumn}
                              defaultText={"Select Filter"}
                              defaultName={this.state.selectedcolumn}
                              controlID="1"
                              id={"ddlselected"}
                            //onTAB={this.onTAB.bind(this, "ddlprocess-" + rowIndex + "ddl", rowIndex)}
                            // disabled={disable}
                            /></Form.Label>
                          <hr />
                          <BootstrapTable id="tblCO"
                            {...props.baseProps}
                            keyField="rowId"
                            rowEvents={this.rowEvents}
                            expandRow={expandSplitRow}
                            pagination={paginationFactory()}
                          />

                          {(this.state.footershow &&
                            <div className="TabFooter-Pay">
                              <div className="bulkSelectFooter">
                                <span className="shift-selected-count">{this.state.selectedRows.length}
                                  {" "}Selected</span>
                                <div className="FooterHorLine"></div>
                                <span className="shift-selected-count">Invoice Total: {Utils.currencyFormatWithOutdoller(this.state.invoiceTotal)}</span>
                                <div className="action-group d-flex flex-row mr-auto">
                                  <Button type="button"
                                  //className="btn-outline-primary btnHeight"
                                  //  onClick={(e) => { this.handleBtnEvent(e, "", 'bulk') }}
                                   onClick={(x) => this.showRcvslideout()}
                                  >Invoice Generate</Button>
                                </div>
                              </div>
                            </div>)}

                        </div>



                      )
                    }
                  </ToolkitProvider>

                </TabPanel>
                <TabPanel>


                <ToolkitProvider

data={this.state.invoiceList}
columns={invoiceColumns}

search
>
{
  props => (
    <div>

      <SearchBar {...props.searchProps} />


      <Form.Label>
        <SingleDropdownListWithoutSearch
          // tabIndex={this.state.tabEnable}
          itemList={this.state.selectOptions}
          handleSelectedItem={this.handleSelectedItem1.bind(this, 1)}
          defaultItem={this.state.selectedcolumn}
          defaultText={"Select Filter"}
          defaultName={this.state.selectedcolumn}
          controlID="1"
          id={"ddlselected"}
        //onTAB={this.onTAB.bind(this, "ddlprocess-" + rowIndex + "ddl", rowIndex)}
        // disabled={disable}
        /></Form.Label>
      <hr />
      <BootstrapTable id="tblInvoice"
        {...props.baseProps}
        keyField="rowId"
        rowEvents={this.rowEvents}
        pagination={paginationFactory()}
      />

      {(this.state.footershow &&
        <div className="TabFooter-Pay">
          <div className="bulkSelectFooter">
            <span className="shift-selected-count">{this.state.selectedRows.length}
              {" "}Selected</span>
            <div className="FooterHorLine"></div>
            <span className="shift-selected-count">Invoice Total: {Utils.currencyFormatWithOutdoller(this.state.invoiceTotal)}</span>
            <div className="action-group d-flex flex-row mr-auto">
              <Button type="button"
              //className="btn-outline-primary btnHeight"
              //  onClick={(e) => { this.handleBtnEvent(e, "", 'bulk') }}
               onClick={(x) => this.showRcvslideout()}
              >Invoice Generate</Button>
            </div>
          </div>
        </div>)}

    </div>



  )
}
</ToolkitProvider>

                </TabPanel>

              </Tabs> */}


              {(showSlideout &&
                <Customerorderslideout
                  closeSlideOut={this.closeSlideOut}
                  orderid={this.state.orderid}
                >


                </Customerorderslideout>
              )}

              {(showReceivableslideout &&
                <Receivableslideout
                  closeSlideOut={this.closeInvoiceSlideOut}
                  orderid={this.state.orderid}
                  orderIds={this.state.orderIds}
                  products={this.state.products}
                  invoiceId={this.state.invoiceId}
                >


                </Receivableslideout>
              )}
            </Container>


       
        </React.Fragment>
      </>
    );
  }
}
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import * as React from "react";
import {
  Container,
  Dropdown,
  Accordion,
  Card,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import { VendorService } from "../Common/Services/VendorService";
import { Utils } from "../Common/Utilis";
import _ from "lodash";
import paginationFactory from "react-bootstrap-table2-paginator";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import createDOMPurify from "dompurify";
import chevronRight from "../../Common/Assets/Images/icons/bx-chevron-right.svg";
import moment from "moment";
import { JobTrackerServices } from "../Common/Services/JobTrackerServices";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
// import { DateRangePicker } from "@nextui-org/date-picker";
// import {DateRangePicker} from "@nextui-org/react";
import { SingleDropdownListWithoutSearch } from "../Common/Components/SingleDropdownListWithoutSearch";
const { SearchBar } = Search;
import DatePicker from "react-datepicker";
// import '@nextui-org/react/nextui.css'; // Add this line
// import { NextUIProvider } from "@nextui-org/react";

import "react-tabs/style/react-tabs.css";
export class DetailedReport extends React.Component<any, any> {
  private parentScroll: any;

  constructor(props: any) {
    super(props);
    this.parentScroll = React.createRef();
    this.state = {
      selectedcolumn: "All",
      startDate: null,
      endDate: null,
      orders: [
        {
          id: 1,
          orderNo: "CO#123",
          customerCode: "C1001",
          customerName: "ABC Enterprises",
          customerPONo: "1234",
          orderDate: "1/10/24",
          orderTotal: "$1000",
          jobNo: "JO#123",
          partNo: "PN123",
          partDescription: "SAMPLE1",
          drawingNo: "DN456",
          drawingRevision: "Rev A",
        },
        {
          id: 2,
          orderNo: "CO#124",
          customerCode: "C1002",
          customerName: "XYZ Corp",
          customerPONo: "5678",
          orderDate: "1/11/24",
          orderTotal: "$1500",
          jobNo: "JO#124",
          partNo: "PN124",
          partDescription: "SAMPLE2",
          drawingNo: "DN457",
          drawingRevision: "Rev B",
        },
        {
          id: 3,
          orderNo: "CO#125",
          customerCode: "C1003",
          customerName: "LMN Ltd",
          customerPONo: "9101",
          orderDate: "1/12/24",
          orderTotal: "$2000",
          jobNo: "JO#125",
          partNo: "PN125",
          partDescription: "SAMPLE3",
          drawingNo: "DN458",
          drawingRevision: "Rev C",
        },
      ],
      selectOptions: [
        {
          id: "All",
          name: "All",
        },
        { id: "Customer Name", name: "Customer Name" },
        { id: "Customer Code", name: "Customer Code" },
        { id: "Customer PO No.", name: "Customer PO No." },
      ],
      options: [
        "Part No.",
        "Part description",
        "Qty",
        "Unit price",
        "Total price",
        "Job No.",
        "Customer code",
      ],
    };
  }

  closeSlideOut = () => {
    this.props.closeSlideout(true);
  };

  render() {
    return (
      <section>
        <div
          className="details-drawer"
          style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
          // className="back-drop1  viewer-panel1 opacity "
        >
          <div
            className="back-drop1  viewer-panel1 opacity "
            style={{ minWidth: "25%", maxWidth: "100%", height: "100vh" }}
            /*  className="back-drop1  viewer-panel1 opacity " */
            onClick={() => this.closeSlideOut()}
          ></div>

          <Container
            fluid
            ref={this.parentScroll}
            className="body-sec viewer-panel back-drop p-2"
            style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
          >
            <div className="page-heading  d-flex  ">
              Detailed Report
              <div className="d-flex flex-row ml-auto">
                <button
                  type="button"
                  className="btn btn-primary cross"
                  onClick={this.closeSlideOut}
                  style={{ marginLeft: "10px" }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                      fill="#ffffff"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <section className="pb-3">
              <div className="row g-3">
                <div className="col-12 col-md-4">
                  <div
                    className="p-2 mt-3 mb-3"
                    style={{
                      backgroundColor: "var(--bs-light)",
                      color: "grey",
                      fontWeight: "500",
                    }}
                  >
                    Filter Options
                  </div>
                  <Form.Group controlId="customercode">
                    <Form.Label className="font-semibold">Customer</Form.Label>
                    <div className="" style={{ width: "50%" }}>
                      <SingleDropdownListWithoutSearch
                        itemList={this.state.selectOptions}
                        defaultItem={this.state.selectedcolumn}
                        defaultText={"Select Filter"}
                        defaultName={this.state.selectedcolumn}
                        controlID="1"
                        id={"ddlselected"}
                      />
                    </div>
                  </Form.Group>

                  <Form.Group
                    controlId="emptyTextField"
                    style={{ width: "52%" }}
                  >
                    <Form.Control
                      type="text"
                      placeholder="Enter value"
                      className="mb-3 mt-4"
                    />
                  </Form.Group>

                  {/* <Form.Group controlId="customercodeInput">
                    <Form.Label className="font-semibold"></Form.Label>
                    <NextUIProvider>
                      <DateRangePicker
                        key="bordered"
                        labelPlacement="outside"
                        label="Stay duration"
                        className="max-w-xs"
                      />
                    </NextUIProvider>
                  </Form.Group> */}

                  <Form.Group controlId="daterange" style={{ width: "100%" }}>
                    <Form.Label className="font-semibold">
                      Date Range
                    </Form.Label>
                    <div className="date-picker calendar-picker d-flex align-items-center">
                      <DatePicker
                        id="startDate"
                        tabIndex={this.state.tabEnable}
                        className="form-control px-0 text-center"
                        placeholderText={"MM/DD/YY"}
                        style={{ width: "30%" }}
                        dateFormat="MM/dd/yy"
                        autoComplete="off"
                      />
                      <span className="mx-2">-</span>
                      <DatePicker
                        id="endDate"
                        tabIndex={this.state.tabEnable}
                        className="form-control px-0 text-center"
                        placeholderText={"MM/DD/YY"}
                        style={{ width: "30%" }}
                        dateFormat="MM/dd/yy"
                        autoComplete="off"
                      />
                    </div>
                  </Form.Group>

                  <Form.Group controlId="customercodeInput">
                    <Form.Label className="font-semibold mt-3">
                      Exclude
                    </Form.Label>
                    <div
                      className="single-select"
                      style={{
                        border: "1px solid #ced4da",
                        padding: "10px",
                        borderRadius: "5px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {this.state.options.map((option, index) => (
                        <Form.Check
                          key={index}
                          type="checkbox"
                          label={option}
                        />
                      ))}
                    </div>
                  </Form.Group>

                  <div className={"footer-section2 "}>
                    <div>
                      <div className="d-flex gap-3 align-items-center justify-content-end height-72">
                        <div className="d-flex gap-3 align-items-center">
                          <Button
                            variant="primary"
                            type="button"
                            className="space btn btn-primary btn-discard"
                            // onClick={() =>
                            //   this.handleDiscard(this.state.orderUniqueNo)
                            // }
                          >
                            Save
                          </Button>
                          <Button id="btnSave" variant="primary" type="button">
                            Run
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-8">
                  <div className="border border-grey p-5">
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>SNo.</th>
                            <th>Order No.</th>
                            <th>Customer Code</th>
                            <th>Customer Name</th>
                            <th>Customer PO No.</th>
                            <th>Order Date</th>
                            <th>Order Total</th>
                            <th>Job No.</th>
                            <th>Part No.</th>
                            <th>Part Description</th>
                            <th>Drawing No.</th>
                            <th>Drawing Revision</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.orders.map((order) => (
                            <tr key={order.id}>
                              <td>{order.id}</td>
                              <td>{order.orderNo}</td>
                              <td>{order.customerCode}</td>
                              <td>{order.customerName}</td>
                              <td>{order.customerPONo}</td>
                              <td>{order.orderDate}</td>
                              <td>{order.orderTotal}</td>
                              <td>{order.jobNo}</td>
                              <td>{order.partNo}</td>
                              <td>{order.partDescription}</td>
                              <td>{order.drawingNo}</td>
                              <td>{order.drawingRevision}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Container>

          {(this.state.isSpinner || this.state.isSaveSpinner) && (
            <div className="loader-spinner d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="success" />
            </div>
          )}
        </div>
      </section>
    );
  }
}
